// src/components/Comment.tsx
import { User } from "api/types";
import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { getExpiry, hasExpired } from "utils/localStorage";
import { getDateTimeFromString } from "utils/util";
import Button from "./Button";
import { Comment as CommentInterface } from "./Comments";
import { Icon } from "./Icon";
import { getAvatarName } from "./StaffDetail";

interface CommentProps extends CommentInterface {
  onDelete: (id: string) => void;
  onEdit: (id: string, content: string) => void;
  admin: User;
}

export const BUTTON_VISIBLE_DURATION = 5 * 60 * 1000;

const Comment: React.FC<CommentProps> = ({
  id,
  commentedBy,
  createdAt,
  comment,
  onDelete,
  onEdit,
  admin,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editContent, setEditContent] = useState(comment);
  const [isVisible, setIsVisible] = useState(true);
  const BUTTON_KEY = id + "-button_expiry";

  useEffect(() => {
    if (hasExpired(BUTTON_KEY)) {
      setIsVisible(false);
    } else {
      const remainingTime = getExpiry(BUTTON_KEY)! - new Date().getTime();
      if (remainingTime > 0) {
        setTimeout(() => {
          setIsVisible(false);
        }, remainingTime);
      }
    }
  }, [BUTTON_KEY]);

  const handleEdit = () => {
    onEdit(id, editContent);
    setIsEditing(false);
  };

  return (
    <div className="p-4 mt-4 bg-gray-50 border border-gray-200 rounded-lg">
      <div className="flex py-2 items-center">
        <Avatar
          name={getAvatarName(commentedBy)}
          size="40"
          round
          textSizeRatio={2}
          className="!h-6 !w-6 !text-xs !leading-4 !font-medium !text-primary-900"
          color="#D1F5FC"
          fgColor="#194860"
          src={commentedBy.avatar}
        />
        <h4 className="text-sm leading-5 font-semibold mr-4 ml-3 text-gray-700">
          {commentedBy?.username}
        </h4>
        <span className="flex text-xs leading-4 font-medium text-gray-500">
          <Icon name="calendarOutline" size={16} />
          <span className="ml-2">{getDateTimeFromString(createdAt || "")}</span>
        </span>
      </div>
      {isEditing ? (
        <div className="my-2">
          <textarea
            className="w-full p-2 border rounded-md"
            value={editContent}
            onChange={(e) => setEditContent(e.target.value)}
          />
          <div className="flex space-x-2">
            <Button onClick={handleEdit} variant="primary">
              Save
            </Button>
            <Button
              onClick={() => {
                setIsEditing(false);
                setEditContent(comment);
              }}
              variant="white"
            >
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <p className="my-2">{comment}</p>
      )}
      {!isEditing &&
        isVisible &&
        commentedBy &&
        admin.id === commentedBy.id && (
          <div className="flex space-x-2">
            <Button onClick={() => setIsEditing(!isEditing)} variant="white">
              Edit
            </Button>
            <Button onClick={() => onDelete(id)} variant="errorTonal">
              Delete
            </Button>
          </div>
        )}
    </div>
  );
};

export default Comment;
