import classNames from "classnames";
import { Icon, IconName } from "components/Icon";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

export interface SidebarItemProps {
  title: string;
  icon: string;
  children?: { label: string; url: string; isOpenInNewTab?: boolean }[];
  url?: string; // Optional URL for main items that don't have children
}

interface SidebarProps {
  items: SidebarItemProps[];
}

const SidebarItem: React.FC<SidebarItemProps> = ({
  title,
  icon,
  children,
  url,
}) => {
  const location = useLocation();

  // Check if the current path matches the item or any of its children
  const isActive = (path: string) => location.pathname === path;

  const isChildActive = children?.some((child) => isActive(child.url)) || false;
  const isInitiallyOpen = url ? isActive(url) : isChildActive;

  const [isOpen, setIsOpen] = useState(isInitiallyOpen);

  useEffect(() => {
    // Expand the parent if the current route is the active one or one of the children is active
    if (isInitiallyOpen) {
      setIsOpen(true);
    }
  }, [isInitiallyOpen]);

  return (
    <div className="p-1">
      <div
        className={classNames(
          isActive(url || "") || isChildActive
            ? "bg-primary-100 text-gray-900 hover:bg-primary-200"
            : "text-gray-600 hover:text-gray-900 hover:bg-gray-200",
          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-medium cursor-pointer h-9",
          isChildActive
            ? "outline outline-offset-2 outline-2 outline-primary-500"
            : ""
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center space-x-3 flex-1 h-5">
          {icon && (
            <>
              <Icon
                name={icon as IconName}
                className={classNames(
                  isActive(url || "") || isChildActive
                    ? "text-gray-900"
                    : "text-gray-600 group-hover:text-gray-900 hover:text-gray-900",
                  "h-5 w-5 shrink-0 "
                )}
              />
            </>
          )}
          {url ? (
            <Link
              to={url}
              className={classNames(isActive(url) ? "font-medium" : "")}
            >
              {title}
            </Link>
          ) : (
            <span>{title}</span>
          )}
        </div>
        {children && children.length > 0 && (
          <span>
            <Icon
              name="downChevron"
              size={20}
              className={classNames(
                "transition-transform -rotate-90 text-gray-400",
                isOpen ? "rotate-0 " : "",
                isChildActive ? "text-gray-800" : ""
              )}
            />
          </span>
        )}
      </div>
      {isOpen && children && children.length > 0 && (
        <div className="">
          {children.map((child, index) => (
            <Link
              key={index}
              to={child.url}
              className={classNames(
                isActive(child.url)
                  ? "bg-primary-100 text-gray-900 hover:bg-primary-200"
                  : "text-gray-600 hover:text-gray-900 hover:bg-gray-200",
                "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-medium cursor-pointer pl-10",
                index === 0 ? "mt-2.5" : "mt-1"
              )}
              target={child.isOpenInNewTab ? "_blank" : undefined}
              rel={child.isOpenInNewTab ? "noopener noreferrer" : undefined}
            >
              {child.label}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

const Sidebar: React.FC<SidebarProps> = ({ items }) => {
  return (
    <div className="w-64 h-full">
      {items.map((item, index) => (
        <SidebarItem
          key={index}
          title={item.title}
          icon={item.icon}
          children={item.children}
          url={item.url}
        />
      ))}
    </div>
  );
};

export default Sidebar;
