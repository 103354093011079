import { onboardStaff } from "api/staff";
import { StaffProfile } from "api/types";
import Button from "components/Button";
import ReusableForm from "components/Form";
import FormFooter from "components/FormFooter";
import { Icon } from "components/Icon";
import Modal from "components/Modal";
import Notification, { NotificationProps } from "components/Notification";
import {
  FieldType,
  FormData,
  RESIDENCY_STATUS,
  StepProps,
} from "components/types";
import { handleResponse } from "components/utility";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getDateAfterPeriod, getTodayDate } from "utils/util";

const FIELDS_TO_REMOVE = ["calendarMonth", "calendarYear"];

export const VisaTypeOptions = [
  { label: "Studying and training", value: "Studying and training" },
  {
    label: "Work and skilled",
    value: "Work and skilled",
  },
  { label: "Visitor", value: "Visitor" },
  { label: "Family and partner", value: "Family and partner" },
  { label: "Permanent resident", value: "Permanent resident" },
  { label: "Refugee and humanitarian", value: "Refugee and humanitarian" },
  { label: "Other", value: "Other" },
];

export const ResidencyOptions = [
  { label: "Australian citizen", value: "citizen" },
  {
    label: "Australian permanent resident",
    value: "permanent_resident",
  },
  { label: "Other", value: "other" },
];

const getRequiredFields = (
  shouldValidate = true,
  staffProfile: StaffProfile,
  isCompleted: boolean,
  isToggled: boolean
) => [
    {
      name: "residencyStatus",
      label: "Select Residency Status *",
      type: FieldType.Select,
      placeholder: "Select",
      className: "w-full ",
      requiredCondition: shouldValidate && "This is a required field",
      options: ResidencyOptions,
      defaultValue: staffProfile.residencyStatus,
      isDisabled: isCompleted,
      parentFormContainerClassName: "w-full md:!w-1/3 pr-6",
      containerClassName: "w-full ",
      hideSearch: true,
      isHiglighted: isToggled,
    },
  ];

const getCitizenFields = (
  staffProfile: StaffProfile,
  isCompleted: boolean,
  isToggled: boolean
) => [
    {
      type: FieldType.Paragraph,
      parentFormContainerClassName: "w-full",
      name: "",
      label: "",
      children: (
        <p className="mt-5">
          <span className="text-lg leading-6 font-semibold text-gray-900">
            Proof of citizenship
          </span>
          <p className="text-sm leading-5 font-normal text-gray-500">
            Please provide at least 1 of the 3 options
          </p>
        </p>
      ),
    },
    {
      name: "citizenShipCertificate",
      label: "Australian citizenship certificate",
      type: FieldType.FileUpload,
      parentFormContainerClassName:
        "md:w-1/3 w-full inline-block align-top pr-2 md:pr-6",
      defaultValue: staffProfile.citizenShipCertificate,
      isDisabled: isCompleted,
      externalLink:
        "https://immi.homeaffairs.gov.au/visas/already-have-a-visa/check-visa-details-and-conditions/check-conditions-online/visa-holders",
    },
    {
      name: "passport",
      label: "Certified copy of Passport",
      type: FieldType.FileUpload,
      parentFormContainerClassName:
        "md:w-1/3 w-full inline-block align-top pr-2 md:pr-6",
      defaultValue: staffProfile.passport,
      isDisabled: isCompleted,
      externalLink: "https://www.justice.vic.gov.au/certifiedcopies",
    },
    {
      name: "australianBirthCertificate",
      label: "Australian birth certificate",
      type: FieldType.FileUpload,
      parentFormContainerClassName:
        "md:w-1/3 w-full inline-block align-top md:pr-6",
      defaultValue: staffProfile.australianBirthCertificate,
      isDisabled: isCompleted,
      externalLink: "https://www.bdm.vic.gov.au/births/get-a-birth-certificate",
    },
  ];

const getPassportExpiry = (
  shouldValidate = true,
  staffProfile: StaffProfile,
  isCompleted: boolean,
  isToggled: boolean
) => [
    {
      name: "passportExpiryDate",
      label: "Passport expiry date *",
      type: FieldType.Calendar,
      parentFormContainerClassName: `w-full flex items-center justify-center`,
      defaultValue: staffProfile.passportExpiryDate,
      requiredCondition: shouldValidate && "This is a required field",
      isHiglighted: isToggled,
      containerClassName: "md:w-1/3  md:pr-6",
      isDisabled: isCompleted,
      minDate: getTodayDate(),
      wrapperClassName: "w-full",
      className: "w-full",
      placeholder: "Input passport expiry date",
    },
    {
      name: "passportNumber",
      label: "Passport number *",
      type: FieldType.Input,
      containerClassName: "md:w-1/3  md:pr-6",
      parentFormContainerClassName: "w-full flex items-center justify-center",
      requiredCondition: shouldValidate && "This is a required field",
      isHiglighted: isToggled,
      defaultValue: staffProfile.passportNumber,
      isDisabled: isCompleted,
      placeholder: "Input passport number",
    },
  ];

const getLegalWorkField = (
  shouldValidate = true,
  staffProfile: StaffProfile,
  isCompleted: boolean,
  isToggled: boolean
) => [
    {
      name: "legallyWorkInAustralia",
      label: "Are you legally entitled to work in Australia? *",
      type: FieldType.Radio,
      className: "mt-4 w-full",
      options: [
        { label: "Yes", value: "true" },
        {
          label: "No",
          value: "false",
        },
      ],
      requiredCondition: shouldValidate && "This is a required field",
      isHiglighted: isToggled,
      defaultValue: staffProfile.legallyWorkInAustralia?.toString(),
      isDisabled: isCompleted,
    },
  ];

export const validateDate = (date: Date | null) => {
  if (date) {
    const today = new Date();
    const sixMonthsFromToday = new Date(today.setMonth(today.getMonth() + 6));
    if (date < sixMonthsFromToday) {
      return "The date cannot be before the current date and should be at least 6 months valid.";
    }
  }
  return true;
};

const getOtherFields = (
  shouldValidate = true,
  staffProfile: StaffProfile,
  isCompleted: boolean,
  isToggled: boolean
) => [
    {
      name: "visaType",
      label: "Visa type *",
      type: FieldType.Select,
      placeholder: "Select",
      className: "w-full ",
      containerClassName: "w-full ",
      requiredCondition: shouldValidate && "This is a required field",
      isHiglighted: isToggled,
      options: VisaTypeOptions,
      parentFormContainerClassName: "md:w-1/3 w-full inline-block align-top pr-6",
      defaultValue: staffProfile.visaType,
      isDisabled: isCompleted,
    },
    {
      name: "visaClass",
      label: "Visa Class/Subclass i.e TU/573 *",
      type: FieldType.Input,
      className: "w-full ",
      containerClassName: "w-full ",
      placeholder: "Visa Class example",
      parentFormContainerClassName: "md:w-1/3 w-full  inline-block align-top",
      requiredCondition: shouldValidate && "This is a required field",
      isHiglighted: isToggled,
      defaultValue: staffProfile.visaClass,
      isDisabled: isCompleted,
    },
    {
      name: "workEntitlement",
      label: "Work entitlements *",
      type: FieldType.Radio,
      className: "w-96",
      options: [
        { label: "Limited", value: "limited" },
        {
          label: "Unlimited",
          value: "unlimited",
        },
      ],
      requiredCondition: shouldValidate && "This is a required field",
      isHiglighted: isToggled,
      defaultValue: staffProfile.workEntitlement,
      isDisabled: isCompleted,
    },
    {
      name: "visaGrantDate",
      label: "Visa grant date *",
      type: FieldType.Calendar,
      parentFormContainerClassName:
        "md:w-1/3 w-full  inline-block align-top pr-6",
      requiredCondition: shouldValidate && "This is a required field",
      isHiglighted: isToggled,
      defaultValue: staffProfile.visaGrantDate,
      isDisabled: isCompleted,
      maxDate: getTodayDate(),
      className: "w-full ",
      containerClassName: "w-full ",
      wrapperClassName: "w-full ",
    },
    {
      name: "visaExpiryDate",
      label: "Visa expiry date (Visa must be valid for at least 6 months)*",
      type: FieldType.Calendar,
      parentFormContainerClassName: "md:w-96 w-full  inline-block align-top",
      requiredCondition: shouldValidate && "This is a required field",
      isHiglighted: isToggled,
      validateFunction: validateDate,
      defaultValue: staffProfile.visaExpiryDate,
      isDisabled: isCompleted,
      containerClassName: "w-full",
      wrapperClassName: "w-full ",
      className: "w-96 ",
      minDate: getDateAfterPeriod(1, 6),
    },
    {
      name: "vevoRecord",
      label: "Upload vevo *",
      type: FieldType.FileUpload,
      buttonLabel: "Attach vevo",
      placeholder: "or drag and drop",
      parentFormContainerClassName: "w-2/3",
      requiredCondition: shouldValidate && "This is a required field",
      isHiglighted: isToggled,
      defaultValue: staffProfile.vevoRecord,
      isDisabled: isCompleted,
      externalLink:
        "https://immi.homeaffairs.gov.au/visas/already-have-a-visa/check-visa-details-and-conditions/check-conditions-online/visa-holders",
    },
    {
      name: "passport",
      label: "Certified copy of Passport *",
      type: FieldType.FileUpload,
      parentFormContainerClassName: "w-full",
      fileUploadContainerClassName: "md:w-2/3",
      defaultValue: staffProfile.passport,
      isDisabled: isCompleted,
      externalLink: "https://www.justice.vic.gov.au/certifiedcopies",
      requiredCondition: shouldValidate && "This is a required field",
      isHiglighted: isToggled,
    },
    {
      name: "passportExpiryDate",
      label: "Passport expiry date *",
      type: FieldType.Calendar,
      parentFormContainerClassName: "md:w-1/3 w-full inline-block align-top pr-6",
      defaultValue: staffProfile.passportExpiryDate,
      requiredCondition: shouldValidate && "This is a required field",
      isHiglighted: isToggled,
      containerClassName: "w-full",
      isDisabled: isCompleted,
      minDate: getTodayDate(),
      wrapperClassName: "w-full",
      className: "w-full",
      placeholder: "Input passport expiry date",
    },
    {
      name: "passportNumber",
      label: "Passport number *",
      type: FieldType.Input,
      containerClassName: "md:w-full",
      className: " mt-1",
      parentFormContainerClassName: "md:w-1/3 w-full inline-block align-top",
      requiredCondition: shouldValidate && "This is a required field",
      isHiglighted: isToggled,
      defaultValue: staffProfile.passportNumber,
      isDisabled: isCompleted,
      placeholder: "Input passport number",
    },
  ];

export default function Step1({ user, isCompleted, isToggled }: StepProps) {
  const {
    setError,
    watch,
    reset,
    handleSubmit,
    formState,
    getValues,
    trigger,
    ...formMethods
  } = useForm<FormData>({ shouldUnregister: true });
  const { staffProfile } = user;
  const navigate = useNavigate();
  const [response, setResponse] = useState<NotificationProps | null>(null);
  const [shouldValidate, setShouldValidate] = useState<boolean>();
  const [endApplicationModal, openEndApplicationModal] = useState(false);
  const [applicationEnded, openApplicationEnded] = useState(false);

  const [legallyWorkInAustralia, setLegallyWorkInAustralia] = useState<
    string | undefined
  >(undefined);

  const [residency, setResidency] = useState<RESIDENCY_STATUS | undefined>(
    undefined
  );

  const residencyStatus = watch("residencyStatus");
  const canlegallyWorkInAustralia = watch("legallyWorkInAustralia");
  const passport = watch("passport");

  useEffect(() => {
    setResidency(residencyStatus || staffProfile.residencyStatus);
  }, [residencyStatus, staffProfile.residencyStatus]);
  useEffect(() => {
    setLegallyWorkInAustralia(
      canlegallyWorkInAustralia ||
      staffProfile.legallyWorkInAustralia?.toString()
    );
  }, [canlegallyWorkInAustralia, staffProfile.legallyWorkInAustralia]);

  const onSubmit = async (data: FormData) => {
    setResponse(null);
    if (residencyStatus === "citizen") {
      if (
        !(
          data.citizenShipCertificate ||
          (data.passport && data.passportExpiryDate) ||
          data.australianBirthCertificate
        )
      ) {
        setResponse({
          type: "error",
          message: "Please provide at least 1 of the 3 options",
        });
        return;
      }
      data.passport?.length === 0 && delete data.passport;
    }
    if (residencyStatus !== "citizen") {
      if (data.legallyWorkInAustralia) {
        data.legallyWorkInAustralia =
          data.legallyWorkInAustralia.toLowerCase() === "false" ? false : true;
      }
      if (!data.legallyWorkInAustralia) {
        const response = await onboardStaff(
          1,
          data,
          "submit",
          FIELDS_TO_REMOVE
        );
        handleResponse(response, setError, setResponse);
        openEndApplicationModal(false);
        openApplicationEnded(true);
        setTimeout(() => navigate("/login"), 10000);
      }
    }

    const response = await onboardStaff(1, data, "submit", FIELDS_TO_REMOVE);
    const isSuccess = await handleResponse(response, setError, setResponse);
    isSuccess && navigate("/onboarding/staff/step/2");
  };

  const saveForLater = async (data: FormData) => {
    setResponse(null);
    setShouldValidate(true);
    if (!data.residencyStatus) {
      setResponse({
        type: "info",
        message: "No data inputted to be saved",
      });
      return;
    }
    if (data.legallyWorkInAustralia) {
      data.legallyWorkInAustralia =
        data.legallyWorkInAustralia.toLowerCase() === "false" ? false : true;
    }

    const response = await onboardStaff(1, data, "save", FIELDS_TO_REMOVE);
    handleResponse(response, setError, setResponse);
  };

  const getFields = useCallback(() => {
    if (!residency && !staffProfile.residencyStatus) {
      return [
        ...getRequiredFields(
          shouldValidate,
          staffProfile,
          isCompleted,
          isToggled
        ),
      ];
    }
    if (residency === RESIDENCY_STATUS.citizen) {
      if (passport && passport.length > 0) {
        return [
          ...getRequiredFields(
            shouldValidate,
            staffProfile,
            isCompleted,
            isToggled
          ),
          ...getCitizenFields(staffProfile, isCompleted, isToggled),
          ...getPassportExpiry(
            shouldValidate,
            staffProfile,
            isCompleted,
            isToggled
          ),
        ];
      }
      return [
        ...getRequiredFields(
          shouldValidate,
          staffProfile,
          isCompleted,
          isToggled
        ),
        ...getCitizenFields(staffProfile, isCompleted, isToggled),
      ];
    }
    if (
      !legallyWorkInAustralia ||
      legallyWorkInAustralia?.toLowerCase() === "false"
    ) {
      return [
        ...getRequiredFields(
          shouldValidate,
          staffProfile,
          isCompleted,
          isToggled
        ),
        ...getLegalWorkField(
          shouldValidate,
          staffProfile,
          isCompleted,
          isToggled
        ),
      ];
    }
    return [
      ...getRequiredFields(
        shouldValidate,
        staffProfile,
        isCompleted,
        isToggled
      ),
      ...getLegalWorkField(
        shouldValidate,
        staffProfile,
        isCompleted,
        isToggled
      ),
      ...getOtherFields(shouldValidate, staffProfile, isCompleted, isToggled),
    ];
  }, [
    residency,
    staffProfile,
    legallyWorkInAustralia,
    shouldValidate,
    isCompleted,
    passport,
    isToggled,
  ]);

  const handleButtonClick = async () => {
    setShouldValidate(false);
    setTimeout(() => handleSubmit(saveForLater)(), 100);
  };

  return (
    <div className="bg-white shadow rounded-md">
      <div className="p-6 pr-0 pb-0">
        <div className="flex flex-col ">
          <Notification
            type="warningExclamation"
            message="All mandatory fields must be completed in order to continue to the next step."
            className="!inline-flex !m-0 w-max"
            iconClassName="text-yellow-500"
          />
          {response ? (
            <Notification
              type={response.type}
              message={response.message}
              className="!inline-flex !m-0 !mt-2 w-max"
            />
          ) : (
            <></>
          )}
        </div>
        <ReusableForm
          fields={getFields()}
          onSubmit={onSubmit}
          id="save-step1"
          formClassName="pb-6"
          formMethods={{
            ...formMethods,
            setError,
            watch,
            reset,
            handleSubmit,
            formState,
            getValues,
            trigger,
          }}
        />
        {legallyWorkInAustralia?.toLowerCase() === "false" && (
          <Notification
            className="!mb-5 !ml-0"
            size={24}
            type="error"
            message="Sorry you will not be able to continue completing your Staff profile. You must have Australian work rights to work in Australia. You can save your progress to sort this out and continue later or click “End job application” button to end this job application. Call us on 1300 180 888 if you need further assistance."
          />
        )}
      </div>
      <FormFooter
        saveforLater={handleButtonClick}
        isDisabled={isCompleted}
        formId="save-step1"
        step={1}
        isSaveForLaterButtonDisabled={!Boolean(residency)}
        isDifferentSubmit={legallyWorkInAustralia?.toLowerCase() === "false"}
        DifferentSubmitButton={
          <Button
            variant="secondary"
            size="sm"
            onClick={() => openEndApplicationModal(true)}
          >
            End job application
          </Button>
        }
      />
      <Modal
        isOpen={endApplicationModal}
        onClose={() => openEndApplicationModal(false)}
        modalHeader=""
        className="!bg-yellow-50"
        modalBodyClassName="!mt-2"
      >
        <h3 className="text-yellow-900 font-bold flex justify-start items-center px-4">
          <Icon name="warning" />
          <span className="ml-3">
            Please confirm you want to end this job application
          </span>
        </h3>
        <div className="flex justify-start items-center  rounded-b-md px-8 py-1  rounded-b-md">
          <Button
            variant="text"
            onClick={() => openEndApplicationModal(false)}
            className="mr-3 text-yellow-800"
          >
            Cancel
          </Button>
          <Button
            variant="text"
            className="text-yellow-800"
            onClick={() => handleSubmit(onSubmit)()}
          >
            Confirm
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={applicationEnded}
        onClose={() => {
          navigate("/login");
          openApplicationEnded(false);
        }}
        modalHeader=""
        className="!bg-transparent"
        modalBodyClassName="!mt-2"
      >
        <Notification
          type="success"
          message="Application successfully terminated"
        />
      </Modal>
    </div>
  );
}
