import { StaffProfile, User } from "api/types";
import { Option } from "components/Select";
import { FieldType } from "components/types";
import { toZonedTime } from "date-fns-tz";
import { OccupationOptions } from "routes/dashboard/staff/InviteStaff";
import { validateDate } from "routes/onboarding/staff/steps/step1";
import { qualificationOptions } from "routes/onboarding/staff/steps/step4";
import {
  AUSTRALIA_STATE_OPTIONS,
  capitalizeFirstLetter,
  formatDate,
  formatDateWithDay,
  formatDateWithTime,
  getDateAfterPeriod,
  getFormattedDate,
  getTodayDate,
  getTomorrowDate,
  isBoolean,
  maskString,
  validateAustralianPhoneNumber,
} from "./util";

export const getPersonalInformationData = (user: User) => [
  [
    { title: "title", value: user.title, columnWidth: 5 },
    { title: "firstName", value: user.firstName, columnWidth: 6 },
    { title: "lastName", value: user.lastName, columnWidth: 6 },
    { title: "email", value: user.email, columnWidth: 4 },
    { title: "mobile number", value: user.mobile || "N/A", columnWidth: 5 },
  ],
  [
    {
      title: "Home telephone number",
      value: user.staffProfile?.homePhone || "N/A",
      columnWidth: 5,
    },
    {
      title: "Occupation",
      value: capitalizeFirstLetter(user.staffProfile?.occupation),
      columnWidth: 6,
    },
    {
      title: "Residential address",
      value: user.staffProfile.residentialAddressStreetName
        ? capitalizeFirstLetter(
            `${user.staffProfile.residentialAddressBuildingNo} ${user.staffProfile.residentialAddressStreetName}, ${user.staffProfile.residentialAddressSuburb}, ${user.staffProfile.residentialAddressState} ${user.staffProfile.residentialAddressZip}`
          )
        : "N/A",
      columnWidth: 6,
    },
    {
      title: "Mailing addresss",
      value: user.staffProfile.mailingAddressStreetName
        ? capitalizeFirstLetter(
            `${user.staffProfile.mailingAddressBuildingNo} ${user.staffProfile.mailingAddressStreetName}, ${user.staffProfile.mailingAddressSuburb}, ${user.staffProfile.mailingAddressState} ${user.staffProfile.mailingAddressZip}`
          )
        : "N/A",
      columnWidth: 4,
    },
    {
      title: "Gender",
      value: capitalizeFirstLetter(user.staffProfile?.gender || "N/A"),
      columnWidth: 5,
    },
  ],
  [
    {
      title: "Date of birth",
      value: user.staffProfile?.dob
        ? formatDate(new Date(user.staffProfile?.dob))
        : "N/A",
      columnWidth: 5,
    },
    {
      title: "Country of birth",
      value: capitalizeFirstLetter(user.staffProfile?.birthCountry || "N/A"),
      columnWidth: 6,
    },
    {
      title: "Home region",
      value: capitalizeFirstLetter(user.staffProfile?.homeRegion || "N/A"),
      columnWidth: 6,
    },
    {
      title: "Spoken language(s)",
      value: user.staffProfile?.spokenLanguages?.join(", ") || "N/A",
      columnWidth: 4,
    },
    {
      title: "I identify as Aboriginal/TSI",
      value: user.staffProfile?.aboriginalTSIStatus || "N/A",
      columnWidth: 5,
    },
  ],
];
export const getEmergencyContactData = (emergencyContact: User) => [
  [
    {
      title: "email",
      value: capitalizeFirstLetter(emergencyContact?.email) || "N/A",
    },
    { title: "mobile number", value: emergencyContact?.mobile || "N/A" },
    {
      title: "Home telephone number",
      value: emergencyContact?.homeTelephoneNo || "N/A",
    },
    {
      title: "Relationship",
      value: capitalizeFirstLetter(emergencyContact?.relationShip) || "N/A",
    },
  ],
];
export const getStatutoryDeclarationData = (staffProfile: StaffProfile) => [
  [
    {
      title: "Statutory declaration",
      value: staffProfile?.signedStatutoryForm
        ? [staffProfile?.signedStatutoryForm]
        : "N/A",
      isTypeFile: Boolean(staffProfile?.signedStatutoryForm),
    },
  ],
];
export const getTrainingData = (
  staffProfile: StaffProfile,
  isAdminView: boolean
) => [
  [
    {
      title: "Highest qualification in your country",
      value: capitalizeFirstLetter(staffProfile?.highestQualification) || "N/A",
    },
    {
      title: "School for highest qualification",
      value:
        capitalizeFirstLetter(staffProfile?.highestQualificationSchool) ||
        "N/A",
    },
    {
      title: "Work-related certificates",
      value: staffProfile?.workCertificates
        ? staffProfile?.workCertificates
        : "N/A",
      isTypeFile: Boolean(staffProfile?.workCertificates),
    },
    {
      title: "Food handling certificate",
      value: staffProfile?.foodHandlingCertificate
        ? staffProfile?.foodHandlingCertificate
        : "N/A",
      isTypeFile: Boolean(staffProfile?.foodHandlingCertificate),
    },
  ],
  [
    {
      title: "Infection control training",
      value: staffProfile?.infectionControlCertificate
        ? staffProfile?.infectionControlCertificate
        : "N/A",
      isTypeFile: Boolean(staffProfile?.infectionControlCertificate),
    },
    {
      title: "Resume",
      value: staffProfile?.resume ? [staffProfile?.resume] : "N/A",
      isTypeFile: Boolean(staffProfile?.resume),
    },
    {
      title: "Aged care certificate (AHPRA)",
      value: staffProfile?.ahpra ? [staffProfile?.ahpra] : "N/A",
      isTypeFile: Boolean(staffProfile?.ahpra),
      cellClassName: !isAdminView ? "hidden" : "",
    },
    {
      title: "Aged care certificate (AHPRA) date",
      value: Boolean(staffProfile.ahpraDate)
        ? formatDate(new Date(staffProfile.ahpraDate))
        : "N/A",
      cellClassName: !isAdminView ? "hidden" : "",
    },
  ],
];
export const getWorkRightsData = (staffProfile: StaffProfile) => [
  [
    {
      title: "Australian Residency status",
      value: capitalizeFirstLetter(
        staffProfile?.residencyStatus?.replace("_", " ") || "N/A"
      ),
      cellClassName: "md:!w-76",
    },
    {
      title: "Legally entitled to work in Australia",
      value: staffProfile?.legallyWorkInAustralia ? "Yes" : "No",
      cellClassName: "md:!w-80",
    },
    {
      title: "Visa type",
      value: capitalizeFirstLetter(staffProfile?.visaType) || "N/A",
      cellClassName: "md:!w-64",
    },
    {
      title: "Visa Class/Subclass i.e TU/573",
      value: staffProfile?.visaClass || "N/A",
      cellClassName: "md:!w-72",
    },
  ],
  [
    {
      title: "Work entitlements",
      value: capitalizeFirstLetter(staffProfile?.workEntitlement) || "N/A",
      cellClassName: "md:!w-76",
    },
    {
      title: "Visa grant date",
      value: staffProfile?.visaGrantDate
        ? formatDate(new Date(staffProfile?.visaGrantDate))
        : "N/A",
      cellClassName: "md:!w-80",
    },
    {
      title: "Visa expiry date",
      value: staffProfile?.visaExpiryDate
        ? formatDate(new Date(staffProfile?.visaExpiryDate))
        : "N/A",
      cellClassName: "md:!w-64",
    },
    {
      title: "Vevo",
      value: staffProfile?.vevoRecord ? [staffProfile?.vevoRecord] : "N/A",
      isTypeFile: Boolean(staffProfile?.vevoRecord),
      cellClassName: "md:!w-72",
    },
  ],
  [
    {
      title: "Australian citizenship certificate",
      value: staffProfile?.citizenShipCertificate
        ? [staffProfile?.citizenShipCertificate]
        : "N/A",
      isTypeFile: Boolean(staffProfile?.citizenShipCertificate),
      cellClassName: "md:!w-76",
    },
    {
      title: "Certified copy of Passport",
      value: staffProfile?.passport ? [staffProfile?.passport] : "N/A",
      isTypeFile: Boolean(staffProfile?.passport),
      cellClassName: "md:!w-80",
    },
    {
      title: "Australian birth certificate",
      value: staffProfile?.australianBirthCertificate
        ? [staffProfile?.australianBirthCertificate]
        : "N/A",
      isTypeFile: Boolean(staffProfile?.australianBirthCertificate),
      cellClassName: "md:!w-64",
    },
    {
      title: "Passport expiry date",
      value: Boolean(staffProfile.passportExpiryDate)
        ? formatDate(new Date(staffProfile.passportExpiryDate))
        : "N/A",
      cellClassName: "md:!w-72",
      minDate: getTodayDate(),
    },
  ],
  [
    {
      title: "Passport number",
      value: maskString(staffProfile?.passportNumber, 3, 0) || "N/A",
      cellClassName: "md:!w-72",
    },
  ],
];
export const getBankData = (staffProfile: StaffProfile) => [
  [
    {
      title: "Salary payment authorisation",
      value: isBoolean(staffProfile?.bankDetails?.paymentAuthorisation)
        ? "I authorise and request Pay Office to deposit my salary into the bank account added below"
        : "N/A",
      cellClassName: "w-full",
    },
  ],
  [
    {
      title: "Bank name",
      value:
        capitalizeFirstLetter(staffProfile?.bankDetails?.bankName) || "N/A",
      cellClassName: "md:!w-64",
    },
    {
      title: "Branch address",
      value:
        capitalizeFirstLetter(staffProfile?.bankDetails?.branchAddress) ||
        "N/A",
      cellClassName: "md:!w-44",
    },
    {
      title: "Account name",
      value:
        capitalizeFirstLetter(staffProfile?.bankDetails?.accountName) || "N/A",
      cellClassName: "md:!w-44",
    },
    {
      title: "Type of account",
      value:
        capitalizeFirstLetter(staffProfile?.bankDetails?.accountType) || "N/A",
      cellClassName: "md:!w-44",
    },
    {
      title: "Account number *",
      value:
        capitalizeFirstLetter(staffProfile?.bankDetails?.accountNumber) ||
        "N/A",
      cellClassName: "md:!w-52",
    },
    {
      title: "Branch number (BSB)",
      value:
        capitalizeFirstLetter(staffProfile?.bankDetails?.bankName) || "N/A",
      cellClassName: "md:!w-52",
    },
  ],
  [
    {
      title: "Tax File Number Declaration",
      value: staffProfile?.bankDetails?.tfn
        ? [staffProfile?.bankDetails?.tfn]
        : "N/A",
      isTypeFile: Boolean(staffProfile?.bankDetails?.tfn),
      cellClassName: "md:!w-64",
    },
    {
      title: "Super fund selection",
      value: Boolean(staffProfile.bankDetails?.superannuationFund)
        ? staffProfile?.bankDetails?.superannuationFund === "default"
          ? "I would like an account established with the default Superannuation fund"
          : "I would like to nominate a fund and have attached the Choice of Superannuation Fund Standard Choice Form and complying fund evidence"
        : "N/A",
      cellClassName: "flex-1",
    },
    {
      title: "Superannuation form",
      value: staffProfile?.bankDetails?.superannuationFundChoice
        ? [staffProfile?.bankDetails?.superannuationFundChoice]
        : "N/A",
      isTypeFile: Boolean(staffProfile?.bankDetails?.superannuationFundChoice),
      cellClassName: "md:!w-60",
    },
  ],
];
export const getInjuryData = (staffProfile: StaffProfile) => [
  [
    {
      title: "Read and understood",
      value: isBoolean(staffProfile.healthTermsUnderstood)
        ? "I have read and understood this form and the attached position description, and have discussed the employment with EICARE Pty. Ltd. I understand the responsibilities and physical demands of the employment."
        : "N/A",
      cellClassName: "w-full",
    },
  ],
  [
    {
      title: "Acknowledge",
      value: isBoolean(staffProfile.termsPreExisting)
        ? "I acknowledge that I am required to disclose all pre-existing conditions which I believe may be affected by me undertaking the employment."
        : "N/A",
      cellClassName: "w-full",
    },
  ],
  [
    {
      title: "Failure to disclose",
      value: isBoolean(staffProfile.termsFailureToDisclose)
        ? "I acknowledge that failure to disclose this information or providing false and misleading information may result in invoking Section 41 of Workplace Injury and Rehabilitation Act 2013 which may disentitle me or my dependents from receiving any worker’s compensation benefits relating to any recurrence, aggravation, acceleration, exacerbation or deterioration of any pre-existing condition which I may have arising out of or in the course of, the employment."
        : "N/A",
      cellClassName: "w-full",
    },
  ],
  [
    {
      title: "Declared statement",
      value: isBoolean(staffProfile?.termsDeclarationPreExisting)
        ? staffProfile?.termsDeclarationPreExisting
          ? "I have suffered the following conditions that may recur or deteriorate, accelerate or be exacerbated or aggravated by the employment."
          : "I have suffered no prior injuries that may recur or deteriorate, accelerate or be exacerbated or aggravated by the employment."
        : "N/A",
      cellClassName: "w-full",
    },
  ],
  [
    {
      title: "Health condition(s)",
      value: staffProfile?.termsDeclaredStatement || "N/A",
      cellClassName: "w-full",
    },
  ],
  [
    {
      title: "Declare and acknowledge statement",
      value: isBoolean(staffProfile.termsDeclarationAcknowledgment)
        ? "I acknowledge and declare that the information provided in this form is true and correct in every particular."
        : "N/A",
      cellClassName: "w-full",
    },
  ],
  [
    {
      title: "Health declaration signature",
      value: staffProfile?.signature || "N/A",
    },
    {
      title: "Signed date",
      value: staffProfile?.signedAt
        ? getFormattedDate(new Date(staffProfile?.signedAt))
        : "N/A",
    },
    {
      title: "Immunisation History",
      value: staffProfile?.immunisationHistory
        ? [staffProfile?.immunisationHistory]
        : "N/A",
      isTypeFile: Boolean(staffProfile?.immunisationHistory),
    },
    {
      title: "Covid-19 vaccine certificate",
      value: staffProfile?.covidVaccineCertificate
        ? [staffProfile?.covidVaccineCertificate]
        : "N/A",
      isTypeFile: Boolean(staffProfile?.covidVaccineCertificate),
    },
  ],
];
export const getEmployeeCodeOfConductData = (staffProfile: StaffProfile) => [
  [
    {
      title: "Downloaded employee handboook",
      value: isBoolean(staffProfile?.downloadEmployeeHandbook)
        ? staffProfile?.downloadEmployeeHandbook
          ? "Yes"
          : "No"
        : "N/A",
    },
    {
      title: "Read employee handbook",
      value: isBoolean(staffProfile.readEmployeeHandbook)
        ? "I have read the employee handbook"
        : "N/A",
    },
    {
      title: "Code of Conduct acknowledgment",
      value: isBoolean(staffProfile.acknowledgeCodeOfConduct)
        ? "I have read and agree to the Code of Conduct"
        : "N/A",
    },
  ],
  [
    {
      title: "Sign Code of conduct ",
      value: staffProfile?.signatureCodeOfConduct || "N/A",
      columnWidth: 3,
    },
    {
      title: "Signed date",
      value: staffProfile?.signatureCodeOfConductDate
        ? getFormattedDate(new Date(staffProfile?.signatureCodeOfConductDate))
        : "N/A",
      columnWidth: 3,
    },
  ],
];
export const getVehicleData = (user: User) => {
  const { staffProfile } = user;
  return [
    [
      {
        title: "Owns a vehicle",
        value: isBoolean(staffProfile?.vehicleOwnership)
          ? staffProfile?.vehicleOwnership
            ? "Yes"
            : "No"
          : "N/A",
      },
      {
        title: "Vehicle owner",
        value: isBoolean(staffProfile?.vehicleOwnership)
          ? staffProfile?.vehicleOwnership
            ? `${capitalizeFirstLetter(
                user?.firstName
              )} ${capitalizeFirstLetter(user?.lastName)}`
            : "N/A"
          : "N/A",
      },
      {
        title: "Make of vehicle",
        value:
          capitalizeFirstLetter(staffProfile?.vehicleDetails?.vehicleMake) ||
          "N/A",
      },
      {
        title: "Model of vehicle",
        value:
          capitalizeFirstLetter(staffProfile?.vehicleDetails?.vehicleModel) ||
          "N/A",
      },
      {
        title: "Engine size",
        value: staffProfile?.vehicleDetails?.engineSize || "N/A",
      },
    ],
    [
      {
        title: "Vehicle plate number",
        value: staffProfile?.vehicleDetails?.vehiclePlateNumber || "N/A",
      },
      {
        title: "Vehicle registration",
        value: staffProfile?.vehicleDetails?.vehicleRegistration
          ? [staffProfile?.vehicleDetails?.vehicleRegistration]
          : "N/A",
        isTypeFile: Boolean(staffProfile?.vehicleDetails?.vehicleRegistration),
      },
      {
        title: "Registration expiry date",
        value: staffProfile?.vehicleDetails?.vehicleRegistrationExpiry
          ? formatDate(
              new Date(staffProfile?.vehicleDetails?.vehicleRegistrationExpiry)
            )
          : "N/A",
      },
      {
        title: "Vehicle insurance",
        value: staffProfile?.vehicleDetails?.vehicleInsurance
          ? [staffProfile?.vehicleDetails?.vehicleInsurance]
          : "N/A",
        isTypeFile: Boolean(staffProfile?.vehicleDetails?.vehicleInsurance),
      },
      {
        title: "Vehicle insurance expiry date",
        value: staffProfile?.vehicleDetails?.vehicleInsuranceExpiry
          ? formatDate(
              new Date(staffProfile?.vehicleDetails?.vehicleInsuranceExpiry)
            )
          : "N/A",
      },
    ],
    [
      {
        title: "Driving licence number",
        value: staffProfile?.vehicleDetails?.drivingLicenseNumber || "N/A",
        columnWidth: 5,
      },
      {
        title: "Driving licence expiry date",
        value: staffProfile?.vehicleDetails?.drivingLicenseExpiry
          ? formatDate(
              new Date(staffProfile?.vehicleDetails?.drivingLicenseExpiry)
            )
          : "N/A",
        columnWidth: 5,
      },
      {
        title: "Certified driving licence (Front & back)",
        value:
          staffProfile?.vehicleDetails?.drivingLicenseFront &&
          staffProfile?.vehicleDetails?.drivingLicenseBack
            ? [
                staffProfile?.vehicleDetails?.drivingLicenseFront,
                staffProfile?.vehicleDetails?.drivingLicenseBack,
              ]
            : "N/A",
        isTypeFile: Boolean(
          staffProfile?.vehicleDetails?.drivingLicenseFront &&
            staffProfile?.vehicleDetails?.drivingLicenseBack
        ),
        columnWidth: 2,
      },
    ],
  ];
};
export const getContractData = (
  staffProfile: StaffProfile,
  isAdminView: boolean,
  adminStaffOptions: Option[]
) => [
  [
    {
      title: "signed contract",
      value: staffProfile?.contractSigned
        ? [staffProfile?.contractSigned]
        : "N/A",
      isTypeFile: Boolean(staffProfile?.contractSigned),
    },
    {
      title: "Contract received date",
      value: staffProfile?.contractReceivedDate
        ? formatDateWithTime(new Date(staffProfile?.contractReceivedDate))
        : "N/A",
      cellClassName: !isAdminView ? "hidden" : "",
    },
    {
      title: "Employment start date",
      value: staffProfile?.empoymentStartDate
        ? formatDateWithTime(new Date(staffProfile?.empoymentStartDate))
        : "N/A",
      cellClassName: !isAdminView ? "hidden" : "",
    },
    {
      title: "Employment end date",
      value: staffProfile?.employmentEndDate
        ? formatDateWithTime(new Date(staffProfile?.employmentEndDate))
        : "N/A",
      cellClassName: !isAdminView ? "hidden" : "",
    },
    {
      title: "Reports to Manager/Supervisor",
      value: staffProfile?.reportingManagerId
        ? capitalizeFirstLetter(
            adminStaffOptions.filter(
              (admin) => admin.value === staffProfile.reportingManagerId
            )[0]?.label
          )
        : "N/A",
      cellClassName: !isAdminView ? "hidden" : "",
    },
  ],
  [
    {
      title: "Availability to commence date",
      value: staffProfile?.availiableToCommenceDate
        ? formatDate(new Date(staffProfile?.availiableToCommenceDate))
        : "N/A",
      cellClassName: !isAdminView ? "hidden" : "",
    },
  ],
  [
    {
      title: "Personal care worker job description",
      value: "",
      cellClassName: staffProfile.isPersonalCareWorker ? "" : "hidden",
      cellTitleClassName:
        "!text-lg !leading-6 !font-semibold !text-gray-900 !tracking-normal !normal-case !mt-2",
    },
  ],
  [
    {
      title: "Acknowledgement",
      value: staffProfile.contractAcknowledged
        ? "I certify that I have read, understood and accept the duties,responsibilities and obligations of my position."
        : "N/A",
      cellClassName: staffProfile.isPersonalCareWorker ? "" : "hidden",
    },
  ],
  [
    {
      title: "Employee Signature",
      value: staffProfile.contractAcknowledgedSignature || "N/A",
      cellClassName: staffProfile.isPersonalCareWorker ? "" : "hidden",
      columnWidth: 6,
    },
    {
      title: "Signed date",
      value: staffProfile?.contractAcknowledgedAt
        ? formatDateWithTime(new Date(staffProfile.contractAcknowledgedAt))
        : "N/A",
      cellClassName: staffProfile.isPersonalCareWorker ? "" : "hidden",
      columnWidth: 6,
    },
    {
      title: "Manager Name",
      value: staffProfile?.contractReportsToManager?.firstName
        ? capitalizeFirstLetter(
            `${staffProfile?.contractReportsToManager.firstName} ${staffProfile?.contractReportsToManager.lastName}`
          )
        : "N/A",
      cellClassName:
        isAdminView && staffProfile.isPersonalCareWorker ? "" : "hidden",
      columnWidth: 6,
    },
    {
      title: "Manager signature",
      value: staffProfile.contractAdminSignature || "N/A",
      cellClassName:
        isAdminView && staffProfile.isPersonalCareWorker ? "" : "hidden",
      columnWidth: 6,
    },
    {
      title: "Signed date",
      value: staffProfile?.contractAdminSignedAt
        ? formatDateWithTime(new Date(staffProfile.contractAdminSignedAt))
        : "N/A",
      cellClassName:
        isAdminView && staffProfile.isPersonalCareWorker ? "" : "hidden",
      columnWidth: 6,
    },
  ],
];

export const getPoliceCheckData = (staffProfile: StaffProfile) => [
  [
    {
      title: "Police check",
      value: staffProfile?.policeCheck ? [staffProfile?.policeCheck] : "N/A",
      isTypeFile: Boolean(staffProfile?.policeCheck),
      columnWidth: 5,
    },
    {
      title: "Police check expiry date",
      value: staffProfile?.policeCheckValidUntil
        ? formatDate(new Date(staffProfile?.policeCheckValidUntil))
        : "N/A",
      columnWidth: 5,
    },
  ],
];

export const getInterviewData = (staffProfile: StaffProfile) => [
  [
    {
      title: "Preferred interview date",
      value: Boolean(staffProfile?.interviewDate)
        ? formatDateWithDay(new Date(staffProfile.interviewDate))
        : "N/A",
      columnWidth: 5,
    },
    {
      title: "Preferred interview time",
      value: Boolean(staffProfile?.interviewDate)
        ? new Date(staffProfile.interviewDate).toLocaleString("en-AU", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
            timeZone: "Australia/Sydney",
            timeZoneName: "short",
          })
        : "N/A",
      columnWidth: 5,
    },
    {
      title: "Timezone",
      value: Boolean(staffProfile?.interviewDate)
        ? `Melbourne VIC, Australia ${new Date(staffProfile.interviewDate)
            .toLocaleString("en-AU", {
              timeZone: "Australia/Victoria",
              timeZoneName: "shortOffset",
            })
            ?.split(" ")
            ?.pop()}`
        : "N/A",
    },
  ],
];

export const getReference1Data = (staffProfile: StaffProfile) => [
  [
    {
      title: "Title ",
      value: staffProfile?.reference1?.title || "N/A",
    },
    {
      title: "Firstname",
      value: staffProfile?.reference1?.firstName || "N/A",
    },
    {
      title: "Lastname ",
      value: staffProfile?.reference1?.lastName || "N/A",
    },
  ],
  [
    {
      title: "Email ",
      value: staffProfile?.reference1?.email || "N/A",
    },
    {
      title: "Mobile ",
      value: staffProfile?.reference1?.mobile || "N/A",
    },
    {
      title: "Relationship ",
      value: staffProfile?.reference1?.relationShip || "N/A",
    },
  ],
];

export const getReference2Data = (staffProfile: StaffProfile) => [
  [
    {
      title: "Title ",
      value: staffProfile?.reference2?.title || "N/A",
    },
    {
      title: "Firstname",
      value: staffProfile?.reference2?.firstName || "N/A",
    },
    {
      title: "Lastname ",
      value: staffProfile?.reference2?.lastName || "N/A",
    },
  ],
  [
    {
      title: "Email ",
      value: staffProfile?.reference2?.email || "N/A",
    },
    {
      title: "Mobile ",
      value: staffProfile?.reference2?.mobile || "N/A",
    },
    {
      title: "Relationship ",
      value: staffProfile?.reference2?.relationShip || "N/A",
    },
  ],
];

export const editResidencyFields = (
  staffProfile: StaffProfile,
  residencyStatus: string
) => [
  {
    name: "residencyStatus",
    label: "Select Residency Status",
    type: FieldType.Select,
    placeholder: "Select",
    parentFormContainerClassName: `w-1/2 md:pr-6`,
    className: "w-full",
    containerClassName: "w-full",
    options: [
      { label: "Australian citizen", value: "citizen" },
      {
        label: "Australian permanent resident",
        value: "permanent_resident",
      },
      { label: "Other", value: "other" },
    ],
    defaultValue: staffProfile.residencyStatus,
    hideSearch: true,
  },
  {
    name: "citizenShipCertificate",
    label: "Australian citizenship certificate",
    type: FieldType.FileUpload,
    parentFormContainerClassName:
      residencyStatus === "citizen"
        ? "md:w-1/3 w-full inline-block align-top pr-2 md:pr-6"
        : "hidden",
    defaultValue: staffProfile.citizenShipCertificate,
    externalLink:
      "https://immi.homeaffairs.gov.au/visas/already-have-a-visa/check-visa-details-and-conditions/check-conditions-online/visa-holders",
  },
  {
    name: "passport",
    label: "Certified copy of Passport",
    type: FieldType.FileUpload,
    parentFormContainerClassName:
      residencyStatus === "citizen"
        ? "md:w-1/3 w-full inline-block align-top pr-2 md:pr-6"
        : "hidden",
    fileUploadContainerClassName:
      residencyStatus === "citizen" ? "" : "md:w-1/3 md:pr-6 my-3",
    defaultValue: staffProfile.passport,
    externalLink: "https://www.justice.vic.gov.au/certifiedcopies",
  },
  {
    name: "australianBirthCertificate",
    label: "Australian birth certificate",
    type: FieldType.FileUpload,
    parentFormContainerClassName:
      residencyStatus === "citizen"
        ? "md:w-1/3 w-full inline-block align-top pr-2"
        : "hidden",
    defaultValue: staffProfile.australianBirthCertificate,
    externalLink: "https://www.bdm.vic.gov.au/births/get-a-birth-certificate",
  },
  {
    name: "legallyWorkInAustralia",
    label: "Are you legally entitled to work in Australia? *",
    type: FieldType.Radio,
    className: "mt-4 w-full",
    options: [
      { label: "Yes", value: "true" },
      {
        label: "No",
        value: "false",
      },
    ],
    defaultValue: staffProfile.legallyWorkInAustralia?.toString(),
    parentFormContainerClassName:
      residencyStatus !== "citizen" ? "block" : "hidden",
  },
  {
    name: "visaType",
    label: "Visa type *",
    type: FieldType.Select,
    placeholder: "Select",
    options: [
      { label: "Studying and training", value: "Studying and training" },
      {
        label: "Work and skilled",
        value: "Work and skilled",
      },
      { label: "Visitor", value: "Visitor" },
      { label: "Family and partner", value: "Family and partner" },
      { label: "Permanent resident", value: "Permanent resident" },
      { label: "Refugee and humanitarian", value: "Refugee and humanitarian" },
      { label: "Other", value: "Other" },
    ],
    parentFormContainerClassName:
      residencyStatus !== "citizen"
        ? "w-1/2  inline-block align-top md:pr-6"
        : "hidden",
    defaultValue: staffProfile.visaType,
    className: "w-full",
    containerClassName: "w-full",
  },
  {
    name: "visaClass",
    label: "Visa Class/Subclass i.e TU/573 *",
    type: FieldType.Input,
    className: "!w-full pr-3",
    placeholder: "Visa Class example",
    parentFormContainerClassName:
      residencyStatus !== "citizen"
        ? "w-1/2  inline-block align-top"
        : "hidden",
    defaultValue: staffProfile.visaClass,
  },
  {
    name: "workEntitlement",
    label: "Work entitlements *",
    type: FieldType.Radio,
    className: "w-96",
    options: [
      { label: "Limited", value: "limited" },
      {
        label: "Unlimited",
        value: "unlimited",
      },
    ],
    defaultValue: staffProfile.workEntitlement,
    parentFormContainerClassName:
      residencyStatus !== "citizen" ? "block" : "hidden",
  },
  {
    name: "visaGrantDate",
    label: "Visa grant date *",
    type: FieldType.Calendar,
    parentFormContainerClassName:
      residencyStatus !== "citizen"
        ? "w-1/2  inline-block align-top md:pr-6"
        : "hidden",
    defaultValue: staffProfile.visaGrantDate,
    maxDate: getTodayDate(),
    className: "!w-full",
    wrapperClassName: "w-full",
  },
  {
    name: "visaExpiryDate",
    label: "Visa expiry date*",
    type: FieldType.Calendar,
    parentFormContainerClassName:
      residencyStatus !== "citizen"
        ? "w-1/2  inline-block align-top"
        : "hidden",
    validateFunction: validateDate,
    defaultValue: staffProfile.visaExpiryDate,
    className: "!w-full pr-3",
    minDate: getDateAfterPeriod(0, 6),
    wrapperClassName: "w-full",
  },
  {
    name: "vevoRecord",
    label: "Upload vevo *",
    type: FieldType.FileUpload,
    buttonLabel: "Attach vevo",
    placeholder: "or drag and drop",
    parentFormContainerClassName:
      residencyStatus !== "citizen" ? "w-full" : "hidden",
    defaultValue: staffProfile.vevoRecord,
  },
  {
    name: "passport",
    label: "Certified copy of Passport",
    type: FieldType.FileUpload,
    parentFormContainerClassName:
      residencyStatus === "citizen"
        ? "hidden"
        : "w-full flex items-center justify-center",
    fileUploadContainerClassName:
      residencyStatus === "citizen" ? "" : "md:w-full my-3",
    defaultValue: staffProfile.passport,
    externalLink: "https://www.justice.vic.gov.au/certifiedcopies",
  },
  {
    name: "passportExpiryDate",
    label: "Passport expiry date",
    type: FieldType.Calendar,
    parentFormContainerClassName: `w-1/2  inline-block align-top md:pr-6`,
    className: "w-full",
    wrapperClassName: "w-full",
    defaultValue: staffProfile.passportExpiryDate,
    minDate: getTodayDate(),
    placeholder: "Input passport expiry date",
  },
  {
    name: "passportNumber",
    label: "Passport number",
    type: FieldType.Input,
    parentFormContainerClassName: "w-1/2  inline-block align-top",
    defaultValue: staffProfile.passportNumber,
    placeholder: "Input passport number",
    className: "mt-1",
  },
];
export const editPersonalFields = (user: User) => [
  {
    name: "email",
    label: "Email *",
    type: FieldType.EmailInput,
    placeholder: "johndoe@gmail.com",
    parentFormContainerClassName: "md:w-1/3  inline-block align-top md:pr-6",
    defaultValue: user.email,
    containerClassName: "w-full",
  },
  {
    name: "occupation",
    label: "Occupation",
    type: FieldType.Select,
    placeholder: "Select an occupation",
    options: OccupationOptions,
    defaultValue: user.staffProfile?.occupation,
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top md:pr-6",
    containerClassName: "w-full",
    isDropdownFixed: true,
  },
  {
    name: "mobile",
    label: "Mobile *",
    type: FieldType.Input,
    containerClassName: "w-full",
    placeholder: "04XXX XXX XXX",
    parentFormContainerClassName: "w-full md:w-1/3  inline-block align-top",
    defaultValue: user.mobile,
    validationFunction: validateAustralianPhoneNumber,
    validationErrorMessage: "Invalid Australian phone number",
  },
  {
    name: "homePhone",
    label: "Home Telephone no.",
    type: FieldType.Input,
    className: "w-full",
    placeholder: "04XXX XXX XXX",
    parentFormContainerClassName: "w-full md:w-1/3  md:pr-6",
    containerClassName: "w-full",
    defaultValue: user.staffProfile.homePhone,
    validationFunction: validateAustralianPhoneNumber,
    validationErrorMessage: "Invalid Australian phone number",
  },
  {
    type: FieldType.Paragraph,
    parentFormContainerClassName: "w-full",
    name: "",
    label: "Residential address *",
  },
  {
    name: "residentialAddressBuildingNo",
    label: "Building/House/Unit number",
    type: FieldType.Input,
    placeholder: "2",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    defaultValue: user.staffProfile.residentialAddressBuildingNo,
  },
  {
    name: "residentialAddressStreetName",
    label: "Street name",
    type: FieldType.Input,
    placeholder: "Rose Street",
    parentFormContainerClassName:
      "md:w-1/2 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    defaultValue: user.staffProfile.residentialAddressStreetName,
  },
  {
    name: "residentialAddressSuburb",
    label: "Suburb",
    type: FieldType.Input,
    placeholder: "Clayton",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    defaultValue: user.staffProfile.residentialAddressSuburb,
  },
  {
    name: "residentialAddressState",
    label: "State/Territory",
    type: FieldType.Select,
    placeholder: "1301",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    defaultValue: user.staffProfile.residentialAddressState,
    options: AUSTRALIA_STATE_OPTIONS,
  },
  {
    name: "residentialAddressZip",
    label: "Postcode",
    type: FieldType.Input,
    placeholder: "3001",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    defaultValue: user.staffProfile.residentialAddressZip,
  },
  {
    type: FieldType.Paragraph,
    parentFormContainerClassName: "w-full",
    name: "",
    label: "",
  },
  {
    name: "mailingAddressBuildingNo",
    label: "Building/House/Unit number",
    type: FieldType.Input,
    placeholder: "2",
    parentFormContainerClassName:
      "md:w-1/4 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    defaultValue: user.staffProfile.mailingAddressBuildingNo,
  },
  {
    name: "mailingAddressStreetName",
    label: "Street name",
    type: FieldType.Input,
    placeholder: "Rose Street",
    parentFormContainerClassName:
      "md:w-1/2 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    defaultValue: user.staffProfile.mailingAddressStreetName,
  },
  {
    name: "mailingAddressSuburb",
    label: "Suburb",
    type: FieldType.Input,
    placeholder: "Clayton",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    defaultValue: user.staffProfile.mailingAddressSuburb,
  },
  {
    name: "mailingAddressState",
    label: "State/Territory",
    type: FieldType.Select,
    placeholder: "1301",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    defaultValue: user.staffProfile.mailingAddressState,
    options: AUSTRALIA_STATE_OPTIONS,
  },
  {
    name: "mailingAddressZip",
    label: "Postcode",
    type: FieldType.Input,
    placeholder: "3001",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    defaultValue: user.staffProfile.mailingAddressZip,
  },
];

export const editEmergencyContactDetails = (staffProfile: StaffProfile) => [
  {
    name: "emergencyContact.firstName",
    label: "First Name *",
    type: FieldType.Input,
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top px-2",
    className: "w-72",
    placeholder: "John",
    defaultValue: staffProfile?.emergencyContact?.firstName,
    isDisabled: true,
  },
  {
    name: "emergencyContact.lastName",
    label: "Last Name *",
    type: FieldType.Input,
    className: "w-72",
    placeholder: "Smith",
    parentFormContainerClassName: "w-full md:w-1/3  inline-block align-top",
    defaultValue: staffProfile?.emergencyContact?.lastName,
    isDisabled: true,
  },
  {
    name: "emergencyContact.email",
    label: "Email *",
    type: FieldType.EmailInput,
    placeholder: "johndoe@gmail.com",
    parentFormContainerClassName: "w-1/4  inline-block align-top",
    defaultValue: staffProfile?.emergencyContact?.email,
    isDisabled: true,
  },
  {
    name: "emergencyContact.mobile",
    label: "Mobile *",
    type: FieldType.Input,
    placeholder: "04XXX XXX XXX",
    parentFormContainerClassName: "w-1/4  inline-block align-top mx-3",
    defaultValue: staffProfile?.emergencyContact?.mobile,
    validationFunction: validateAustralianPhoneNumber,
    validationErrorMessage: "Invalid Australian phone number",
    isDisabled: true,
  },
  {
    name: "emergencyContact.homeTelephoneNo",
    label: "Home Telephone no",
    type: FieldType.Input,
    placeholder: "+614XXX XXX XXX",
    parentFormContainerClassName: "w-1/4  inline-block align-top mr-3",
    defaultValue: staffProfile?.emergencyContact?.homeTelephoneNo,
    validationFunction: validateAustralianPhoneNumber,
    validationErrorMessage: "Invalid Australian phone number",
    isDisabled: true,
  },
  {
    name: "emergencyContact.relationShip",
    label: "Relationship *",
    type: FieldType.Input,
    placeholder: "Uncle",
    parentFormContainerClassName: "w-1/5  inline-block align-top",
    isDisabled: true,
    defaultValue: staffProfile?.emergencyContact?.relationShip,
  },
];

export const editTraining = (staffProfile: StaffProfile) => [
  {
    name: "highestQualification",
    label: "Highest qualification in your country *",
    type: FieldType.Select,
    options: qualificationOptions,
    defaultValue: staffProfile.highestQualification,
    containerClassName: "w-full mr-3",
    parentFormContainerClassName: "!w-1/2 inline-block align-top md:pr-6",
    isDropdownFixed: true,
  },
  {
    name: "highestQualificationSchool",
    label: "School where highest qualification was attained *",
    type: FieldType.Input,
    parentFormContainerClassName: "w-1/2 inline-block align-top",
    containerClassName: "",
    defaultValue: staffProfile.highestQualificationSchool,
  },
  {
    name: "ahpra",
    label: "Aged care certificate (AHPRA)",
    type: FieldType.FileUpload,
    buttonLabel: "Attach ahpra",
    placeholder: "or drag and drop",
    defaultValue: staffProfile.ahpra,
    parentFormContainerClassName: `w-1/2  inline-block align-top md:pr-6`,
  },
  {
    name: "ahpraDate",
    label: "Aged care certificate (AHPRA) date",
    type: FieldType.Calendar,
    parentFormContainerClassName: `w-1/2  inline-block align-top md:pr-6`,
    className: "w-full",
    wrapperClassName: "w-full",
    defaultValue: staffProfile.ahpraDate,
    minDate: getTodayDate(),
    placeholder: "Input ahpra date",
  },
];

export const editReference1Data = (staffProfile: StaffProfile) => [
  {
    name: "reference1.title",
    label: "Title *",
    type: FieldType.Select,
    placeholder: "Select",
    containerClassName: "!w-full",
    options: [
      { label: "Mr", value: "Mr" },
      {
        label: "Mrs",
        value: "Mrs",
      },
      { label: "Miss", value: "Miss" },
    ],
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top pr-2",
    defaultValue: staffProfile?.reference1?.title,
    inputClassName: "w-3/4",
    hideSearch: true,
  },
  {
    name: "reference1.firstName",
    label: "First Name *",
    type: FieldType.Input,
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top pr-2",
    placeholder: "John",
    defaultValue: staffProfile?.reference1?.firstName,
  },
  {
    name: "reference1.lastName",
    label: "Last Name *",
    type: FieldType.Input,
    placeholder: "John",
    parentFormContainerClassName: "w-full md:w-1/3  inline-block align-top",
    defaultValue: staffProfile.reference1?.lastName,
  },
  {
    name: `reference1.email`,
    label: "Email *",
    type: FieldType.EmailInput,
    placeholder: "jamesbond@actionstuff.com",
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top pr-2",
    defaultValue: staffProfile?.reference1?.email,
  },
  {
    name: "reference1.mobile",
    label: "Mobile *",
    type: FieldType.Input,
    placeholder: "+614XXX XXX XXX",
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top pr-2",
    defaultValue: staffProfile?.reference1?.mobile,

    validationFunction: validateAustralianPhoneNumber,
    validationErrorMessage: "Invalid Australian phone number",
  },
  {
    name: "reference1.relationShip",
    label: "Relationship *",
    type: FieldType.Input,
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top pr-2",
    placeholder: "Manager",
    defaultValue: staffProfile.reference1?.relationShip,
  },
];
export const editReference2Data = (staffProfile: StaffProfile) => [
  {
    name: "reference2.title",
    label: "Title *",
    type: FieldType.Select,
    placeholder: "Select",
    containerClassName: "!w-full",
    options: [
      { label: "Mr", value: "Mr" },
      {
        label: "Mrs",
        value: "Mrs",
      },
      { label: "Miss", value: "Miss" },
    ],
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top pr-2",
    defaultValue: staffProfile?.reference2?.title,
    inputClassName: "w-3/4",
    hideSearch: true,
  },
  {
    name: "reference2.firstName",
    label: "First Name *",
    type: FieldType.Input,
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top pr-2",
    placeholder: "John",
    defaultValue: staffProfile?.reference2?.firstName,
  },
  {
    name: "reference2.lastName",
    label: "Last Name *",
    type: FieldType.Input,
    placeholder: "John",
    parentFormContainerClassName: "w-full md:w-1/3  inline-block align-top",
    defaultValue: staffProfile.reference2?.lastName,
  },
  {
    name: `reference2.email`,
    label: "Email *",
    type: FieldType.EmailInput,
    placeholder: "jamesbond@actionstuff.com",
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top pr-2",
    defaultValue: staffProfile?.reference2?.email,
  },
  {
    name: "reference2.mobile",
    label: "Mobile *",
    type: FieldType.Input,
    placeholder: "+614XXX XXX XXX",
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top pr-2",
    defaultValue: staffProfile?.reference2?.mobile,

    validationFunction: validateAustralianPhoneNumber,
    validationErrorMessage: "Invalid Australian phone number",
  },
  {
    name: "reference2.relationShip",
    label: "Relationship *",
    type: FieldType.Input,
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top pr-2",
    placeholder: "Manager",
    defaultValue: staffProfile.reference2?.relationShip,
  },
];

export const editPoliceCheck = (staffProfile: StaffProfile) => [
  {
    name: "policeCheck",
    label: "Police check *",
    buttonLabel: "Upload police check",
    type: FieldType.FileUpload,
    parentFormContainerClassName: "w-2/3 inline-block align-top pr-6",
    key: "policeCheck",
    defaultValue: staffProfile.policeCheck,
    isMultiple: false,
  },
  {
    name: "policeCheckValidUntil",
    label: "Police check expiry date *",
    type: FieldType.Calendar,
    parentFormContainerClassName: "w-full md:w-1/3 inline-block align-top",
    wrapperClassName: "w-full",
    defaultValue: staffProfile.policeCheckValidUntil,
    minDate: getTodayDate(),
    className: "w-full",
  },
];

export const editInterviewDate = (staffProfile: StaffProfile) => [
  {
    name: "interviewDate",
    label: "",
    type: FieldType.Calendar,
    parentFormContainerClassName: "w-1/2 inline-block align-top !my-0 pr-6",
    className: "w-full",
    wrapperClassName: "w-full",
    containerClassName: "w-full",
    minDate: getTomorrowDate(),
    defaultValue: staffProfile.interviewDate
      ? toZonedTime(new Date(staffProfile.interviewDate), "Australia/Sydney")
      : undefined,
    customDateFormat: "EEEE, do MMMM yyyy",
    showOnlyWeekday: true,
  },
  {
    name: "interviewDateTime",
    label: "",
    type: FieldType.Calendar,
    parentFormContainerClassName:
      "w-1/2 inline-block align-top !my-0 timeOnlyPicker",
    className: "w-full",
    wrapperClassName: "w-full",
    containerClassName: "w-full",
    showTimeInput: true,
    minDate: getTomorrowDate(),
    showWorkingTime: true,
    defaultValue: staffProfile.interviewDate
      ? toZonedTime(new Date(staffProfile.interviewDate), "Australia/Sydney")
      : undefined,
  },
];

export const editBankDetails = (staffProfile: StaffProfile) => [
  {
    name: "bankName",
    label: "Bank name *",
    type: FieldType.Input,
    defaultValue: staffProfile.bankDetails?.bankName,
    parentFormContainerClassName: "w-full md:w-1/3 inline-block align-top",
    className: "!w-64",
    isDisabled: true,
  },
  {
    name: "branchAddress",
    label: "Branch address *",
    type: FieldType.Input,
    defaultValue: staffProfile.bankDetails?.branchAddress,
    parentFormContainerClassName: "w-full md:w-1/3 inline-block align-top",
    className: "!w-64",
    isDisabled: true,
  },
  {
    name: "accountName",
    label: "Account name *",
    type: FieldType.Input,
    defaultValue: staffProfile.bankDetails?.accountName,
    parentFormContainerClassName: "w-full md:w-1/3 inline-block align-top",
    className: "!w-64",
    isDisabled: true,
  },
  {
    name: "accountType",
    label: "Type of account *",
    type: FieldType.Input,
    defaultValue: staffProfile.bankDetails?.accountType,
    parentFormContainerClassName: "w-full md:w-1/3 inline-block align-top",
    className: "!w-64",
    isDisabled: true,
  },
  {
    name: "accountNumber",
    label: "Account number *",
    type: FieldType.Input,
    defaultValue: staffProfile.bankDetails?.accountNumber,
    parentFormContainerClassName: "w-full md:w-1/3 inline-block align-top",
    className: "!w-64",
    subType: "number",
    isDisabled: true,
  },
  {
    name: "branchNumber",
    label: "Branch number (BSB) *",
    type: FieldType.Input,
    defaultValue: staffProfile.bankDetails?.branchNumber,
    parentFormContainerClassName: "w-full md:w-1/3 inline-block align-top",
    className: "!w-64",
    subType: "number",
    isDisabled: true,
  },
  {
    name: "tfn",
    label:
      "After you fill the “Tax file number declaration form” upload the document below:",
    type: FieldType.FileUpload,
    defaultValue: staffProfile.bankDetails?.tfn,
    isMultipleFiles: false,
    buttonLabel: "Upload tax form",
    isDisabled: true,
  },
];
export const editVehicleDetails = (staffProfile: StaffProfile) => [
  {
    name: "ownVehicle",
    label: "Do you own a vehicle? *",
    parentFormContainerClassName: "w-1/5 inline-block align-top",
    options: [
      {
        label: "Yes",
        value: "Yes",
      },
      {
        label: "No",
        value: "No",
      },
    ],
    type: FieldType.Radio,
    isDisabled: true,
    defaultValue:
      typeof staffProfile.vehicleOwnership === "boolean"
        ? staffProfile.vehicleOwnership
          ? "Yes"
          : "No"
        : "Yes",
  },
  {
    name: "vehicleMake",
    label: "Make of vehicle *",
    type: FieldType.Input,
    defaultValue: staffProfile.vehicleDetails?.vehicleMake,
    parentFormContainerClassName: "w-1/5 inline-block align-top pr-2",
    className: "!w-full",
    containerClassName: "!m-0",
    isDisabled: true,
  },
  {
    name: "vehicleModel",
    label: "Model of vehicle *",
    type: FieldType.Input,
    defaultValue: staffProfile.vehicleDetails?.vehicleModel,
    parentFormContainerClassName: "w-1/5 inline-block align-top pr-2",
    className: "!w-full",
    containerClassName: "!m-0",
    isDisabled: true,
  },
  {
    name: "vehiclePlateNumber",
    label: "Vehicle plate number *",
    type: FieldType.Input,
    defaultValue: staffProfile.vehicleDetails?.vehiclePlateNumber,
    parentFormContainerClassName: "w-1/5 inline-block align-top pr-2",
    className: "!w-full",
    containerClassName: "!m-0",
    isDisabled: true,
  },
  {
    name: "engineSize",
    label: "Engine size *",
    type: FieldType.Input,
    defaultValue: staffProfile.vehicleDetails?.engineSize,
    parentFormContainerClassName: "w-1/5 inline-block align-top pr-2",
    containerClassName: "!m-0",
    className: "!w-full",
    isDisabled: true,
  },
  {
    name: "vehicleRegistration",
    label: "Vehicle registration *",
    type: FieldType.FileUpload,
    defaultValue: staffProfile.vehicleDetails?.vehicleRegistration,
    isMultipleFiles: false,
    buttonLabel: "Upload vehicleRegistration",
    parentFormContainerClassName: "w-1/2 inline-block align-top pr-2",
    isDisabled: true,
  },
  {
    name: "vehicleInsurance",
    label: "Vehicle insurance *",
    type: FieldType.FileUpload,
    defaultValue: staffProfile.vehicleDetails?.vehicleInsurance,
    isMultipleFiles: false,
    buttonLabel: "Upload Vehicle insurance *",
    parentFormContainerClassName: "w-1/2 inline-block align-top pr-2",
    isDisabled: true,
  },
  {
    name: "vehicleRegistrationExpiry",
    label: "Registration expiry date *",
    type: FieldType.Calendar,
    defaultValue: staffProfile.vehicleDetails?.vehicleRegistrationExpiry,
    parentFormContainerClassName: "w-1/4 inline-block align-top",
    className: "!w-full",
    minDate: getTodayDate(),
    isDisabled: true,
  },
  {
    name: "vehicleInsuranceExpiry",
    label: "Vehicle insurance expiry date *",
    type: FieldType.Calendar,
    defaultValue: staffProfile.vehicleDetails?.vehicleInsuranceExpiry,
    parentFormContainerClassName: "w-1/4 inline-block align-top",
    className: "!w-full",
    minDate: getTodayDate(),
    isDisabled: true,
  },
  {
    name: "drivingLicenseNumber",
    label: "Driving licence number *",
    type: FieldType.Input,
    defaultValue: staffProfile.vehicleDetails?.drivingLicenseNumber,
    parentFormContainerClassName: "w-1/4 inline-block align-top pr-2",
    className: "!w-full",
    isDisabled: true,
  },
  {
    name: "drivingLicenseExpiry",
    label: "Driving licence expiry date *",
    type: FieldType.Calendar,
    defaultValue: staffProfile.vehicleDetails?.drivingLicenseExpiry,
    parentFormContainerClassName: "w-1/4 inline-block align-top",
    className: "!w-full",
    minDate: getTodayDate(),
    isDisabled: true,
  },
  {
    name: "drivingLicenseFront",
    label: "Certified driving licence (Front) *",
    type: FieldType.FileUpload,
    defaultValue: staffProfile.vehicleDetails?.drivingLicenseFront,
    isMultipleFiles: false,
    buttonLabel: "Upload Certified driving licence (Front) *",
    parentFormContainerClassName: "w-1/2 inline-block align-top pr-2",
    isDisabled: true,
  },
  {
    name: "drivingLicenseBack",
    label: "Certified driving licence (Back) *",
    type: FieldType.FileUpload,
    defaultValue: staffProfile.vehicleDetails?.drivingLicenseBack,
    isMultipleFiles: false,
    buttonLabel: "Upload Certified driving licence (Back) *",
    parentFormContainerClassName: "w-1/2 inline-block align-top",
    isDisabled: true,
  },
];
export const editEmployeeHandbook = (staffProfile: StaffProfile) => [
  {
    name: "readEmployeeHandbook",
    label: "I have read the Employee handbook",
    parentFormContainerClassName: "w-full",
    type: FieldType.Checbox,
    isDisabled: true,
    defaultValue: staffProfile.readEmployeeHandbook,
  },
  {
    name: "signatureCodeOfConductDate",
    label: "Sign Code of Conduct *",
    type: FieldType.Sign,
    parentFormContainerClassName: "mt-5",
    inputClassName: "w-3/4",
    defaultValue: staffProfile.signatureCodeOfConductDate,
    defaultSignature: staffProfile.signatureCodeOfConduct,
    isDisabled: true,
    signatureName: "signatureCodeOfConduct",
  },
];

export const editStatutoryDeclaration = (staffProfile: StaffProfile) => [
  {
    name: "signedStatutoryForm",
    label:
      "After you fill the “Statutory declaration form” upload the form below:",
    type: FieldType.FileUpload,
    defaultValue: staffProfile.signedStatutoryForm,
    isMultipleFiles: false,
    buttonLabel: "Upload vehicleRegistration",
    parentFormContainerClassName: "w-full inline-block align-top pr-2",
    isDisabled: true,
  },
];
export const editContract = (
  staffProfile: StaffProfile,
  adminStaffOptions: Option[]
) => [
  {
    name: "contractSigned",
    label: "Contract",
    type: FieldType.FileUpload,
    defaultValue: staffProfile.contractSigned,
    isMultipleFiles: false,
    buttonLabel: "Upload contractSigned",
    parentFormContainerClassName: "w-full inline-block align-top pr-1",
  },
  {
    name: "employmentEndDate",
    label: "Employment end date",
    type: FieldType.Calendar,
    defaultValue: staffProfile.employmentEndDate,
    parentFormContainerClassName: "w-1/2 inline-block align-top pr-6",
    wrapperClassName: "w-full",
    className: "w-full",
  },
  {
    name: "reportingManagerId",
    label: "Reports to Manager/Supervisor",
    type: FieldType.Select,
    defaultValue: staffProfile.reportingManagerId,
    parentFormContainerClassName: "w-1/2 inline-block align-top pr-1",
    options: adminStaffOptions,
    placeholder: "Select",
    isDropdownFixed: true,
    containerClassName: "w-full",
    className: "mt-2",
  },
];
