import classNames from "classnames";
import React, { useEffect } from "react";
import { FieldError, useFormContext } from "react-hook-form";
import { get } from "utils/util";
import { FormField } from "./types";
import { renderErrorMessage } from "./utility";

interface RadioProps extends FormField {
  name: string;
  label: string;
  error?: FieldError | undefined;
  isCol?: boolean;
  labelClassName?: string;
}

const Radio: React.FC<RadioProps> = ({
  options,
  name,
  requiredCondition,
  label,
  error,
  className,
  isCol,
  defaultValue,
  isDisabled,
  isHiglighted,
  labelClassName,
}) => {
  const {
    register,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext();
  const stateValue = watch(name);
  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
    }
  }, [defaultValue, name, setValue]);
  if (!options) {
    return <></>;
  }
  return (
    <div className={classNames("mt-5", className)}>
      {label && (
        <label
          className={classNames(
            "block text-sm font-semibold leading-6 text-gray-700",
            isDisabled && "!text-gray-400"
          )}
        >
          {label}
        </label>
      )}
      <div
        className={classNames(
          "flex ",
          isCol ? "flex-col items-start justify-center my-1" : "items-center"
        )}
      >
        {options.map((option) => (
          <div
            key={`${name}-${option.value}`}
            className={classNames("flex items-center mr-4", isCol && "my-1")}
          >
            <input
              type="radio"
              id={`${name}-${option.value}`}
              value={option.value}
              {...register(name, {
                required: requiredCondition,
              })}
              defaultChecked={defaultValue === option.value}
              className={classNames(
                "mr-2 text-sm border-gray-300 text-primary-500 active:ring-primary-500 focus:ring-primary-500 disabled:text-gray-300 disabled:ring-gray-300 disabled:border-gray-300",
                isHiglighted &&
                  requiredCondition &&
                  !stateValue &&
                  "border-secondary-500",
                {
                  "!ring-red-600": get(errors, name),
                  "!focus:ring-red-600": get(errors, name),
                  "!border-red-600": get(errors, name),
                  "!border:ring-red-600": get(errors, name),
                }
              )}
              disabled={isDisabled}
            />
            <label
              htmlFor={`${name}-${option.value}`}
              className={classNames(
                "text-sm leading-5 font-medium text-gray-900",
                isDisabled && "!text-gray-300",
                labelClassName
              )}
            >
              {option.label}
            </label>
          </div>
        ))}
      </div>
      {renderErrorMessage(get(errors, name) as FieldError)}
    </div>
  );
};

export default Radio;
