import api from "api/api";
import { GenericResponse } from "api/types";
import Button from "components/Button";
import { Icon } from "components/Icon";
import Notification, { NotificationProps } from "components/Notification";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ApplicationMenu from "routes/dashboard/Menu";

export default function StartOnboarding(props: any) {
  const [response, setResponse] = useState<NotificationProps | null>(null);
  const navigate = useNavigate();

  return (
    <>
      <ApplicationMenu navigationItems={[]} />
      <div className="py-1 my-10">
        <main className="mx-auto max-w-2xl sm:px-6 lg:px-8 py-5 bg-white rounded-md shadow">
          <div className="bg-primary-50 p-5 flex flex-col justify-center items-center rounded-md">
            <img
              className="min-h-44 w-full lg:h-60 lg:w-auto"
              src="/images/ei-care-logo.png"
              alt="EICARE"
            />
          </div>
          {
            <div className="flex flex-col items-center justify-center">
              {response ? (
                <Notification
                  type={response.type}
                  message={response.message}
                  className="mt-5 w-full"
                />
              ) : (
                <Notification
                  type={"success"}
                  message={"Your account has successfully been created!"}
                  bodyText="Check your email before getting started | 您的账户已创建成功！请在开始前检查您的电子邮件。"
                  className="mt-5 w-full inline-flex"
                  textClassName="!mt-1 !text-gray-900 font-semibold"
                />
              )}
              <Button
                variant="primary"
                type="submit"
                form="create-account-staff-onboarding"
                className="w-full my-5 text-gray-900"
                onClick={async () => {
                  const response = (await api.post<GenericResponse>(
                    `/api/user/start-onboarding`,
                    {}
                  )) as GenericResponse;
                  if (
                    (response.data?.message || response.data?.error) &&
                    response.status &&
                    response.status !== 200
                  ) {
                    setResponse({
                      type: "error",
                      message: "There was a problem in creating your account!",
                    });
                    return;
                  }

                  navigate("/onboarding/staff/step/1");
                }}
              >
                <span className="text-base leading-6 font-bold text-gray-900">
                  Get Started | 开始
                </span>
                <Icon name="rightDirectionArrow" className="text-gray-900" />
              </Button>
              <span className="text-sm leading-5 font-normal text-gray-500 text-center">
                Click above button to start completing your profile
              </span>
            </div>
          }
        </main>
      </div>
    </>
  );
}
