import api from "api/api";
import { GenericResponse } from "api/types";
import { useValidateResetPasswordToken } from "api/user";
import classNames from "classnames";
import Button from "components/Button";
import ReusableForm from "components/Form";
import { Loading } from "components/Loading";
import Notification, { NotificationProps } from "components/Notification";
import { FieldType, FormData } from "components/types";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { validatePassword } from "utils/util";

const fields = [
  {
    name: "password",
    label: "New Password*",
    type: FieldType.Input,
    placeholder:
      "Min. 8 characters, 1 capital letter, 1 number, 1 special character",
    requiredCondition:
      "Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character",
    className: "w-96",
    isGeneratePassword: true,
    parentContainerClassName: "flex",
    validationFunction: validatePassword,
    validationErrorMessage:
      "Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character",
    additionalValidationProps: {
      minLength: {
        value: 8,
        message:
          "Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character",
      },
    },
  },
  {
    name: "confirmPassword",
    label: "Confirm Password",
    type: FieldType.Input,
    subType: "password",
    placeholder: "",
    className: "w-96",
    requiredCondition: "This is a required field",
  },
];

export default function ResetPassword(props: any) {
  const [response, setResponse] = useState<NotificationProps | null>(null);
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const navigate = useNavigate();
  const { data, isFetching } = useValidateResetPasswordToken(code);

  const onSubmit = async (data: FormData) => {
    if (data.confirmPassword !== data.password) {
      setError("confirmPassword", {
        type: "custom",
        message:
          "The confirm password does not match the Set password, please check and retype.",
      });
      return;
    }
    delete data.confirmPassword;
    setResponse(null);
    const response = (await api.put<GenericResponse>(
      `/api/user/reset-password/${code}`,
      data
    )) as GenericResponse;
    if (response.status && response.status !== 200) {
      setResponse({
        type: "error",
        message: response.data?.message || response.data?.error,
      });
      return;
    }
    setResponse({
      type: "success",
      message: "Your password has been successfully reset!",
    });
    setTimeout(() => navigate("/login"), 3000);
  };

  const { setError, formState, watch, trigger, ...formMethods } =
    useForm<FormData>();
  const { isValid } = formState;
  const confirmPassword = watch("confirmPassword");
  const password = watch("password");

  useEffect(() => {
    if (confirmPassword) {
      if (confirmPassword !== password) {
        setError("confirmPassword", {
          type: "custom",
          message:
            "The confirm password does not match the Set password, please check and retype.",
        });
        return;
      }
      trigger("confirmPassword");
    }
  }, [confirmPassword, password, setError, trigger]);

  if (isFetching) {
    return (
      <div className="min-h-screen w-full flex items-center justify-center">
        <Loading />
      </div>
    );
  }

  if (!data) {
    return (
      <div className={classNames("mx-auto max-w-7xl  p-3 mt-20")}>
        <Notification
          type="warning"
          message={"Link has already expired!"}
          className="ml-0"
        />
        <div className=" pb-5 mt-8">
          <h1 className="text-gray-900 text-xl font-bold">
            Reset password link has expired
          </h1>
          <p className="text-gray-900 mt-4">
            Please click on forgot password on login scree to regenerate the
            link.
          </p>
          <p className="text-gray-900 mt-4 ">
            If there is any problem in reseting the password or if you have any
            questions and or need assistance.
          </p>
          <p className="text-gray-900">
            Please contact the EICARE team, You can Email/Call us between 9am -
            5pm Mon - Fri
          </p>
          <p className="text-gray-900">
            <span>Email: </span>
            <span className="underline">support@eicare.com.au</span>
          </p>
          <p className="text-gray-900">Phone: 1300 180 888</p>
        </div>
      </div>
    );
  }

  return (
    <div className="py-1 my-10">
      <main className="mx-auto max-w-2xl sm:px-6 lg:px-8 py-5 bg-white rounded-md shadow">
        <div className="bg-primary-50 p-5 flex flex-col justify-center items-center rounded-md">
          <img
            className="min-h-44 w-full lg:h-60 lg:w-auto"
            src="/images/ei-care-logo.png"
            alt="EICARE"
          />
          <h1 className="text-3xl leading-9 font-extrabold text-gray-900 mt-6">
            Password Reset
          </h1>
          <p className="text-sm leading-5 font-normal text-primary-800 mt-1 w-3/5 text-center">
            Set new password
          </p>
        </div>
        {
          <div className="pt-4">
            {response && (
              <Notification type={response.type} message={response.message} />
            )}
            <ReusableForm
              fields={fields}
              onSubmit={onSubmit}
              id="create-account-staff-onboarding"
              formMethods={{
                ...formMethods,
                setError,
                formState,
                watch,
                trigger,
              }}
            />
            <div className="w-full flex justify-between items-center  rounded-b-md py-5">
              <Button
                variant="primary"
                type="submit"
                form="create-account-staff-onboarding"
                className="w-full pr-1"
                disabled={response?.type === "success" || !isValid}
              >
                Reset password
              </Button>
            </div>
          </div>
        }
      </main>
    </div>
  );
}
