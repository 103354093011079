import { ADMIN_ROLES, SUPER_ADMIN_ROLES, User, UserStatus } from "api/types";
import axios from "axios";
import Footer from "components/Footer";
import { Navigate, createBrowserRouter, redirect } from "react-router-dom";
import { user as userAPI } from "../api";
import ComponentLib from "./ComponentLib";
import Error from "./Error";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import NotAuthorised from "./NotAuthorised";
import ResetPassword from "./ResetPassword";
import ViewStaffDetail from "./ViewStaffDetail";
import Dashboard from "./dashboard";
import AlliedHealthServices from "./dashboard/AlliedHealthServices";
import Clients from "./dashboard/Clients";
import Contractors from "./dashboard/Contractors";
import ExpiringDocuments from "./dashboard/ExpiringDocuments";
import Help from "./dashboard/Help";
import Home from "./dashboard/Home";
import Staff from "./dashboard/staff";
import AllStaff from "./dashboard/staff/AllStaff";
import { StaffOrientation } from "./dashboard/staff/StaffOrientation";
import DeclineInvitation from "./onboarding/DeclineInvitation";
import Onboarding from "./onboarding/Onboarding";
import StartOnboarding from "./onboarding/StartOnboarding";
import StaffOnBoarding from "./onboarding/staff";
import OnBoardingCompletion from "./onboarding/staff/OnBoardingCompletion";
import OnBoardingStaff from "./onboarding/staff/OnBoardingStaff";
import PersonalCareWorkerManagerForm from "./onboarding/staff/PersonalCareWorkerManagerForm";
import ReviewDetail from "./onboarding/staff/reviewDetail";
import Reports from "./reports";
import EmergencyContactDetails from "./reports/EmergencyContactDetails";
import EmploymentChecksRegister from "./reports/EmploymentChecksRegister";
import PoliceCheckReport from "./reports/PoliceCheckReport";

const setupInterceptors = (redirect: any) => {
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        // window.localStorage.removeItem("eicare_auth");
        redirect("/login");
      }
      return Promise.reject(error);
    }
  );
};
export async function dashboardLoader({ params, request }: any) {
  const authData = window.localStorage.getItem("eicare_auth");
  setupInterceptors(redirect);
  const url = new URL(request.url);

  if (authData) {
    const auth = JSON.parse(authData);
    if (auth.session) {
      try {
        const [user, staffDetails] = await Promise.all([
          userAPI.getUser(),
          userAPI.getStaffDetails(),
        ]);
        if (user.status !== UserStatus.Completed) {
          const { staffProfile } = user;
          if (
            user.status === UserStatus.In_Progress &&
            staffProfile.onboardingStatus === 11
          ) {
            return redirect(`/onboarding/staff/reviewDetails`);
          }
          if (
            user.status === UserStatus.In_Review &&
            user.staffProfile.onboardingStatus === 11
          ) {
            return redirect("/onboarding/staff/onBoardingCompletion");
          }
          return redirect(
            `/onboarding/staff/step/${staffProfile.onboardingStatus}`
          );
        }
        return {
          user: {
            ...user,
            staffProfile: {
              ...user.staffProfile,
              ...staffDetails,
            },
          },
        };
      } catch (error) {
        console.log("error", error);
        return redirect(
          `/login?redirectTo=${encodeURIComponent(url.pathname + url.search)}`
        );
      }
    }
  }
  return redirect(
    `/login?redirectTo=${encodeURIComponent(url.pathname + url.search)}`
  );
}
export async function reportLoader({ params, request }: any) {
  const authData = window.localStorage.getItem("eicare_auth");
  setupInterceptors(redirect);
  const url = new URL(request.url);

  if (authData) {
    const auth = JSON.parse(authData);
    if (auth.session) {
      try {
        const user: User = await userAPI.getUser();
        const isAdmin =
          user.roles?.filter((role) => ADMIN_ROLES.indexOf(role) > -1).length >
          0;

        if (!isAdmin) {
          return redirect(
            `/login?redirectTo=${encodeURIComponent(url.pathname + url.search)}`
          );
        }
        return { user };
      } catch (error) {
        console.log("error", error);
        return redirect(
          `/login?redirectTo=${encodeURIComponent(url.pathname + url.search)}`
        );
      }
    }
  }
  return redirect(
    `/login?redirectTo=${encodeURIComponent(url.pathname + url.search)}`
  );
}

export async function staffOnboardingLoader({ params, request }: any) {
  const authData = window.localStorage.getItem("eicare_auth");
  setupInterceptors(redirect);
  const auth = JSON.parse(authData || "{}");
  if (!(auth && auth.session)) {
    const url = new URL(request.url);
    return redirect(
      `/login?redirectTo=${encodeURIComponent(url.pathname + url.search)}`
    );
  }
  const [user, staffDetails] = await Promise.all([
    userAPI.getUser(),
    userAPI.getStaffDetails(),
  ]);
  if (user.status === UserStatus.Completed) {
    return redirect("/dashboard/staff");
  }
  if (user.staffProfile.onboardingStatus < 1) {
    return redirect(`/onboarding/staff/start-onboarding`);
  }
  if (
    user.status === UserStatus.In_Review &&
    user.staffProfile.onboardingStatus === 11
  ) {
    return redirect("/onboarding/staff/onBoardingCompletion");
  }
  return {
    user: {
      ...user,
      staffProfile: {
        ...user.staffProfile,
        ...staffDetails,
      },
    },
  };
}

export async function reviewOnboardingLoader({ params, request }: any) {
  const authData = window.localStorage.getItem("eicare_auth");
  setupInterceptors(redirect);
  const auth = JSON.parse(authData || "{}");
  if (!(auth && auth.session)) {
    const url = new URL(request.url);
    return redirect(
      `/login?redirectTo=${encodeURIComponent(url.pathname + url.search)}`
    );
  }
  const [user, staffDetails] = await Promise.all([
    userAPI.getUser(),
    userAPI.getStaffDetails(),
  ]);
  if (user.status === UserStatus.Completed) {
    return redirect("/dashboard/staff");
  }
  if (user.staffProfile.onboardingStatus < 1) {
    return redirect(`/onboarding/staff/start-onboarding`);
  }
  if (
    user.status === UserStatus.In_Review &&
    user.staffProfile.onboardingStatus > 10
  ) {
    return redirect("/onboarding/staff/onBoardingCompletion");
  }
  if (user.staffProfile.onboardingStatus < 11) {
    return redirect(
      `/onboarding/staff/step/${user.staffProfile.onboardingStatus}`
    );
  }
  return {
    user: {
      ...user,
      staffProfile: {
        ...user.staffProfile,
        ...staffDetails,
      },
    },
  };
}
export async function OnBoardingCompletionLoader({ params, request }: any) {
  const authData = window.localStorage.getItem("eicare_auth");
  setupInterceptors(redirect);
  const auth = JSON.parse(authData || "{}");
  if (!(auth && auth.session)) {
    const url = new URL(request.url);
    return redirect(
      `/login?redirectTo=${encodeURIComponent(url.pathname + url.search)}`
    );
  }
  const [user, staffDetails] = await Promise.all([
    userAPI.getUser(),
    userAPI.getStaffDetails(),
  ]);
  if (user.status === UserStatus.Completed) {
    return redirect("/dashboard/staff");
  }
  if (user.staffProfile.onboardingStatus < 1) {
    return redirect(`/onboarding/staff/start-onboarding`);
  }
  if (user.staffProfile.onboardingStatus < 11) {
    return redirect(
      `/onboarding/staff/step/${user.staffProfile.onboardingStatus}`
    );
  }
  return {
    user: {
      ...user,
      staffProfile: {
        ...user.staffProfile,
        ...staffDetails,
      },
    },
  };
}
export async function StartOnBoardingCompletionLoader({
  params,
  request,
}: any) {
  const authData = window.localStorage.getItem("eicare_auth");
  setupInterceptors(redirect);
  const auth = JSON.parse(authData || "{}");
  if (!(auth && auth.session)) {
    const url = new URL(request.url);
    return redirect(
      `/login?redirectTo=${encodeURIComponent(url.pathname + url.search)}`
    );
  }
  const [user, staffDetails] = await Promise.all([
    userAPI.getUser(),
    userAPI.getStaffDetails(),
  ]);
  if (user.status === UserStatus.Completed) {
    return redirect("/dashboard/staff");
  }
  if (user.staffProfile.onboardingStatus > 0) {
    return redirect(
      `/onboarding/staff/step/${user.staffProfile.onboardingStatus}`
    );
  }
  return {
    user: {
      ...user,
      staffProfile: {
        ...user.staffProfile,
        ...staffDetails,
      },
    },
  };
}

export async function viewStaffLoader({ params, request }: any) {
  const { staffId } = params;
  const url = new URL(request.url);
  if (!staffId) {
    return redirect(
      `/login?redirectTo=${encodeURIComponent(url.pathname + url.search)}`
    );
  }
  const authData = window.localStorage.getItem("eicare_auth");
  setupInterceptors(redirect);
  const auth = JSON.parse(authData || "{}");
  if (!(auth && auth.session)) {
    return redirect(
      `/login?redirectTo=${encodeURIComponent(url.pathname + url.search)}`
    );
  }

  const [adminDetail, staffDetail] = await Promise.all([
    userAPI.getUser(),
    userAPI.getUserDetail(staffId),
  ]);

  const isAdmin =
    adminDetail.roles?.filter((role) => ADMIN_ROLES.indexOf(role) > -1).length >
    0;
  const isSuperAdmin =
    adminDetail.roles?.filter((role) => SUPER_ADMIN_ROLES.indexOf(role) > -1)
      .length > 0;

  if (!isAdmin) {
    return redirect(
      `/login?redirectTo=${encodeURIComponent(url.pathname + url.search)}`
    );
  }

  return {
    user: { ...staffDetail },
    admin: { ...adminDetail },
    isSuperAdmin: isSuperAdmin,
  };
}
export async function personalCareManagerFormLoader({ params, request }: any) {
  const { staffId } = params;
  const authData = window.localStorage.getItem("eicare_auth");
  setupInterceptors(redirect);
  const url = new URL(request.url);

  if (authData) {
    const auth = JSON.parse(authData);
    if (auth.session) {
      const [adminDetail, staffDetail] = await Promise.all([
        userAPI.getUser(),
        userAPI.getUserDetail(staffId),
      ]);

      const isAdmin =
        adminDetail.roles?.filter((role) => ADMIN_ROLES.indexOf(role) > -1)
          .length > 0;

      if (!isAdmin) {
        return redirect(
          `/login?redirectTo=${encodeURIComponent(url.pathname + url.search)}`
        );
      }

      if (
        adminDetail.id !== staffDetail.staffProfile.contractReportsToManagerId
      ) {
        return redirect(`/not-authorised`);
      }

      return {
        user: { ...staffDetail },
        admin: { ...adminDetail },
      };
    }
  }
  return redirect(
    `/login?redirectTo=${encodeURIComponent(url.pathname + url.search)}`
  );
}

const WithOptionalFooter: React.FC<{
  showFooter?: boolean;
  children: React.ReactNode;
}> = ({ showFooter = true, children }) => (
  <>
    <main className="flex-1">{children}</main>
    {showFooter && <Footer />}
  </>
);

export const router = createBrowserRouter([
  {
    path: "/login",
    element: (
      <WithOptionalFooter showFooter={false}>
        <Login />
      </WithOptionalFooter>
    ),
    errorElement: <Error />,
  },
  {
    path: "forgot-password",
    element: (
      <WithOptionalFooter showFooter={false}>
        <ForgotPassword />
      </WithOptionalFooter>
    ),
    errorElement: <Error />,
  },
  {
    path: "/dashboard",
    element: (
      <WithOptionalFooter>
        <Dashboard />
      </WithOptionalFooter>
    ),
    errorElement: <Error />,
    loader: dashboardLoader,
    children: [
      {
        path: "",
        element: <Home />,
        errorElement: <Error />,
      },
      {
        path: "clients",
        element: <Clients />,
        errorElement: <Error />,
      },
      {
        path: "allied-health-services",
        element: <AlliedHealthServices />,
        errorElement: <Error />,
      },
      {
        path: "contractors",
        element: <Contractors />,
        errorElement: <Error />,
      },
      {
        path: "help",
        element: <Help />,
        errorElement: <Error />,
      },
      {
        path: "expiringDocuments",
        element: <ExpiringDocuments />,
        errorElement: <Error />,
      },
      {
        path: "staff",
        element: <Staff />,
        errorElement: <Error />,
        children: [
          {
            path: "",
            element: <AllStaff />,
            errorElement: <Error />,
          },
          {
            path: "all-staff",
            element: <AllStaff />,
            errorElement: <Error />,
          },
          {
            path: "staff-orientation",
            element: <StaffOrientation />,
            errorElement: <Error />,
          },
        ],
      },
    ],
  },
  {
    path: "onboarding/staff/step/:step",
    element: (
      <WithOptionalFooter>
        <OnBoardingStaff />
      </WithOptionalFooter>
    ),
    loader: staffOnboardingLoader,
    errorElement: <Error />,
  },
  {
    path: "onboarding/staff/reviewDetails",
    element: (
      <WithOptionalFooter>
        <ReviewDetail />
      </WithOptionalFooter>
    ),
    loader: reviewOnboardingLoader,
    errorElement: <Error />,
  },
  {
    path: "onboarding/staff/start-onboarding",
    element: (
      <WithOptionalFooter>
        <StartOnboarding />
      </WithOptionalFooter>
    ),
    loader: StartOnBoardingCompletionLoader,
    errorElement: <Error />,
  },
  {
    path: "onboarding/staff/onBoardingCompletion",
    element: (
      <WithOptionalFooter showFooter={false}>
        <OnBoardingCompletion />
      </WithOptionalFooter>
    ),
    loader: OnBoardingCompletionLoader,
    errorElement: <Error />,
  },
  {
    path: "/staff/view-profile/:staffId/personal-care-agreement",
    element: (
      <WithOptionalFooter>
        <PersonalCareWorkerManagerForm />
      </WithOptionalFooter>
    ),
    loader: personalCareManagerFormLoader,
    errorElement: <Error />,
  },
  {
    path: "staff/view-profile/:staffId",
    element: (
      <WithOptionalFooter>
        <ViewStaffDetail />
      </WithOptionalFooter>
    ),
    loader: viewStaffLoader,
    errorElement: <Error />,
  },
  {
    path: "not-authorised",
    element: (
      <WithOptionalFooter>
        <NotAuthorised />
      </WithOptionalFooter>
    ),
    errorElement: <Error />,
  },
  {
    path: "reports",
    element: (
      <WithOptionalFooter>
        <Reports />
      </WithOptionalFooter>
    ),
    loader: reportLoader,
    errorElement: <Error />,
    children: [
      {
        path: "employment-checks-register",
        element: <EmploymentChecksRegister />,
        errorElement: <Error />,
      },
      {
        path: "police-check-report",
        element: <PoliceCheckReport />,
        errorElement: <Error />,
      },
      {
        path: "emergency-contacts-details",
        element: <EmergencyContactDetails />,
        errorElement: <Error />,
      },
    ],
  },
  {
    path: "onboarding",
    element: (
      <WithOptionalFooter>
        <Onboarding />
      </WithOptionalFooter>
    ),
    errorElement: <Error />,
    children: [
      {
        path: "staff",
        element: <StaffOnBoarding />,
      },
      {
        path: "staff/decline",
        element: <DeclineInvitation />,
      },
    ],
  },
  {
    path: "components",
    element: <ComponentLib />,
    errorElement: <Error />,
  },
  {
    path: "reset-password",
    element: (
      <WithOptionalFooter>
        <ResetPassword />
      </WithOptionalFooter>
    ),
    errorElement: <Error />,
  },
  {
    path: "/",
    element: <Navigate to="/dashboard" replace />,
    errorElement: <Error />,
  },
]);
