// src/components/Tabs/Tab.tsx
import classNames from "classnames";
import React from "react";

export interface TabProps {
  label: string;
  icon?: React.ReactNode;
  content: React.ReactNode;
  isActive?: boolean;
  onClick?: () => void;
  iconClassName?: string;
  tabClassName?: string;
}

export const Tab: React.FC<TabProps> = ({
  label,
  icon,
  isActive,
  onClick,
  iconClassName,
  tabClassName,
}) => {
  return (
    <button
      className={classNames(
        `flex items-center p-4  shadow ${
          isActive
            ? "border-b-2 border-primary-400 text-gray-900 hover:bg-primary-50"
            : "border-transparent text-gray-500 hover:bg-gray-100 hover:text-gray-900"
        }  bg-white focus:text-gray-900 focus:font-semibold`,
        tabClassName
      )}
      onClick={onClick}
    >
      {icon && (
        <span className={classNames("mr-2", iconClassName)}>{icon}</span>
      )}
      <span className="text-sm leading-5 font-semibold">{label}</span>
    </button>
  );
};
