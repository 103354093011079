import {
  useRouteError,
  isRouteErrorResponse,
  useNavigate,
} from "react-router-dom";
import { useEffect } from "react";

export default function Error() {
  const navigate = useNavigate();
  const error: any = useRouteError();
  let errorMessage: string;
  console.log(error);
  if (isRouteErrorResponse(error)) {
    errorMessage = error.statusText;
  } else if (error instanceof Error) {
    errorMessage = (error as Error).message;
  } else if (typeof error === "string") {
    errorMessage = error;
  } else {
    errorMessage = "Unknown error";
  }
  useEffect(() => {
    if (error.status === 404) {
      navigate("/dashboard");
    }
  }, [error, navigate]);
  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{errorMessage}</i>
      </p>
    </div>
  );
}
