import {
  OrientationCheckListHistory,
  SUPER_ADMIN_ROLES,
  TransformedOrientationData,
  User,
} from "api/types";
import { updateOrientationList } from "api/user";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import {
  getChangedBooleanObjectFields,
  mergeObjects,
  removeFalseValues,
} from "utils/util";
import { getOrientationCheckListHistoryItems } from "utils/viewStaffDetailUtility";
import Button from "./Button";
import Checkbox from "./Checkbox";
import Modal from "./Modal";
import Table from "./Table";
import { FieldType, FormData } from "./types";
import { handleGenericResponse, invalidateQueries } from "./utility";

interface OrientationChecklistProps {
  user: User;
  admin: User;
  orientationData: TransformedOrientationData;
}

export const OrientationChecklist: React.FC<OrientationChecklistProps> = ({
  user,
  admin,
  orientationData,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { watch, setValue, ...formMethods } = useForm<FormData>();
  const methods = {
    ...formMethods,
    watch,
    setValue,
  };
  const isSuperAdmin =
    admin.roles?.filter((role) => SUPER_ADMIN_ROLES.indexOf(role) > -1).length >
    0;
  const handleCloseModal = () => {
    setIsOpen(false);
  };
  const preSelectedCheckedItem = { ...user.staffProfile.orientationItems };
  const orientationChecklist = watch(
    "orientationChecklist",
    preSelectedCheckedItem
  );
  const editOrientationChecklist = watch(
    "editOrientationChecklist",
    preSelectedCheckedItem
  );
  const queryClient = useQueryClient();

  const isAnythingSelected = Object.keys(preSelectedCheckedItem || {}).some(
    (val) => preSelectedCheckedItem[val]
  );

  const isAnythingSelectedForSave = Object.keys(
    orientationChecklist || {}
  ).some((val) => orientationChecklist[val]);

  const setChecklistItems = async (isEditType = false) => {
    const list = isEditType ? editOrientationChecklist : orientationChecklist;
    const data = mergeObjects(
      Object.keys(preSelectedCheckedItem).map((item) => ({
        [item]: Boolean(preSelectedCheckedItem[item]?.value),
      }))
    );
    let values = getChangedBooleanObjectFields(data, list, isEditType);
    if (!isEditType) {
      values = removeFalseValues(values);
    }

    const response = await updateOrientationList(
      {
        values: Object.keys(values).map((item) => ({
          name: item,
          checked: values[item],
        })),
      },
      user.id
    );
    const isSuccess = await handleGenericResponse(response);
    if (isSuccess) {
      invalidateQueries(queryClient, [
        `/api/user/admin/profile/${user.id}`,
        `/api/user/admin/staff`,
        `/api/orientation/admin/orientation-data`,
      ]);
      if (isEditType) {
        setValue("orientationChecklist", list);
      }
      setIsOpen(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="mb-11">
        <h3 className="text-xl leading-7 font-semibold text-gray-900 mt-8 mb-10">
          Orientation
        </h3>
        <div className="bg-white rounded-md shadow">
          <h4 className="text-lg leading-6 font-semibold text-gray-900 px-6 pt-6">
            Orientation/Induction checklist
          </h4>
          <div className="flex items-center flex-wrap p-6">
            {orientationData &&
              (orientationData?.occupations_orientation || []).map(
                (item, index) => (
                  <div className="w-1/2" key={item.name}>
                    <Checkbox
                      type={FieldType.Checbox}
                      label={item.description}
                      name={`orientationChecklist.${item.name}`}
                      className="mt-1"
                      key={item.name}
                      checked={orientationChecklist[item.name]?.value}
                      defaultValue={preSelectedCheckedItem[item.name]?.value}
                      isDisabled={preSelectedCheckedItem[item.name]?.value}
                    />
                  </div>
                )
              )}
          </div>
          <div className="flex justify-end items-center bg-gray-50 border-t border-gray-200 rounded-b-md px-6 py-3  space-x-3">
            <Button
              variant="white"
              onClick={() => {
                setIsOpen(true);
              }}
              disabled={!isAnythingSelected}
            >
              Edit
            </Button>
            <Button
              variant="primary"
              onClick={async () => {
                setChecklistItems();
              }}
              disabled={!isAnythingSelectedForSave}
            >
              Save
            </Button>
          </div>
        </div>
        <div className="bg-white rounded-md shadow ">
          <h3 className="text-lg leading-6 font-semibold text-gray-900 mt-8 p-6">
            Checklist history
          </h3>
          <div>
            {user &&
              user.staffProfile.orientationCheckListHistory?.length > 0 &&
              orientationData && (
                <div className="px-6 pb-6 ">
                  <div className="mx-auto bg-gray-100 p-4 rounded-lg">
                    <Table<OrientationCheckListHistory>
                      data={user.staffProfile.orientationCheckListHistory}
                      columns={getOrientationCheckListHistoryItems(
                        orientationData
                      )}
                      containerClassName="max-h-104 overflow-auto"
                    />
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onClose={handleCloseModal}
        modalHeader="Edit checklist"
        customModalStyles={{ width: "740px" }}
      >
        <div className="px-6">
          <div className="flex items-center flex-wrap pb-6">
            {orientationData &&
              (orientationData?.occupations_orientation || []).map(
                (item, index) => (
                  <div className="w-1/2" key={item.name}>
                    <Checkbox
                      type={FieldType.Checbox}
                      label={item.description}
                      name={`editOrientationChecklist.${item.name}`}
                      className="mt-1"
                      key={item.name}
                      defaultValue={preSelectedCheckedItem?.[item.name]?.value}
                      isDisabled={
                        preSelectedCheckedItem[item.name]?.value &&
                        preSelectedCheckedItem[item.name]?.admin_id !==
                          admin.id &&
                        !isSuperAdmin
                      }
                    />
                  </div>
                )
              )}
          </div>
        </div>
        <div className="bg-gray-50 flex justify-end items-center  rounded-b-md px-5 py-3 border-t border-gray-200 w-full mt-3 flex-wrap md:flex-nowrap space-x-4">
          <Button variant="white" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => setChecklistItems(true)}>
            Save
          </Button>
        </div>
      </Modal>
    </FormProvider>
  );
};
