import { GenericResponse, User } from "api/types";
import {
  addInterviewAnswers,
  addInterviewComments,
  deleteInterviewComment,
  updateInterviewComments,
} from "api/user";
import cn from "classnames";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import Badge from "./Badge";
import Button from "./Button";
import Comments, { Comment } from "./Comments";
import { Icon } from "./Icon";
import { InterviewAnswers } from "./InterviewDetail";
import Modal from "./Modal";
import Radio from "./RadioBox";
import { FieldType, FormData } from "./types";
import { handleGenericResponse, invalidateQueries } from "./utility";

export interface InterviewQuestion {
  id: number;
  question: string;
  archivedAt: string;
  createdAt: string;
  updatedAt: string;
}

interface InterviewQuestionsProps {
  questions: InterviewQuestion[];
  className?: string;
  interviewComments: Comment[] | undefined;
  interviewAnswers: InterviewAnswers[] | undefined;
  user: User;
  admin?: User;
}
interface InterviewQuestionAccordionProps {
  question: InterviewQuestion;
  isOpen: boolean;
  onToggle: () => void;
  interviewComments: Comment[] | undefined;
  interviewAnswers: InterviewAnswers[] | undefined;
  user: User;
  admin?: User;
  questionNo: number;
}

const InterviewQuestionAccordion: React.FC<InterviewQuestionAccordionProps> = ({
  question,
  isOpen,
  onToggle,
  interviewComments,
  user,
  admin,
  interviewAnswers,
  questionNo,
}) => {
  const [showScoreSelectModal, setShowScoreSelectModal] = useState(false);
  const [showConfirmScoreSelectModal, setShowConfirmScoreSelectModal] =
    useState(false);
  const { getValues, ...formMethods } = useForm<FormData>();
  const methods = {
    ...formMethods,
    getValues,
  };
  const queryClient = useQueryClient();
  const interviewAnswer = interviewAnswers?.find(
    (answer) => answer.staffInterviewQuestionId === question.id
  );
  const noOfComments =
    interviewComments?.filter(
      (comments) => comments.staffInterviewQuestionId === question.id
    )?.length || 0;
  return (
    <div className="border-b border-gray-100">
      <FormProvider {...methods}>
        <div className="w-full text-left py-3 focus:outline-none">
          <div className="flex justify-between items-center">
            <div
              className="flex justify-start items-center space-x-1 cursor-pointer"
              onClick={onToggle}
            >
              <div className="mr-1">
                <Button
                  variant="text"
                  size="noSize"
                  className="hover:rounded-full focus:rounded-full p-2"
                >
                  {isOpen ? (
                    <Icon
                      name="downChevron"
                      className="rotate-180 text-primary-800"
                    />
                  ) : (
                    <Icon name="downChevron" className="text-primary-800" />
                  )}
                </Button>
              </div>

              <Badge
                message={questionNo.toString()}
                className="bg-primary-50  h-8 w-8 "
                statusClassName="!text-sm text-primary-900 font-semibold"
              />
              <span className="text-base leading-6 font-medium text-gray-900">
                {question.question}
              </span>
            </div>
            <div className="flex space-x-5 items-center">
              <div className="relative">
                <Button
                  className="w-8 h-8 border !rounded-full flex justify-center items-center cursor-pointer border-gray-300 !p-0 focus:!outline-offset-0"
                  onClick={onToggle}
                  variant="white"
                >
                  <Icon name="comments" />
                </Button>
                {noOfComments > 0 && (
                  <Badge
                    message={noOfComments.toString()}
                    className="bg-primary-100 h-6 w-6 absolute -top-3 -right-2 border-2 border-white"
                    statusClassName="text-primary-900 font-semibold !text-sm leading-5 "
                  />
                )}
              </div>
              {interviewAnswer ? (
                <div className="flex space-x-2 items-center w-22 justify-between">
                  <div className="flex-1 flex justify-center space-x-1">
                    <span className="text-xs leading-4 font-semibold uppercase text-primary-600">
                      score:
                    </span>
                    <span className="text-xs leading-4 font-semibold uppercase text-primary-600">
                      {interviewAnswer.score}
                    </span>
                  </div>
                  <Button
                    variant="gray"
                    className="cursor-pointer w-5 h-5 !p-0 !rounded-full"
                    onClick={() => setShowConfirmScoreSelectModal(true)}
                  >
                    <Icon name="pencil" className="text-gray-400" />
                  </Button>
                </div>
              ) : (
                <Button
                  className="w-22"
                  size="xs"
                  onClick={() => setShowScoreSelectModal(true)}
                >
                  Select score
                </Button>
              )}
            </div>
            <Modal
              isOpen={showScoreSelectModal}
              onClose={() => setShowScoreSelectModal(false)}
              modalHeader=""
              className="!bg-white"
              modalBodyClassName="!mt-2 pt-2"
              customModalStyles={{ width: "530px", minWidth: "530px" }}
            >
              <div className="pb-6 px-6">
                <h3 className="text-primary-900 font-bold flex justify-start items-center text-gray-900">
                  <span className="text-gray-900 text-lg leading-6 font-semibold">
                    {question.question}
                  </span>
                  <Button
                    className="absolute right-2 top-2 !rounded-full p-2"
                    size="noSize"
                    aria-label="Close"
                    onClick={() => setShowScoreSelectModal(false)}
                    variant="gray"
                  >
                    <Icon name="close" />
                  </Button>
                </h3>
                <div className="mt-6">
                  <Radio
                    name="score"
                    label=""
                    type={FieldType.Radio}
                    defaultValue={interviewAnswer?.score?.toString()}
                    options={[
                      { label: "1 (Not suitable)", value: "1" },
                      {
                        label: "2 (Average)",
                        value: "2",
                      },
                      { label: "3 (Excellent)", value: "3" },
                    ]}
                    isCol
                    labelClassName="font-medium"
                  />
                </div>
              </div>

              <div className="flex justify-end items-center bg-gray-50 border-t border-gray-200 shadow rounded-b-md px-4 py-3  rounded-b-md space-x-3">
                <Button
                  variant="white"
                  onClick={() => setShowScoreSelectModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={async () => {
                    const response = await addInterviewAnswers(
                      { questionId: question.id, score: getValues("score") },
                      user.id,
                      interviewAnswer?.score ? "put" : "post"
                    );
                    handleGenericResponse(
                      response as unknown as GenericResponse
                    );
                    invalidateQueries(queryClient, [
                      `/api/user/admin/interview-answers/${user.id}`,
                    ]);
                    setShowScoreSelectModal(false);
                  }}
                >
                  Save
                </Button>
              </div>
            </Modal>
            <Modal
              isOpen={showConfirmScoreSelectModal}
              onClose={() => setShowConfirmScoreSelectModal(false)}
              modalHeader=""
              className="!bg-yellow-50"
              modalBodyClassName="!mt-0 pb-2 px-4"
            >
              <h3 className="text-yellow-900 font-bold flex justify-start items-center">
                <Icon name="warning" />
                <span className="ml-3">
                  Please confirm you want to change the score!
                </span>
              </h3>
              <div className="flex justify-start items-center  rounded-b-md  rounded-b-md px-4 pt-2">
                <Button
                  variant="yellowTonal"
                  onClick={() => setShowConfirmScoreSelectModal(false)}
                  className="mr-3"
                >
                  Cancel
                </Button>
                <Button
                  variant="yellowTonal"
                  onClick={() => {
                    setShowConfirmScoreSelectModal(false);
                    setShowScoreSelectModal(true);
                  }}
                >
                  Confirm
                </Button>
              </div>
            </Modal>
          </div>
        </div>
        {isOpen && admin && (
          <div className="px-4 py-2 text-gray-700">
            <Comments
              admin={admin}
              data={interviewComments?.filter(
                (comments) => comments.staffInterviewQuestionId === question.id
              )}
              queryInvalidateFn={() => {
                invalidateQueries(queryClient, [
                  `/api/user/admin/interview-comments/${user.id}`,
                ]);
              }}
              addComments={async (newComment: string) =>
                await addInterviewComments(
                  { comment: newComment, questionId: question.id },
                  user.id
                )
              }
              updateComments={async (content: string, commentId: number) =>
                await updateInterviewComments({ comment: content }, commentId)
              }
              deleteComments={async (id: number) =>
                await deleteInterviewComment(id)
              }
            />
          </div>
        )}
      </FormProvider>
    </div>
  );
};

export const InterviewQuestions: React.FC<InterviewQuestionsProps> = ({
  questions,
  className,
  interviewComments,
  user,
  admin,
  interviewAnswers,
}) => {
  const [openId, setOpenId] = useState<number[]>([]);

  const handleToggle = (id: number) => {
    const index = openId.indexOf(id);
    if (index > -1) {
      openId.splice(index, 1);
    } else {
      openId.push(id);
    }
    setOpenId([...openId]);
  };
  return (
    <div className={cn("bg-white shadow px-3 pt-3 rounded-t-md", className)}>
      {questions.map((item, index) => (
        <InterviewQuestionAccordion
          key={item.id}
          question={item}
          isOpen={openId.indexOf(item.id) > -1}
          onToggle={() => handleToggle(item.id)}
          interviewComments={interviewComments}
          interviewAnswers={interviewAnswers}
          user={user}
          admin={admin}
          questionNo={index + 1}
        />
      ))}
    </div>
  );
};
