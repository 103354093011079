// components/EmailInput.tsx
import classNames from "classnames";
import React, { InputHTMLAttributes, useState } from "react";
import { FieldError, useFormContext } from "react-hook-form";
import { topEmailDomains } from "utils/emailDomain";
import { get } from "utils/util";
import Dialogue from "./Dialogue";
import { Icon } from "./Icon";
import Input from "./UnControlledInput";
import { FieldType, FormField } from "./types";
import { renderErrorMessage } from "./utility";

interface EmailInputProps
  extends FormField,
    Omit<
      InputHTMLAttributes<HTMLInputElement>,
      "type" | "name" | "defaultValue"
    > {
  onEmailChange?: (email: string) => void;
  containerClassName?: string;
  placeholder?: string;
}

const EmailInput: React.FC<EmailInputProps> = ({
  onEmailChange,
  name,
  label,
  containerClassName,
  placeholder,
  defaultValue,
  isDisabled,
  className,
  requiredCondition,
  isHiglighted,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    clearErrors,
    setError,
    register,
    trigger,
    setValue,
    getValues,
    formState: { errors },
    watch,
  } = useFormContext();
  const stateValue = watch(name);

  function validateEmail(email: string): boolean {
    // Regular expression pattern for email validation
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,3}$/i;
    if (!emailPattern.test(email)) {
      return false;
    }
    const domain = email.split("@")[1];
    return topEmailDomains.includes(domain);
  }

  const handleChange = async (email: string) => {
    if (!validateEmail(email)) {
      setIsModalOpen(true);
      setError(name, {
        type: "manual",
        message: "Email entered is invalid",
      });
    } else {
      clearErrors(name);
      await trigger(name);
    }
    onEmailChange && onEmailChange(email);
  };

  const handleCloseModal = async () => {
    setIsModalOpen(false);
    setError(name, {
      type: "manual",
      message: "Email entered is invalid",
    });
  };

  const confirm = async () => {
    setIsModalOpen(false);
    clearErrors(name);
    setValue(name, getValues("emailCheckForValidation"));
    await trigger(name);
  };

  return (
    <div className={classNames("mt-5", containerClassName)}>
      {label && (
        <label
          className={classNames(
            "block text-sm font-semibold leading-6 text-gray-700",
            isDisabled && "!text-gray-400"
          )}
        >
          {label}
        </label>
      )}
      <input
        id={name}
        placeholder={placeholder}
        {...register(name, {
          required: requiredCondition,
        })}
        onBlur={(eve) => {
          const emailValue = eve.target.value;
          if (!emailValue) {
            return;
          }
          handleChange(emailValue);
        }}
        autoComplete="off"
        className={classNames(
          "block w-full h-10 text-gray-900 rounded-md border-0 p-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 sm:text-sm sm:leading-6",
          "focus:ring-2 focus:ring-inset focus:ring-primary-400 focus:shadow ",
          {
            "!ring-red-600": get(errors, name),
            "!focus:ring-red-600": get(errors, name),
          },
          "disabled:text-gray-400 disabled:border-gray-300 disabled:placeholder:text-gray-400",
          isHiglighted &&
            requiredCondition &&
            !stateValue &&
            "ring-secondary-500",
          className
        )}
        defaultValue={defaultValue as string}
        disabled={isDisabled}
      />
      <Dialogue
        key={"emailIn"}
        title="Please check and confirm the email address is correct"
        confirmButtonLabel="Confirm"
        cancelButtonLabel="Cancel"
        children={
          <Input
            label="Email"
            type={FieldType.Input}
            name="emailCheckForValidation"
            defaultValue={getValues(name) as string}
          />
        }
        open={isModalOpen}
        handleClose={() => handleCloseModal()}
        handleConfirm={() => confirm()}
        iconElement={
          <div className="mx-auto flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-16 sm:w-16 my-4">
            <Icon name="solidCheck" />
          </div>
        }
        cancelIconName="solidCross"
        confirmIconName="coloredCheck"
      />
      {renderErrorMessage(get(errors, name) as FieldError)}
    </div>
  );
};

export default EmailInput;
