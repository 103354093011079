import api from "api/api";
import { GenericResponse } from "api/types";
import Button from "components/Button";
import ReusableForm from "components/Form";
import Notification, { NotificationProps } from "components/Notification";
import { FieldType, FormData } from "components/types";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { validatePassword } from "utils/util";

const fields = [
  {
    name: "username",
    label: "Username",
    type: FieldType.Input,
    placeholder: "",
    subType: "text",
    requiredCondition: "This is a required field",
    className: "w-96",
  },
  {
    name: "password",
    label: "Set Password",
    type: FieldType.Input,
    placeholder:
      "Min. 8 characters, 1 capital letter, 1 number, 1 special character",
    requiredCondition:
      "Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character",
    className: "w-96",
    isGeneratePassword: true,
    parentContainerClassName: "flex",
    validationFunction: validatePassword,
    validationErrorMessage:
      "Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character",
    additionalValidationProps: {
      minLength: {
        value: 8,
        message:
          "Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character",
      },
    },
  },
  {
    name: "confirmPassword",
    label: "Confirm Password",
    type: FieldType.Input,
    subType: "password",
    placeholder: "",
    className: "w-96",
    requiredCondition: "This is a required field",
  },
];

export interface StaffOnBoardingOutletContext {
  setShowMenu: (showMenu: boolean) => void;
  isFetching: boolean;
  isValidToken: boolean;
  isError: boolean;
}

export default function Staff(props: any) {
  const [response, setResponse] = useState<NotificationProps | null>(null);
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const { setShowMenu } = useOutletContext<StaffOnBoardingOutletContext>();
  const navigate = useNavigate();

  const onSubmit = async (data: FormData) => {
    if (data.confirmPassword !== data.password) {
      setError("confirmPassword", {
        type: "custom",
        message:
          "The confirm password does not match the Set password, please check and retype.",
      });
      return;
    }
    delete data.confirmPassword;
    setResponse(null);
    const response = (await api.put<GenericResponse>(
      `/api/user/invite-accept/${code}`,
      data
    )) as GenericResponse;
    if (response.status && response.status !== 200) {
      setResponse({
        type: "error",
        message: response.data?.message || response.data?.error,
      });
      return;
    }
    setResponse({
      type: "success",
      message: "Your account has successfully been created!",
    });
    window.localStorage.setItem("eicare_auth", JSON.stringify(response));
    setShowMenu(true);
    navigate("/onboarding/staff/start-onboarding");
  };

  const { setError, formState, watch, trigger, ...formMethods } =
    useForm<FormData>();
  const { isValid } = formState;
  const confirmPassword = watch("confirmPassword");
  const password = watch("password");

  useEffect(() => {
    if (confirmPassword) {
      if (confirmPassword !== password) {
        setError("confirmPassword", {
          type: "custom",
          message:
            "The confirm password does not match the Set password, please check and retype.",
        });
        return;
      }
      trigger("confirmPassword");
    }
  }, [confirmPassword, password, setError, trigger]);

  return (
    <div className="py-1 my-10">
      <main className="mx-auto max-w-2xl sm:px-6 lg:px-8 py-5 bg-white rounded-md shadow">
        <div className="bg-primary-50 p-5 flex flex-col justify-center items-center rounded-md">
          <img
            className="min-h-44 w-full lg:h-60 lg:w-auto"
            src="/images/ei-care-logo.png"
            alt="EICARE"
          />
          <h1 className="text-3xl leading-9 font-extrabold text-gray-900 mt-6">
            Create account | 创建账户
          </h1>
          <p className="text-sm leading-5 font-normal text-primary-800 mt-1 w-3/5 text-center">
            Please choose a username for your EICARE staff account and set your
            password.
          </p>
          <p className="text-sm leading-5 font-normal text-primary-800 mt-1 w-3/5 text-center">
            请选择 EICARE 员工账户用户名并设置你的密码
          </p>
        </div>
        {
          <div className="pt-4">
            {response && (
              <Notification type={response.type} message={response.message} />
            )}
            <ReusableForm
              fields={fields}
              onSubmit={onSubmit}
              id="create-account-staff-onboarding"
              formClassName="px-5"
              formMethods={{
                ...formMethods,
                setError,
                formState,
                watch,
                trigger,
              }}
            />
            <div className="w-full flex justify-between items-center  rounded-b-md p-5">
              <Button
                variant="primary"
                type="submit"
                form="create-account-staff-onboarding"
                className="w-full pr-1"
                disabled={response?.type === "success" || !isValid}
              >
                Create account | 创建账户
              </Button>
            </div>
          </div>
        }
      </main>
    </div>
  );
}
