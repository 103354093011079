import { GenericResponse, Staff, User } from "api/types";
import {
  addCandidateQuestions,
  addCommencementDate,
  addInterviwers,
  deleteCandidateQuestions,
  updateCandidateQuestions,
  useGetAdminStaffs,
  useGetCandidateQuestions,
  useGetInterviewAnswers,
  useGetInterviewComments,
  useGetInterviewQuestions,
} from "api/user";
import React, { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { FormProvider, useForm, UseFormGetValues } from "react-hook-form";
import { QueryClient, useQueryClient } from "react-query";
import { getTodayDate } from "utils/util";
import Button from "./Button";
import Calendar from "./Calendar";
import Checkbox from "./Checkbox";
import Comments from "./Comments";
import { Icon } from "./Icon";
import { InterviewQuestions } from "./InterviewQuestions";
import Modal from "./Modal";
import Notification from "./Notification";
import { FieldType, FormData } from "./types";
import { handleGenericResponse, invalidateQueries } from "./utility";

interface InterviewDetailProps {
  user: User;
  admin?: User;
  className?: string;
}

export interface InterviewAnswers {
  id: number;
  score: number;
  staffInterviewQuestionId: number;
}

const getInterviewGuide = (
  showInterviewGuide: boolean,
  setShowInterviewGuide: (show: boolean) => void
) => {
  return (
    <Modal
      isOpen={showInterviewGuide}
      onClose={() => setShowInterviewGuide(false)}
      modalHeader=""
      className="!bg-white"
      modalBodyClassName="!mt-2 pb-6 px-6"
      customModalStyles={{ width: "864px" }}
    >
      <h3 className="text-primary-900 font-bold flex justify-start items-center text-gray-900">
        <Icon name="info" className="text-gray-900" />
        <span className="ml-3 text-gray-900 text-sm leading-5 font-semibold">
          Interview Guide
        </span>
        <Button
          className="absolute right-2 top-2 !rounded-full p-2"
          size="noSize"
          aria-label="Close"
          onClick={() => setShowInterviewGuide(false)}
          variant="gray"
        >
          <Icon name="close" />
        </Button>
      </h3>
      <div className="mt-6 ml-7">
        <h5 className="text-xs leading-4 font-semibold uppercase text-primary-700">
          Interview objective
        </h5>
        <p className="mt-2 text-sm leading-5 font-medium text-gray-900">
          To assess candidates capability to perform the role:
        </p>
        <p className="mt-1 text-sm leading-5 font-normal text-gray-900">
          1 - Experience – appropriate similar experience in performing the
          role;
        </p>
        <p className="mt-1 text-sm leading-5 font-normal text-gray-900">
          2 - Skills – appropriate level of skill to perform the role;
        </p>
        <p className="mt-1 text-sm leading-5 font-normal text-gray-900">
          3 - Attitude – appropriate attitude to fit with the team and
          contribute positively;
        </p>
        <p className="mt-1 text-sm leading-5 font-normal text-gray-900">
          4 - Competency and knowledge through appropriate response to questions
          and role specific scenario;
        </p>
        <p className="mt-1 text-sm leading-5 font-normal text-gray-900">
          5 - Literacy - (If Applicable) ability to read and write English and
          comprehend the written word, also checking spelling, grammar and
          punctuation
        </p>
      </div>
      <div className="mt-6 ml-7">
        <h5 className="text-xs leading-4 font-semibold uppercase text-primary-700">
          Interview introduction
        </h5>
        <p className="mt-2 text-sm leading-5 font-normal text-gray-900">
          1 - Thank the candidate for attending the interview
        </p>
        <p className="mt-1 text-sm leading-5 font-normal text-gray-900">
          2 - Introduce self, titles and explain how your roles fit into the
          company
        </p>
        <p className="mt-1 text-sm leading-5 font-normal text-gray-900">
          3 - Explain the interview process:
          <ul>
            <li className="ml-5">
              a - The interview will take between 10- 20 minutes{" "}
            </li>
            <li className="ml-5">
              b - There will be a series of behavioural based questions the
              panel would like the candidate to tell us about specific work
              situations which are similar to the question
            </li>
            <li className="ml-5">
              c - Information provided by candidate will be kept confidential
              and not be shared{" "}
            </li>
            <li className="ml-5">
              d - The interviewer will take notes throughout the interview{" "}
            </li>
          </ul>
        </p>
        <p className="mt-1 text-sm leading-5 font-normal text-gray-900">
          4 - Provide the candidate with a brief Description of EICARE,
          explaining where this role fits into the bigger picture and how this
          role contributes to the goals of your company.
        </p>
      </div>
      <div className="mt-6 ml-7">
        <h5 className="text-xs leading-4 font-semibold uppercase text-primary-700">
          Interview scoring guide
        </h5>
        <p className="space-x-4 mt-2 text-sm leading-5 font-normal text-gray-900">
          <span>3 = Excellent </span>
          <span>2 = Average</span>
          <span>1 = Not suitable</span>
        </p>
      </div>
      <div className="mt-6 ml-7">
        <h5 className="text-xs leading-4 font-semibold uppercase text-primary-700">
          Closing the interview
        </h5>
        <p className="mt-2 text-sm leading-5 font-normal text-gray-900">
          1 - Thank the candidate for attending the interview
        </p>
        <p className="mt-1 text-sm leading-5 font-normal text-gray-900">
          2 - Confirm next steps in the selection process and when the candidate
          can expect to hear our decision
        </p>
        <p className="mt-1 text-sm leading-5 font-normal text-gray-900">
          3 - Walk the candidate to the exit
        </p>
      </div>
    </Modal>
  );
};

const getInterviewOutcome = (score: number) => {
  if (score >= 18) {
    return (
      <div className="flex space-x-3 bg-green-50 p-4 items-center rounded-md">
        <Icon name="solidCheck" />
        <span className="text-sm leading-5 font-semibold text-red-900">
          Excellent - Recommend for hire
        </span>
      </div>
    );
  }
  if (score >= 14) {
    return (
      <div className="flex space-x-3 bg-yellow-50 p-4 items-center rounded-md">
        <Icon name="info" className="text-yellow-500" />
        <span className="text-sm leading-5 font-semibold text-yellow-900">
          Average - Consider recommending after reference check
        </span>
      </div>
    );
  }
  return (
    <div className="flex space-x-3 bg-red-50 p-4 items-center rounded-md">
      <Icon name="redCross" />
      <span className="text-sm leading-5 font-semibold text-red-900">
        Not suitable for hire
      </span>
    </div>
  );
};

const getInterviewers = (
  showInterviewers: boolean,
  setShowInterviewers: (show: boolean) => void,
  admins: Staff[],
  getValues: UseFormGetValues<FormData>,
  user: User,
  queryClient: QueryClient
) => {
  return (
    <Modal
      isOpen={showInterviewers}
      onClose={() => setShowInterviewers(false)}
      modalHeader=""
      className="!bg-white"
      modalBodyClassName="!mt-0 pt-2"
      customModalStyles={{ width: "394px", minWidth: "394px" }}
    >
      <h3 className="text-primary-900 font-bold flex justify-start items-center text-gray-900">
        <span className="ml-6 text-gray-900 text-lg leading-6 font-semibold">
          Select Interviewers
        </span>
        <Button
          className="absolute right-2 top-2 !rounded-full p-2"
          size="noSize"
          aria-label="Close"
          onClick={() => setShowInterviewers(false)}
          variant="gray"
        >
          <Icon name="close" />
        </Button>
      </h3>
      <div className="mt-6 pb-6 px-6 space-y-3">
        {admins.map((admin, index) => (
          <Checkbox
            name={`selectedAdmins.${index}`}
            label={`${admin.firstName} ${admin.lastName}`}
            value={admin.id}
            key={admin.id}
            type={FieldType.Checbox}
            defaultValue={
              (user.staffProfile.interviewers || []).indexOf(admin.id) > -1
            }
          />
        ))}
      </div>
      <div className="flex justify-end items-center bg-gray-50 border-t border-gray-200 shadow rounded-b-md px-4 py-3  rounded-b-md space-x-3">
        <Button variant="white" onClick={() => setShowInterviewers(false)}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={async () => {
            const selectedAdmins = (getValues("selectedAdmins") || []).filter(
              (admin: string) => admin
            );
            const response = await addInterviwers(
              { interviewers: selectedAdmins },
              user.id
            );
            handleGenericResponse(response as unknown as GenericResponse);
            if (response.interviewers && response.interviewers.length) {
              invalidateQueries(queryClient, [
                `/api/user/admin/profile/${user.id}`,
              ]);
            }
            setShowInterviewers(false);
          }}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export const InterviewDetail: React.FC<InterviewDetailProps> = ({
  user,
  className,
  admin,
}) => {
  const [showInterviewGuide, setShowInterviewGuide] = useState(false);
  const [noOfQuestions, setNoOfQuestions] = useState(0);
  const [showInterviewers, setShowInterviewers] = useState(false);
  const { data } = useGetInterviewQuestions();
  const { data: interviewComments } = useGetInterviewComments(user.id);
  const { data: interviewAnswers } = useGetInterviewAnswers(user.id);
  const { data: candidateQuestions } = useGetCandidateQuestions(user.id);
  const { data: allAdmins } = useGetAdminStaffs();
  const queryClient = useQueryClient();
  const { getValues, ...formMethods } = useForm<FormData>();
  const methods = {
    ...formMethods,
    getValues,
  };

  useEffect(() => {
    data && setNoOfQuestions(data.length);
  }, [data]);

  const completionPercentage = (
    ((interviewAnswers || [])?.length / (noOfQuestions || 1)) *
    100
  ).toFixed(0);
  const totalScore =
    interviewAnswers?.reduce((sum, item) => sum + item.score, 0) || 0;
  const selectedInterviewersName = allAdmins
    ?.filter(
      (admin) =>
        user.staffProfile.interviewers &&
        user.staffProfile.interviewers.indexOf(admin.id) > -1
    )
    .map((admin) => `${admin.firstName} ${admin.lastName}`);
  return (
    <FormProvider {...methods}>
      <div className="p-6">
        <div className="flex items-center justify-between space-y-3 pb-6 border-b border-gray-300">
          <div className="space-y-3 flex-1">
            <h3 className="text-gray-900 text-base leading-7 font-semibold text-left">
              Interviewers
            </h3>
            <div className="flex space-x-14">
              <div className="flex flex-col space-y-1">
                <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                  selected Interviewers
                </span>
                {selectedInterviewersName &&
                selectedInterviewersName.length > 0 ? (
                  <div className="flex space-x-2 items-center">
                    <span className="text-sm leading-5 font-medium text-gray-900">
                      {selectedInterviewersName.join(", ")}
                    </span>
                    <Button
                      variant="gray"
                      className="cursor-pointer w-5 h-5 !p-0 !rounded-full"
                      onClick={() => setShowInterviewers(true)}
                    >
                      <Icon name="pencil" className="text-gray-400" />
                    </Button>
                  </div>
                ) : (
                  <Button size="xs" onClick={() => setShowInterviewers(true)}>
                    Select interviewers
                  </Button>
                )}
              </div>
              <div className="flex flex-col space-y-1">
                <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700 min-w-32">
                  Candidate name
                </span>
                <span className="text-sm leading-5 font-medium">{`${user.firstName} ${user.lastName}`}</span>
              </div>
              <div className="flex flex-col space-y-1  min-w-32">
                <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                  OCCUPATION
                </span>
                <span className="text-sm leading-5 font-medium">
                  {user.staffProfile?.occupation}
                </span>
              </div>
            </div>
          </div>
          <div>
            <Button
              variant="grayTonal"
              className="space-x-2"
              onClick={() => setShowInterviewGuide(true)}
            >
              <Icon name="info" className="!text-gray-600" />
              <span>Interview guide</span>
            </Button>
          </div>
        </div>
        <div className="py-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <h3 className="text-base leading-7 font-semibold text-gray-900">
                Questions
              </h3>
              <Notification
                type="warning"
                message="Please ensure to score all questions in order to get an assessment outcome."
                className="inline-flex"
              />
            </div>
            <div className="flex items-center space-x-2">
              <span className="text-sm leading-5 font-medium text-gray-700">
                Question completion
              </span>
              <CircularProgressbar
                value={parseFloat(completionPercentage)}
                text={`${completionPercentage}%`}
                className="h-10 w-10 font-bold"
                styles={{
                  path: {
                    stroke: `#0FACD7`,
                  },
                  trail: {
                    stroke: "#E5E7EB",
                  },
                  text: {
                    fill: "#111827",
                    fontSize: "30px",
                  },
                }}
              />
            </div>
          </div>
        </div>
        {data && (
          <div>
            <InterviewQuestions
              questions={data}
              interviewComments={interviewComments}
              interviewAnswers={interviewAnswers}
              user={user}
              admin={admin}
            />
          </div>
        )}
        <div className="flex justify-between items-center bg-gray-50 border-t border-gray-200 shadow rounded-b-md px-6 py-3  rounded-b-md space-x-3">
          <div className="min-h-12">
            {interviewAnswers?.length === noOfQuestions && (
              <div className="flex items-center space-x-2">
                <span className="text-sm leading-5 font-semibold tracking-wide uppercase">
                  Interview assessment outcome:
                </span>
                <span>{getInterviewOutcome(totalScore)}</span>
              </div>
            )}
          </div>
          <div className="space-x-2">
            <span className="text-sm leading-5 font-semibold tracking-wide uppercase text-gray-900">
              Total Score:
            </span>
            <span className="text-base leading-6 font-bold tracking-wide uppercase text-gray-900">
              {totalScore}
            </span>
          </div>
        </div>
        <div className="mt-6 space-y-3">
          <h3 className="text-base leading-7 font-semibold text-gray-900">
            Availability to commence date:
          </h3>

          <div className="flex items-center space-x-4">
            <Calendar
              name="commencementDate"
              label=""
              type={FieldType.Calendar}
              minDate={getTodayDate()}
              containerClassName="!mt-0"
              defaultValue={user.staffProfile.availiableToCommenceDate}
            />
            <Button
              onClick={async () => {
                const response = await addCommencementDate(
                  { availiableToCommenceDate: getValues("commencementDate") },
                  user.id
                );
                handleGenericResponse(response as unknown as GenericResponse);
                if (response) {
                  invalidateQueries(queryClient, [
                    `/api/user/admin/profile/${user.id}`,
                  ]);
                }
              }}
            >
              Save
            </Button>
          </div>
        </div>
        <div className="mt-6">
          <h3 className="text-base leading-7 font-semibold text-gray-900">
            Candidate questions
          </h3>
          <div className="py-2 text-gray-700">
            {admin && (
              <Comments
                admin={admin}
                data={candidateQuestions?.map((question) => {
                  if (question.candidateQuestionAddedBy && question.question) {
                    return {
                      ...question,
                      comment: question.question || "",
                      commentedBy: question.candidateQuestionAddedBy,
                    };
                  }
                  return question;
                })}
                queryInvalidateFn={() => {
                  invalidateQueries(queryClient, [
                    `/api/user/admin/staff/candidate-questions/${user.id}`,
                  ]);
                }}
                addComments={async (newComment: string) =>
                  await addCandidateQuestions({ question: newComment }, user.id)
                }
                updateComments={async (content: string, commentId: number) =>
                  await updateCandidateQuestions(
                    { question: content },
                    commentId
                  )
                }
                deleteComments={async (id: number) =>
                  await deleteCandidateQuestions(id)
                }
              />
            )}
          </div>
        </div>
        {showInterviewGuide &&
          getInterviewGuide(showInterviewGuide, setShowInterviewGuide)}
        {showInterviewers &&
          allAdmins?.length &&
          getInterviewers(
            showInterviewers,
            setShowInterviewers,
            allAdmins,
            getValues,
            user,
            queryClient
          )}
      </div>
    </FormProvider>
  );
};
