import { getToken } from "api";
import { QueryFunctionContext, QueryKey } from "react-query";

export const BASE_URL =
  process.env.REACT_APP_BASE_URL?.trim() || "http://localhost:3001";

// Stores a callback that is called whenever a request returns a 403 - Unauthenticated response.
let _onNoAuth = () => {};

/**
 * `fn` will get callend when an API request results in a 401.
 */
export function onNoAuth(fn: () => void) {
  _onNoAuth = fn;
}

const defaulGetConfig = {
  cache: "no-cache",
  method: "GET",
} as RequestInit;

export async function simpleGet(
  { queryKey }: QueryFunctionContext<QueryKey, any>,
  config: RequestInit = defaulGetConfig
) {
  const url = (BASE_URL + queryKey[0]) as string;
  const configWithDefaults = {
    ...defaulGetConfig,
    ...config,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  const response = await fetch(url, configWithDefaults);
  if (response.status === 401) {
    _onNoAuth();
  }
  const parsedResponse = await response.json();
  if (!response.ok) {
    throw new Error(JSON.stringify(parsedResponse));
  }
  return parsedResponse;
}

export async function postForm(endpoint: string, body: FormData) {
  const config = {
    cache: "no-cache",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body,
  } as RequestInit;

  endpoint = BASE_URL + endpoint;

  const response = await fetch(endpoint, config);
  if (response.status === 401) {
    _onNoAuth();
  }
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json();
}

export async function postJson(endpoint: string, body?: object) {
  const config = {
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    method: "POST",
  } as RequestInit;
  if (body) {
    config.body = JSON.stringify(body);
  }
  endpoint = BASE_URL + endpoint;
  const response = await fetch(endpoint, config);
  if (response.status === 401) {
    _onNoAuth();
  }
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json();
}
