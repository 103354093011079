import { User } from "api/types";
import classNames from "classnames";
import React, { useState } from "react";
import Step1 from "routes/onboarding/staff/steps/step1";
import Step10 from "routes/onboarding/staff/steps/step10";
import Step2 from "routes/onboarding/staff/steps/step2";
import Step3 from "routes/onboarding/staff/steps/step3";
import Step4 from "routes/onboarding/staff/steps/step4";
import Step5 from "routes/onboarding/staff/steps/step5";
import Step6 from "routes/onboarding/staff/steps/step6";
import Step7 from "routes/onboarding/staff/steps/step7";
import Step8 from "routes/onboarding/staff/steps/step8";
import Step9 from "routes/onboarding/staff/steps/step9";
import Accordion from "./Accordion";
import { Icon, IconName } from "./Icon";

interface Step {
  title: string;
  content: React.ReactNode;
  iconName: IconName;
}

interface MultiStepAccordionProps {
  activeStep: number;
  onBoardingStep: number;
  stepCompleted: number;
  user: User;
}

const MultiStepAccordion: React.FC<MultiStepAccordionProps> = ({
  activeStep,
  stepCompleted = 0,
  user,
  onBoardingStep,
}: MultiStepAccordionProps) => {
  const [isToggled, setIsToggled] = useState(false);
  const steps: Step[] = [
    {
      title: "Residency status and work rights | 居留身份和工作权利",
      content: (
        <Step1
          isCompleted={stepCompleted > 1}
          user={user}
          isToggled={isToggled}
        />
      ),
      iconName: "step1",
    },
    {
      title: "Personal details | 个人详情",
      content: (
        <Step2
          isCompleted={stepCompleted > 2}
          user={user}
          isToggled={isToggled}
        />
      ),
      iconName: "step2",
    },
    {
      title:
        "My health and emergency contact details | 我的健康情况与紧急联系人详情",
      content: (
        <Step3
          isCompleted={stepCompleted > 3}
          user={user}
          isToggled={isToggled}
        />
      ),
      iconName: "step3",
    },
    {
      title: "My training and qualification details | 我的培训和资质详情",
      content: (
        <Step4
          isCompleted={stepCompleted > 4}
          user={user}
          isToggled={isToggled}
        />
      ),
      iconName: "step4",
    },
    {
      title: "Police check | 无犯罪公证",
      content: (
        <Step5
          isCompleted={stepCompleted > 5}
          user={user}
          isToggled={isToggled}
        />
      ),
      iconName: "step5",
    },
    {
      title: "Book my interview | 预约面试",
      content: (
        <Step6
          isCompleted={stepCompleted > 6}
          user={user}
          isToggled={isToggled}
        />
      ),
      iconName: "step6",
    },
    {
      title:
        "Bank, tax file number and superannuation details | 银行，税号和养老金账户详情",
      content: (
        <Step7
          isCompleted={stepCompleted > 7}
          user={user}
          isToggled={isToggled}
        />
      ),
      iconName: "step7",
    },
    {
      title: "My vehicle and driving licence details | 交通工具与驾照详情",
      content: (
        <Step8
          isCompleted={stepCompleted > 8}
          user={user}
          isToggled={isToggled}
        />
      ),
      iconName: "step8",
    },
    {
      title: "Employee handbook and statutory declaration | 员工手册与法定声明",
      content: (
        <Step9
          isCompleted={stepCompleted > 9}
          user={user}
          isToggled={isToggled}
        />
      ),
      iconName: "step9",
    },
    {
      title: "My contract | 我的合同",
      content: (
        <Step10
          isCompleted={stepCompleted > 10}
          user={user}
          isToggled={isToggled}
        />
      ),
      iconName: "step10",
    },
  ];
  return (
    <div className="w-full py-5">
      {steps.map((step, index) => {
        const isCompleted = stepCompleted - 1 > index;
        return (
          <div
            className={classNames(
              "flex items-start relative",
              index === 0 && "pt-4"
            )}
            key={step.title}
          >
            <div
              className={
                onBoardingStep === index
                  ? "active-step"
                  : isCompleted
                    ? "completed-step"
                    : "disbaled-step"
              }
            ></div>
            <div
              className={classNames(
                "rounded-full h-10 w-10 flex items-center justify-center border-2 p-2",
                isCompleted
                  ? "bg-primary-500 border-primary-500"
                  : onBoardingStep === index
                    ? "border-primary-500 bg-white"
                    : "border-gray-300 bg-gray-50"
              )}
            >
              <Icon
                name={step.iconName}
                className="mr-2 shadow"
                stroke={
                  isCompleted
                    ? "#ffffff"
                    : onBoardingStep === index
                      ? "#0FACD7"
                      : "#9CA3AF"
                }
              />
            </div>
            <div className="ml-4 w-full">
              <Accordion
                key={step.title}
                title={step.title}
                content={step.content}
                isActive={index === activeStep}
                isCompleted={isCompleted}
                displayStep={`Step ${index === 9 ? 10 : "0" + (index + 1)}`}
                isOnBoardingStep={onBoardingStep >= index}
                stepNumber={index + 1}
                setIsToggled={setIsToggled}
                isToggled={isToggled}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MultiStepAccordion;
