import { GenericResponse, GENERICS, Staff, User } from "api/types";
import {
  addVehicleAnswers,
  addVehicleReviewers,
  updateVehicleInspectionDetails,
  useGetAdminStaffs,
  useGetVehicleAnswers,
  useGetVehicleQuestions,
} from "api/user";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { FormProvider, useForm, UseFormGetValues } from "react-hook-form";
import { QueryClient, useQueryClient } from "react-query";
import Badge from "./Badge";
import Button from "./Button";
import Checkbox from "./Checkbox";
import FileUpload from "./FileUpload";
import { Icon } from "./Icon";
import Modal from "./Modal";
import Notification from "./Notification";
import Radio from "./RadioBox";
import Sign from "./Sign";
import { FieldType, FormData } from "./types";
import { handleGenericResponse, invalidateQueries } from "./utility";

interface VehicleDetailProps {
  user: User;
  admin?: User;
  className?: string;
}

export interface VehicleAnswers {
  id: number;
  score: string;
  vehicleReviewQuestionId: number;
}

export interface VehicleQuestion {
  id: number;
  question: string;
  archivedAt: string;
  createdAt: string;
  updatedAt: string;
}

interface VehicleQuestionsProps {
  question: VehicleQuestion;
  vehicleAnswers: VehicleAnswers[] | undefined;
  user: User;
  admin?: User;
  questionNo: number;
  totalRecords: number;
}

const getAnswerCSS = (answer: string) => {
  if (answer.toLowerCase() === GENERICS.Yes.toLowerCase()) {
    return "!text-green-700";
  }
  if (answer.toLowerCase() === GENERICS.No.toLowerCase()) {
    return "!text-secondary-700";
  }
  if (answer.toLowerCase() === "n/a") {
    return "!text-gray-700";
  }
};

const VehicleQuestions: React.FC<VehicleQuestionsProps> = ({
  question,
  user,
  admin,
  vehicleAnswers,
  questionNo,
  totalRecords,
}) => {
  const [showScoreSelectModal, setShowScoreSelectModal] = useState(false);
  const [showConfirmScoreSelectModal, setShowConfirmScoreSelectModal] =
    useState(false);
  const { getValues, ...formMethods } = useForm<FormData>();
  const methods = {
    ...formMethods,
    getValues,
  };
  const queryClient = useQueryClient();
  const vehicleAnswer = vehicleAnswers?.find(
    (answer) => answer.vehicleReviewQuestionId === question.id
  );
  return (
    <div
      className={classNames(
        totalRecords !== questionNo && "border-b border-gray-100"
      )}
    >
      <FormProvider {...methods}>
        <div className="w-full text-left py-3 focus:outline-none flex justify-between items-center">
          <div className="flex justify-start items-center space-x-2 flex-1">
            <Badge
              message={questionNo.toString()}
              className="bg-primary-50   !p-0"
              statusClassName="!text-sm text-primary-900 font-semibold h-8 w-8 flex justify-center items-center"
            />
            <span className="text-base leading-6 font-medium text-gray-900 w-4/5">
              {question.question}
            </span>
          </div>
          <div className="flex space-x-5 items-center">
            {vehicleAnswer ? (
              <div className="flex space-x-1 items-center w-16">
                <div className="flex-1 flex justify-center">
                  <span
                    className={classNames(
                      "text-xs leading-4 font-semibold uppercase text-primary-600",
                      getAnswerCSS(vehicleAnswer.score)
                    )}
                  >
                    {vehicleAnswer.score}
                  </span>
                </div>
                <Button
                  variant="gray"
                  className="cursor-pointer w-5 h-5 !p-0 !rounded-full"
                  onClick={() => setShowConfirmScoreSelectModal(true)}
                >
                  <Icon name="pencil" className="text-gray-400" />
                </Button>
              </div>
            ) : (
              <Button
                className="w-16"
                size="xs"
                onClick={() => setShowScoreSelectModal(true)}
              >
                Answer
              </Button>
            )}
          </div>
          <Modal
            isOpen={showScoreSelectModal}
            onClose={() => setShowScoreSelectModal(false)}
            modalHeader=""
            className="!bg-white"
            modalBodyClassName="!mt-2 pt-2"
            customModalStyles={{ width: "530px", minWidth: "530px" }}
          >
            <div className="pb-6 px-6">
              <h3 className="text-primary-900 font-bold flex justify-start items-center text-gray-900">
                <span className="text-gray-900 text-lg leading-6 font-semibold">
                  {question.question}
                </span>
                <Button
                  className="absolute right-2 top-2 !rounded-full p-2"
                  size="noSize"
                  aria-label="Close"
                  onClick={() => setShowScoreSelectModal(false)}
                  variant="gray"
                >
                  <Icon name="close" />
                </Button>
              </h3>
              <div className="mt-6">
                <Radio
                  name="score"
                  label=""
                  type={FieldType.Radio}
                  defaultValue={vehicleAnswer?.score?.toString()}
                  options={[
                    { label: "YES", value: GENERICS.Yes },
                    {
                      label: "NO",
                      value: GENERICS.No,
                    },
                    { label: "N/A", value: "N/A" },
                  ]}
                  isCol
                  labelClassName="font-medium"
                />
              </div>
            </div>

            <div className="flex justify-end items-center bg-gray-50 border-t border-gray-200 shadow rounded-b-md px-4 py-3  rounded-b-md space-x-3">
              <Button
                variant="white"
                onClick={() => setShowScoreSelectModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={async () => {
                  const response = await addVehicleAnswers(
                    { questionId: question.id, score: getValues("score") },
                    user.id,
                    vehicleAnswer?.score ? "put" : "post"
                  );
                  handleGenericResponse(response as unknown as GenericResponse);
                  invalidateQueries(queryClient, [
                    `/api/user/admin/vehicle-review-answer/${user.id}`,
                  ]);
                  setShowScoreSelectModal(false);
                }}
              >
                Save
              </Button>
            </div>
          </Modal>
          <Modal
            isOpen={showConfirmScoreSelectModal}
            onClose={() => setShowConfirmScoreSelectModal(false)}
            modalHeader=""
            className="!bg-yellow-50"
            modalBodyClassName="!mt-0 pb-2 px-4"
          >
            <h3 className="text-yellow-900 font-bold flex justify-start items-center">
              <Icon name="warning" />
              <span className="ml-3">
                Please confirm you want to change the previous answer.
              </span>
            </h3>
            <div className="flex justify-start items-center  rounded-b-md  rounded-b-md px-4 pt-2">
              <Button
                variant="yellowTonal"
                onClick={() => setShowConfirmScoreSelectModal(false)}
                className="mr-3"
              >
                Cancel
              </Button>
              <Button
                variant="yellowTonal"
                onClick={() => {
                  setShowConfirmScoreSelectModal(false);
                  setShowScoreSelectModal(true);
                }}
              >
                Confirm
              </Button>
            </div>
          </Modal>
        </div>
      </FormProvider>
    </div>
  );
};

const getVehicleInspector = (
  showVehicleInspector: boolean,
  setShowVehicleInspector: (show: boolean) => void,
  admins: Staff[],
  getValues: UseFormGetValues<FormData>,
  user: User,
  queryClient: QueryClient
) => {
  return (
    <Modal
      isOpen={showVehicleInspector}
      onClose={() => setShowVehicleInspector(false)}
      modalHeader=""
      className="!bg-white"
      modalBodyClassName="!mt-0 pt-2"
      customModalStyles={{ width: "394px", minWidth: "394px" }}
    >
      <h3 className="text-primary-900 font-bold flex justify-start items-center text-gray-900">
        <span className="ml-6 text-gray-900 text-lg leading-6 font-semibold">
          Select vehicle inspector
        </span>
        <Button
          className="absolute right-2 top-2 !rounded-full p-2"
          size="noSize"
          aria-label="Close"
          onClick={() => setShowVehicleInspector(false)}
          variant="gray"
        >
          <Icon name="close" />
        </Button>
      </h3>
      <div className="mt-6 pb-6 px-6 space-y-3">
        {admins.map((admin, index) => (
          <Checkbox
            name={`selectedAdmins.${index}`}
            label={`${admin.firstName} ${admin.lastName}`}
            value={admin.id}
            key={admin.id}
            type={FieldType.Checbox}
            defaultValue={
              (user.staffProfile.vehicleReviewAdmins || []).indexOf(admin.id) >
              -1
            }
          />
        ))}
      </div>
      <div className="flex justify-end items-center bg-gray-50 border-t border-gray-200 shadow rounded-b-md px-4 py-3  rounded-b-md space-x-3">
        <Button variant="white" onClick={() => setShowVehicleInspector(false)}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={async () => {
            const selectedAdmins = (getValues("selectedAdmins") || []).filter(
              (admin: string) => admin
            );
            const response = await addVehicleReviewers(
              { vehicleReviewAdmins: selectedAdmins },
              user.id
            );
            handleGenericResponse(response as unknown as GenericResponse);
            if (
              response.vehicleReviewAdmins &&
              response.vehicleReviewAdmins.length
            ) {
              invalidateQueries(queryClient, [
                `/api/user/admin/profile/${user.id}`,
              ]);
            }
            setShowVehicleInspector(false);
          }}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export const VehicleDetail: React.FC<VehicleDetailProps> = ({
  user,
  className,
  admin,
}) => {
  const [noOfQuestions, setNoOfQuestions] = useState(0);
  const [showVehicleInspector, setShowVehicleInspector] = useState(false);
  const { data } = useGetVehicleQuestions();
  const { data: vehicleAnswers } = useGetVehicleAnswers(user.id);
  const { data: allAdmins } = useGetAdminStaffs();
  const queryClient = useQueryClient();
  const { getValues, handleSubmit, ...formMethods } = useForm<FormData>();
  const methods = {
    ...formMethods,
    getValues,
    handleSubmit,
  };

  useEffect(() => {
    data && setNoOfQuestions(data.length);
  }, [data]);

  const completionPercentage = (
    ((vehicleAnswers || [])?.length / (noOfQuestions || 1)) *
    100
  ).toFixed(0);
  const selectedVehicleInspectorName = allAdmins
    ?.filter(
      (admin) =>
        user.staffProfile.vehicleReviewAdmins &&
        user.staffProfile.vehicleReviewAdmins.indexOf(admin.id) > -1
    )
    .map((admin) => `${admin.firstName} ${admin.lastName}`);

  const onSubmit = async (data: FormData) => {
    delete data.selectedAdmins;
    const response = await updateVehicleInspectionDetails(data, user.id);
    handleGenericResponse(response as unknown as GenericResponse);
    invalidateQueries(queryClient, [`/api/user/admin/profile/${user.id}`]);
  };

  return (
    <FormProvider {...methods}>
      <form
        id={`vehicleInspectionDetailForm`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="p-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <h3 className="text-base leading-7 font-semibold text-gray-900">
                Vehicle inspection checklist
              </h3>
            </div>
            <div className="flex items-center space-x-2">
              <span className="text-sm leading-5 font-medium text-gray-700">
                Question completion
              </span>
              <CircularProgressbar
                value={parseFloat(completionPercentage)}
                text={`${completionPercentage}%`}
                className="h-10 w-10 font-bold"
                styles={{
                  path: {
                    stroke: `#0FACD7`,
                  },
                  trail: {
                    stroke: "#E5E7EB",
                  },
                  text: {
                    fill: "#111827",
                    fontSize: "30px",
                  },
                }}
              />
            </div>
          </div>
          {data && (
            <div
              className={classNames(
                "bg-white shadow rounded-md my-3",
                className
              )}
            >
              <div className={classNames("p-3 pb-0", className)}>
                {data.map((refQuestion, index) => (
                  <VehicleQuestions
                    key={refQuestion.id}
                    question={refQuestion}
                    user={user}
                    admin={admin}
                    questionNo={index + 1}
                    vehicleAnswers={vehicleAnswers}
                    totalRecords={data.length}
                  />
                ))}
              </div>
            </div>
          )}
          <div className="pt-6">
            <Notification
              type="warning"
              message="Note: Comments and actions (If you have ticked no to any items listed above, you should complete a hazard alert and attach a copy of this document) "
              className="inline-flex !mx-0"
            />
            <div className="mt-6 shadow rounded-md">
              <div className="bg-white px-6">
                <div className="border-b border-gray-300 py-6">
                  <FileUpload
                    fileUploadContainerClassName="flex w-full space-x-6 !mt-0"
                    name="hazardAlert"
                    label="Hazard alert"
                    type={FieldType.FileUpload}
                    className="w-full"
                    uploadContainerClassName="w-1/2"
                    uploadedFileContainerClassName="w-1/2"
                    defaultValue={user.staffProfile.hazardAlert}
                  />
                </div>
                <div className="flex py-6 space-x-6">
                  <div className="w-1/2 flex flex-col">
                    <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                      Inspection by
                    </span>
                    {selectedVehicleInspectorName &&
                    selectedVehicleInspectorName.length > 0 ? (
                      <div className="flex space-x-2 items-center">
                        <span className="text-sm leading-5 font-medium text-gray-900">
                          {selectedVehicleInspectorName.join(", ")}
                        </span>
                        <Button
                          variant="gray"
                          className="cursor-pointer w-5 h-5 !p-0 !rounded-full"
                          onClick={() => setShowVehicleInspector(true)}
                        >
                          <Icon name="pencil" className="text-gray-400" />
                        </Button>
                      </div>
                    ) : (
                      <Button
                        size="xs"
                        className="w-max"
                        onClick={() => setShowVehicleInspector(true)}
                      >
                        Select
                      </Button>
                    )}
                  </div>
                  <div className="w-1/2 flex flex-col">
                    <Sign
                      name="vehicleReviewSignatureDate"
                      label="Inspector signature *"
                      type={FieldType.Sign}
                      requiredCondition={"This is a required field"}
                      defaultValue={user.staffProfile.vehicleReviewSignedAt}
                      defaultSignature={
                        user.staffProfile.vehicleReviewSignature
                      }
                      signatureName="vehicleReviewSignature"
                      dataContaineClassName="flex-col"
                      successMessageClassName="!flex-row space-x-1 mt-5"
                      signInputClassName="w-105"
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-end items-center bg-gray-50 border-t border-gray-200 px-6  py-3  rounded-b-md space-x-3">
                <Button variant="white" type="submit">
                  Save
                </Button>
              </div>
            </div>
          </div>
          {showVehicleInspector &&
            allAdmins?.length &&
            getVehicleInspector(
              showVehicleInspector,
              setShowVehicleInspector,
              allAdmins,
              getValues,
              user,
              queryClient
            )}
        </div>
      </form>
    </FormProvider>
  );
};
