import { TransformedOrientationData } from "api/types";
import { useOccupationToOrientationDataMapping } from "api/user";
import Checkbox from "components/Checkbox";
import { FieldType, FormData } from "components/types";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { DashboardStaffOutletContext } from "routes/reports";
import { transformToOrientationData } from "utils/util";

export const StaffOrientation: React.FC = () => {
  const { user } = useOutletContext<DashboardStaffOutletContext>();
  const { watch, setValue, ...formMethods } = useForm<FormData>();
  const methods = {
    ...formMethods,
    watch,
    setValue,
  };
  const [orientationData, setOrientationData] =
    useState<TransformedOrientationData>();
  const preSelectedCheckedItem = { ...user.staffProfile.orientationItems };
  const { data: occupationToOrientationDataMapping } =
    useOccupationToOrientationDataMapping();

  useEffect(() => {
    if (occupationToOrientationDataMapping) {
      const transformedData = transformToOrientationData(
        occupationToOrientationDataMapping
      );
      const occupationData = Object.keys(transformedData).find(
        (item) =>
          transformedData[item].description === user.staffProfile.occupation
      );
      if (occupationData) {
        const dataForOccupation = transformedData[occupationData];
        setOrientationData({ ...dataForOccupation });
      }
    }
  }, [occupationToOrientationDataMapping, user.staffProfile.occupation]);

  return (
    <FormProvider {...methods}>
      <div className="mb-11">
        <h3 className="text-xl leading-7 font-semibold text-gray-900 my-6">
          Orientation
        </h3>
        <div className="bg-white rounded-md shadow">
          <h4 className="text-lg leading-6 font-semibold text-gray-900 px-6 pt-6 space-x-1">
            <span>{user.staffProfile.occupation}</span>
            <span>Orientation/Induction checklist</span>
          </h4>
          <div className="flex items-center flex-wrap p-6">
            {orientationData &&
              (orientationData?.occupations_orientation || []).map(
                (item, index) => (
                  <div className="w-1/2" key={item.name}>
                    <Checkbox
                      type={FieldType.Checbox}
                      label={item.description}
                      name={`orientationChecklist.${item.name}`}
                      className="mt-1"
                      key={item.name}
                      checked={preSelectedCheckedItem[item.name]?.value}
                      defaultValue={preSelectedCheckedItem[item.name]?.value}
                      isDisabled
                    />
                  </div>
                )
              )}
          </div>
        </div>
      </div>
    </FormProvider>
  );
};
