import classNames from "classnames";
import React from "react";
import { Icon } from "./Icon";

interface LinkProps {
  url: string;
  text?: string;
  className?: string;
  isIcon?: boolean;
  iconClassName?: string;
}

const Link: React.FC<LinkProps> = ({
  url,
  text,
  className,
  isIcon = true,
  iconClassName,
}) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames(
        iconClassName,
        className,
        !isIcon && "text-primary-400 underline"
      )}
    >
      {isIcon ? <Icon name="info" className={iconClassName} /> : text}
    </a>
  );
};

export default Link;
