import Button, { ButtonSizes, ButtonVariants } from "components/Button";
import Dialogue from "components/Dialogue";
import FileUpload from "components/FileUpload";
import { Icon, icons } from "components/Icon";
import Notification, { NotificationEnums } from "components/Notification";
import Select from "components/OldSelect";
import Input from "components/UnControlledInput";
import { FILE_NAME_MAPPING, FieldType, FormData } from "components/types";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { handleDownload } from "utils/util";
import Calendar from "../components/Calendar";
import Checkbox from "../components/Checkbox";
import { Loading } from "../components/Loading";
import Modal from "../components/Modal";
import Radio from "../components/RadioBox";
import Sign from "../components/Sign";
import Timer from "../components/Timer";

const uploadComponents = [
  {
    name: "WorkRelatedCertificates",
    label: "Work-related certificates",
    buttonLabel: "Upload work-related certificates",
    type: FieldType.FileUpload,
    requiredCondition: "This is a required field",
    fileUploadContainerClassName: "w-full mb-4",
    key: "WorkRelatedCertificates",
    labelClassName: "text-gray-900 font-bold !text-base",
    externalLink:
      "https://immi.homeaffairs.gov.au/visas/already-have-a-visa/check-visa-details-and-conditions/check-conditions-online/visa-holders",
  },
];

const qualificationOptions = [
  {
    label: "High school certificate",
    value: "High school certificate",
  },
  {
    label: "Diploma",
    value: "Diploma",
  },
  {
    label: "Undergraduate degree",
    value: "Undergraduate degree",
  },
  {
    label: "Post-graduate degree",
    value: "Post-graduate degree",
  },
  {
    label: "Masters degree",
    value: "Masters degree",
  },
  {
    label: "PhD",
    value: "PhD",
  },
  {
    label: "Certificate III",
    value: "Certificate III",
  },
];

export default function ComponentLib(props: any) {
  const { handleSubmit, formState, ...formMethods } = useForm<FormData>();
  const { isValid } = formState;
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [openDialogue, setOpenDialogue] = useState(true);

  const onSubmit = async (data: FormData) => {};

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="bg-white shadow rounded-md">
      {Object.keys(NotificationEnums).map((notificationType) => (
        <Notification
          type={notificationType as keyof typeof NotificationEnums}
          message={"Message for each type"}
          className="my-3"
        />
      ))}

      <div className="px-5 mt-5">
        <FormProvider {...{ ...formMethods, handleSubmit, formState }}>
          <form id={"step-4"} onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col justify-start items-center">
              <div className="mt-5">
                <Dialogue
                  key={"test"}
                  title="Please check and confirm the email address is correct"
                  confirmButtonLabel="Confirm"
                  cancelButtonLabel="Cancel"
                  children={
                    <Input label="Email" type={FieldType.Input} name="email" />
                  }
                  open={openDialogue}
                  handleClose={() => setOpenDialogue(false)}
                  handleConfirm={() => setOpenDialogue(false)}
                  iconElement={
                    <div className="mx-auto flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-16 sm:w-16 my-4">
                      <Icon name="solidCheck" />
                    </div>
                  }
                />
                <Button variant="primary" onClick={() => setOpenDialogue(true)}>
                  Trigger Dialogue
                </Button>
              </div>
              <Select
                name="highestQualification"
                label="Highest qualification in your country *"
                type={FieldType.Select}
                options={qualificationOptions}
                requiredCondition="This is a required field"
                containerClassName="mr-3 !mt-3"
                className="!w-96"
              />
              <Input
                name="placeHighestQualification"
                label="School where highest qualification was attained *"
                type={FieldType.Input}
                requiredCondition="This is a required field"
                containerClassName="w-96"
              />
              <Calendar
                name="calendar"
                label="Calendar component"
                type={FieldType.Calendar}
              />
              <Checkbox
                name="Checkbox"
                label="Checkbox component"
                type={FieldType.Calendar}
              />
              <Input
                name="accountNumber"
                label="Account number *"
                type={FieldType.Input}
                containerClassName="w-96 my-5"
                subType="number"
              />

              <Loading />
              <Modal
                isOpen={isOpen}
                onClose={handleCloseModal}
                modalHeader="Invite new staff"
              >
                Modal component
                <div className="modal-footer bg-gray-50 flex justify-end items-center  rounded-b-md px-6 py-3 border-t border-gray-200  rounded-b-md mt-6">
                  <Button
                    variant="white"
                    onClick={handleCloseModal}
                    className="mr-3"
                  >
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit" disabled={!isValid}>
                    Confirm
                  </Button>
                </div>
              </Modal>
              <Radio
                name="radio"
                label="Radio Component"
                options={[
                  { label: "Limited", value: "limited" },
                  {
                    label: "Unlimited",
                    value: "unlimited",
                  },
                ]}
                type={FieldType.Radio}
              />
              <Sign
                name="signature"
                label="Health declaration signature *"
                type={FieldType.Sign}
                inputClassName="w-3/4"
                signatureName="signatureName"
              />
              <Timer
                duration={500}
                onCompletion={() => {}}
                helperText="Helper text"
              />
              <Button
                variant="secondary"
                size="base"
                className="text-center mt-5"
                onClick={() => {
                  handleDownload(FILE_NAME_MAPPING.EMPLOYEE_HANDBOOK);
                }}
              >
                <Icon name="download" />
                <span className="text-sm leading-5 font-semibold ml-2">
                  Download Employee handbook
                </span>
              </Button>
            </div>
            <div className="mt-5">
              {uploadComponents.map((uploadComp) => (
                <FileUpload {...uploadComp} />
              ))}
            </div>
          </form>
        </FormProvider>
      </div>
      <div className="flex justify-center">
        {Object.keys(ButtonVariants).map((buttonVariant) => (
          <div className="flex flex-col m-4  items-center">
            {Object.keys(ButtonSizes).map((buttonSize) => (
              <Button
                name={`${buttonVariant}-${buttonSize}`}
                label={`${buttonVariant}-${buttonSize}`}
                variant={buttonVariant as keyof typeof ButtonVariants}
                size={buttonSize as keyof typeof ButtonSizes}
                className="my-3"
              />
            ))}
          </div>
        ))}
      </div>

      <div className=" flex items-center mt-5 flex-wrap justify-center mx-auto">
        {icons.map((icon) => (
          <span className="mx-4 flex items-center text-gray-900">
            <span className="mx-2 text-gray-900">{icon}:</span>
            <Icon name={icon} className="text-gray-900" stroke={"#111827"} />
          </span>
        ))}
      </div>

      <div className="modal-footer bg-gray-50 flex justify-between items-center  rounded-b-md px-5 py-3 border-t border-gray-200 w-full mt-3">
        <div className="flex">
          <Button
            variant="icon"
            onClick={() => navigate("/onboarding/staff/step/1")}
            className="flex items-center"
          >
            <Icon name="back" />
            <span className="ml-2 text-gray-500 text-sm mr-4">Back</span>
          </Button>
          <Button variant="primary" type="submit" onClick={() => <></>}>
            Save for later
          </Button>
        </div>
        {
          <Button
            variant="white"
            form="step-4"
            disabled={!isValid}
            type="submit"
          >
            Continue
          </Button>
        }
      </div>
    </div>
  );
}
