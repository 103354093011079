import { GenericResponse, GENERICS, Staff, User } from "api/types";
import {
  addReferencesCheckedBy,
  addReferencesIntroDetails,
  useGetAdminStaffs,
  useGetReferenceCheckComments,
  useGetReferenceDetailQuestions,
} from "api/user";
import React, { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { FormProvider, useForm, UseFormGetValues } from "react-hook-form";
import { QueryClient, useQueryClient } from "react-query";
import { getTodayDate, isBoolean } from "utils/util";
import Button from "./Button";
import Calendar from "./Calendar";
import Checkbox from "./Checkbox";
import { Icon } from "./Icon";
import Modal from "./Modal";
import Notification from "./Notification";
import Radio from "./RadioBox";
import { ReferenceQuestion, ReferenceQuestions } from "./ReferenceQuestions";
import { FieldType, FormData } from "./types";
import { handleGenericResponse, invalidateQueries } from "./utility";

interface ReferenceDetailProps {
  user: User;
  admin?: User;
  className?: string;
  referenceNumber: number;
}

const getReferenceGuide = (
  showReferenceGuide: boolean,
  setShowReferenceGuide: (show: boolean) => void
) => {
  return (
    <Modal
      isOpen={showReferenceGuide}
      onClose={() => setShowReferenceGuide(false)}
      modalHeader=""
      className="!bg-white"
      modalBodyClassName="!mt-2 pb-6 px-6"
      customModalStyles={{ width: "864px" }}
    >
      <h3 className="text-primary-900 font-bold flex justify-start items-center text-gray-900">
        <Icon name="info" className="text-gray-900" />
        <span className="ml-3 text-gray-900 text-sm leading-5 font-semibold">
          Reference checking guide
        </span>
        <Button
          className="absolute right-2 top-2 !rounded-full p-2"
          size="noSize"
          aria-label="Close"
          onClick={() => setShowReferenceGuide(false)}
          variant="gray"
        >
          <Icon name="close" />
        </Button>
      </h3>
      <div className="mt-6 ml-7">
        <h5 className="text-xs leading-4 font-semibold uppercase text-primary-700">
          Step 1
        </h5>
        <p className="mt-1 text-sm leading-5 font-medium text-gray-900">
          Decide what you want to ask the referees
        </p>
        <p className="text-sm leading-5 font-normal text-gray-900">
          Before you contact the referee, you should check what factors you need
          to check first. Are there any particular aspects of their application
          or their interview that you want to verify? Do you have any concerns
          about the applicant’s suitability that the referee may be able to
          provide clarification on?
        </p>
      </div>
      <div className="mt-6 ml-7">
        <h5 className="text-xs leading-4 font-semibold uppercase text-primary-700">
          Step 2
        </h5>
        <p className="mt-1 text-sm leading-5 font-medium text-gray-900">
          Contact the referees
        </p>
        <p className="text-sm leading-5 font-normal text-gray-900">
          Think about the best time to contact the referees. For some referees
          you may need to consider arranging a specific time to talk where they
          won’t be busy and will have time to think about their answers.
        </p>
      </div>
      <div className="mt-6 ml-7">
        <h5 className="text-xs leading-4 font-semibold uppercase text-primary-700">
          Step 3
        </h5>
        <p className="mt-1 text-sm leading-5 font-medium text-gray-900">
          Choose a successful applicant
        </p>
        <p className="text-sm leading-5 font-normal text-gray-900">
          Use the information that you’ve collated during the reference check,
          as well as their interview and application, to help you pick the best
          person for the job.
        </p>
      </div>
    </Modal>
  );
};
interface CategoryMap {
  [key: string]: ReferenceQuestion[];
}

const getReferenceAdmins = (
  showReferenceCheckedBy: boolean,
  setShowReferenceCheckedBy: (show: boolean) => void,
  admins: Staff[],
  getValues: UseFormGetValues<FormData>,
  user: User,
  referenceNumber: number,
  queryClient: QueryClient
) => {
  return (
    <Modal
      isOpen={showReferenceCheckedBy}
      onClose={() => setShowReferenceCheckedBy(false)}
      modalHeader=""
      className="!bg-white"
      modalBodyClassName="!mt-0 pt-2"
      customModalStyles={{ width: "394px", minWidth: "394px" }}
    >
      <h3 className="text-primary-900 font-bold flex justify-start items-center text-gray-900">
        <span className="ml-6 text-gray-900 text-lg leading-6 font-semibold">
          Reference check by
        </span>
        <Button
          className="absolute right-2 top-2 !rounded-full p-2"
          size="noSize"
          aria-label="Close"
          onClick={() => setShowReferenceCheckedBy(false)}
          variant="gray"
        >
          <Icon name="close" />
        </Button>
      </h3>
      <div className="mt-6 pb-6 px-6 space-y-3">
        {admins.map((admin, index) => (
          <Checkbox
            name={`referenceCheckAdmins.${index}`}
            label={`${admin.firstName} ${admin.lastName}`}
            value={admin.id}
            key={admin.id}
            type={FieldType.Checbox}
            defaultValue={
              referenceNumber === 1
                ? (user.staffProfile.referenceCheckAdmins1 || []).indexOf(
                    admin.id
                  ) > -1
                : (user.staffProfile.referenceCheckAdmins2 || []).indexOf(
                    admin.id
                  ) > -1
            }
          />
        ))}
      </div>
      <div className="flex justify-end items-center bg-gray-50 border-t border-gray-200 shadow rounded-b-md px-4 py-3  rounded-b-md space-x-3">
        <Button
          variant="white"
          onClick={() => setShowReferenceCheckedBy(false)}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={async () => {
            const selectedAdmins = (
              getValues("referenceCheckAdmins") || []
            ).filter((admin: string) => admin);
            const response = await addReferencesCheckedBy(
              { referenceCheckAdmins: selectedAdmins },
              user.id,
              referenceNumber
            );
            handleGenericResponse(response as unknown as GenericResponse);
            invalidateQueries(queryClient, [
              `/api/user/admin/profile/${user.id}`,
            ]);
            setShowReferenceCheckedBy(false);
          }}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export const ReferenceDetail: React.FC<ReferenceDetailProps> = ({
  user,
  className,
  admin,
  referenceNumber,
}) => {
  const [showReferenceGuide, setShowReferenceGuide] = useState(false);
  const [showReferenceCheckedBy, setShowReferenceCheckedBy] = useState(false);
  const [noOfQuestions, setNoOfQuestions] = useState(0);
  const [referenceData, setReferenceData] = useState<User>();
  const { data } = useGetReferenceDetailQuestions();
  const { data: allReferenceComments } = useGetReferenceCheckComments(user.id);
  const { data: allAdmins } = useGetAdminStaffs();
  const queryClient = useQueryClient();
  const { getValues, handleSubmit, reset, ...formMethods } = useForm<FormData>({
    shouldUnregister: true,
  });
  const methods = {
    ...formMethods,
    getValues,
    handleSubmit,
    reset,
  };

  const referenceComments = (allReferenceComments || []).filter(
    (comment) => comment.referenceNo === referenceNumber
  );

  useEffect(() => {
    data && setNoOfQuestions(data.length);
  }, [data]);

  useEffect(() => {
    const ref =
      referenceNumber === 1
        ? user.staffProfile.reference1
        : user.staffProfile.reference2;
    setReferenceData({ ...ref });
    reset();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceNumber, reset]);

  const uniqueReferenceQuestionsIds = new Set(
    referenceComments.map((item) => item.refrenceCheckQuestionId)
  );
  const uniqueReferenceQuestionsCount = uniqueReferenceQuestionsIds.size;

  const completionPercentage = (
    (uniqueReferenceQuestionsCount / (noOfQuestions || 1)) *
    100
  ).toFixed(0);
  const onSubmit = async (data: FormData) => {
    const requestPayload: Partial<{
      prepared: boolean;
      goodTime: boolean;
      permissionToProceed: boolean;
      refCheckedAt: string;
    }> = {};

    if (data.prepared) {
      requestPayload.prepared = data.prepared === GENERICS.Yes;
    }
    if (data.goodTime) {
      requestPayload.goodTime = data.goodTime === GENERICS.Yes;
    }
    if (data.permissionToProceed) {
      requestPayload.permissionToProceed =
        data.permissionToProceed === GENERICS.Yes;
    }
    if (data.refCheckedAt) {
      requestPayload.refCheckedAt = data.refCheckedAt;
    }

    const response = await addReferencesIntroDetails(
      requestPayload,
      user.id,
      referenceNumber
    );
    handleGenericResponse(response as unknown as GenericResponse);
    invalidateQueries(queryClient, [`/api/user/admin/profile/${user.id}`]);
  };

  const mapByCategoryString = (data || []).reduce((acc: CategoryMap, obj) => {
    if (!acc[obj.categoryString]) {
      acc[obj.categoryString] = []; // Initialize the array if it doesn't exist
    }
    acc[obj.categoryString].push(obj); // Add the current object to the array
    return acc;
  }, {});

  const isReference1Details = referenceNumber === 1;

  const selectedReferenceChecker = allAdmins
    ?.filter((admin) =>
      isReference1Details
        ? user.staffProfile.referenceCheckAdmins1 &&
          user.staffProfile.referenceCheckAdmins1.indexOf(admin.id) > -1
        : user.staffProfile.referenceCheckAdmins2 &&
          user.staffProfile.referenceCheckAdmins2.indexOf(admin.id) > -1
    )
    .map((admin) => `${admin.firstName} ${admin.lastName}`);

  return (
    <FormProvider {...methods}>
      <form
        id={`referenceIntro${referenceNumber}`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="border-t border-gray-200">
          <div className="space-y-3 p-6 pb-0">
            <div className="flex justify-between items-center">
              <h3 className="text-gray-900 text-base leading-7 font-semibold text-left">
                Reference check introduction
              </h3>
              <Button
                variant="grayTonal"
                className="space-x-2"
                onClick={() => setShowReferenceGuide(true)}
              >
                <Icon name="info" className="!text-gray-600" />
                <span>Reference checking guide</span>
              </Button>
            </div>
            <div className="flex">
              <span className="text-sm leading-5 font-medium text-gray-900 w-2/5 py-3">
                My name is {" " + admin?.firstName + " " + admin?.lastName} and
                I’m calling to conduct a reference check for
                {" " + user.firstName + " " + user.lastName} who is being
                considered for a position at EICARE.
              </span>
              <div className="bg-white shadow rounded-md flex flex-col p-3 ml-7  w-3/5">
                <span className="text-sm leading-5 font-normal text-gray-900">
                  Your details have been provided to me by {user.firstName} and
                  I would first like to check if you are prepared to provide a
                  reference?
                </span>
                <Radio
                  label=""
                  name="prepared"
                  type={FieldType.Radio}
                  defaultValue={
                    referenceData && isBoolean(referenceData.prepared)
                      ? referenceData.prepared
                        ? GENERICS.Yes
                        : GENERICS.No
                      : undefined
                  }
                  options={[
                    { label: GENERICS.Yes, value: GENERICS.Yes },
                    { label: GENERICS.No, value: GENERICS.No },
                  ]}
                />
              </div>
            </div>
            <div className="flex space-y-1">
              <span className="text-sm leading-5 font-medium text-gray-900 w-2/5 py-3">
                The reference check will take approximately 10 minutes to
                complete.
              </span>
              <div className="bg-white shadow rounded-md flex flex-col w-3/5 p-3 ml-7">
                <span className="text-sm leading-5 font-normal text-gray-900">
                  Is this a good time for you? If not, when is a convenient time
                  for us to continue this conversation?
                </span>
                <Radio
                  label=""
                  name="goodTime"
                  defaultValue={
                    referenceData && isBoolean(referenceData.goodTime)
                      ? referenceData.goodTime
                        ? GENERICS.Yes
                        : GENERICS.No
                      : undefined
                  }
                  type={FieldType.Radio}
                  options={[
                    { label: "Proceed", value: GENERICS.Yes },
                    { label: "Call back", value: GENERICS.No },
                  ]}
                />
              </div>
            </div>
            <div className="flex space-y-1">
              <span className="text-sm leading-5 font-medium text-gray-900 w-2/5 py-3">
                Please note that this reference will be used in the overall
                evaluation of the applicant and will affect whether they are
                selected for the job. The information you provide may be given
                to the candidate if requested.
              </span>
              <div className="bg-white shadow rounded-md flex flex-col  p-3 ml-7 w-3/5">
                <span className="text-sm leading-5 font-normal text-gray-900">
                  Do I have your permission to proceed?
                </span>
                <Radio
                  label=""
                  name="permissionToProceed"
                  defaultValue={
                    referenceData &&
                    isBoolean(referenceData.permissionToProceed)
                      ? referenceData.permissionToProceed
                        ? GENERICS.Yes
                        : GENERICS.No
                      : undefined
                  }
                  type={FieldType.Radio}
                  options={[
                    { label: GENERICS.Yes, value: GENERICS.Yes },
                    { label: GENERICS.No, value: GENERICS.No },
                  ]}
                />
              </div>
            </div>
            <div className="flex flex-col space-y-1">
              <span className="text-sm leading-5 font-medium text-gray-900 w-2/5">
                Briefly explain the responsibilities of the job and the factors
                you will be assessing through the reference check.
              </span>
            </div>
            <div className="border-b border-gray-200 pt-3"></div>
          </div>

          <div className="flex space-x-14 p-6">
            <div className="flex flex-col space-y-1">
              <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700 min-w-32">
                Candidate name
              </span>
              <span className="text-sm leading-5 font-medium">{`${user.firstName} ${user.lastName}`}</span>
            </div>
            <div className="flex flex-col space-y-1">
              <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700 min-w-32">
                Reference check date
              </span>
              <Calendar
                name="refCheckedAt"
                defaultValue={
                  referenceData && referenceData.refCheckedAt
                    ? new Date(referenceData.refCheckedAt)
                    : undefined
                }
                type={FieldType.Calendar}
                className="w-64"
                label=""
                minDate={getTodayDate()}
              />
            </div>
            {admin && (
              <div className="flex flex-col space-y-1">
                <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                  Reference check by
                </span>
                {selectedReferenceChecker &&
                selectedReferenceChecker.length > 0 ? (
                  <div className="flex space-x-2 items-center">
                    <span className="text-sm leading-5 font-medium text-gray-900">
                      {selectedReferenceChecker.join(", ")}
                    </span>
                    <Button
                      variant="gray"
                      className="cursor-pointer w-5 h-5 !p-0 !rounded-full"
                      onClick={() => setShowReferenceCheckedBy(true)}
                    >
                      <Icon name="pencil" className="text-gray-400" />
                    </Button>
                  </div>
                ) : (
                  <Button
                    size="xs"
                    className="w-max"
                    onClick={() => setShowReferenceCheckedBy(true)}
                  >
                    Select
                  </Button>
                )}
              </div>
            )}
          </div>
          <div className="flex justify-end items-center bg-gray-50  rounded-b-md px-4 py-3  rounded-b-md space-x-3">
            <Button variant="primary" type="submit">
              Save
            </Button>
          </div>

          <div className="px-6 py-5">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <h3 className="text-base leading-7 font-semibold text-gray-900">
                  Questions
                </h3>
                <Notification
                  type="warning"
                  message="Please ensure to score all questions in order to get an assessment outcome."
                  className="inline-flex"
                />
              </div>
              <div className="flex items-center space-x-2">
                <span className="text-sm leading-5 font-medium text-gray-700">
                  Question completion
                </span>
                <CircularProgressbar
                  value={parseFloat(completionPercentage)}
                  text={`${completionPercentage}%`}
                  className="h-10 w-10 font-bold"
                  styles={{
                    path: {
                      stroke: `#0FACD7`,
                    },
                    trail: {
                      stroke: "#E5E7EB",
                    },
                    text: {
                      fill: "#111827",
                      fontSize: "30px",
                    },
                  }}
                />
              </div>
            </div>
          </div>
          {data && (
            <div>
              <ReferenceQuestions
                questions={mapByCategoryString}
                referenceComments={referenceComments}
                user={user}
                admin={admin}
                referenceNumber={referenceNumber}
              />
            </div>
          )}
          {showReferenceGuide &&
            getReferenceGuide(showReferenceGuide, setShowReferenceGuide)}
          {showReferenceCheckedBy &&
            allAdmins?.length &&
            getReferenceAdmins(
              showReferenceCheckedBy,
              setShowReferenceCheckedBy,
              allAdmins,
              getValues,
              user,
              referenceNumber,
              queryClient
            )}
        </div>
      </form>
    </FormProvider>
  );
};
