import api from "api/api";
import {
  GenericResponse,
  TransformedOrientationData,
  User,
  UserDataChangeLog,
  UserStatus,
} from "api/types";
import {
  addComments,
  deleteComment,
  updateComments,
  useGetAdminStaffs,
  useGetStaffDetail,
  useOccupationToOrientationDataMapping,
  useStaffComments,
  useStaffDataLogs,
  useStaffReviewLogs,
} from "api/user";
import Badge from "components/Badge";
import Button from "components/Button";
import Comments from "components/Comments";
import { Icon } from "components/Icon";
import Modal from "components/Modal";
import { OrientationChecklist } from "components/OrientationChecklist";
import { Option } from "components/Select";
import { getProfileStatusBadgeProps } from "components/StaffDetail";
import Table from "components/Table";
import Tabs from "components/Tabs";
import { FormData } from "components/types";
import { invalidateQueries } from "components/utility";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useLoaderData } from "react-router-dom";
import { Bounce, toast } from "react-toastify";
import { StepNames, useEditableForm } from "utils/staffDetail";
import {
  capitalizeFirstLetter,
  getFormattedDate,
  transformToOrientationData,
} from "utils/util";
import {
  columnsAccountHistory,
  useProfileOverviewContent,
} from "utils/viewStaffDetailUtility";
import ApplicationMenu from "./dashboard/Menu";

export default function ViewStaffDetail() {
  const {
    user: masterUserData,
    admin,
    isSuperAdmin,
  } = useLoaderData() as {
    user: User;
    admin: User;
    isSuperAdmin: boolean;
  };
  const { data: user } = useGetStaffDetail(masterUserData.id);
  const { data: reviewData } = useStaffReviewLogs(masterUserData.id);
  const { data: accountHistoryData } = useStaffDataLogs(masterUserData.id);
  const formMethods = useForm<FormData>();
  const formMethods1 = useForm<FormData>();
  const [isOpen, setIsOpen] = useState(false);
  const [stepName, setStepName] = useState<StepNames>();
  const [adminStaffOptions, setAdminStaffOptions] = useState<Option[]>([]);
  const [orientationData, setOrientationData] =
    useState<TransformedOrientationData>();
  const { data, isFetching } = useGetAdminStaffs();
  const queryClient = useQueryClient();
  const { data: userComments } = useStaffComments(masterUserData.id);
  const { data: occupationToOrientationDataMapping } =
    useOccupationToOrientationDataMapping();

  useEffect(() => {
    if (data) {
      const adminStaffOptions = data.map((admin) => ({
        label: admin.username,
        value: admin.id,
      }));
      setAdminStaffOptions(adminStaffOptions);
    }
  }, [data, isFetching]);
  useEffect(() => {
    if (occupationToOrientationDataMapping) {
      const transformedData = transformToOrientationData(
        occupationToOrientationDataMapping
      );
      const occupationData = Object.keys(transformedData).find(
        (item) =>
          transformedData[item].description ===
          masterUserData.staffProfile.occupation
      );
      if (occupationData) {
        const dataForOccupation = transformedData[occupationData];
        setOrientationData({ ...dataForOccupation });
      }
    }
  }, [
    occupationToOrientationDataMapping,
    masterUserData.staffProfile.occupation,
  ]);

  useEffect(() => {
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
  }, []);

  const handleCloseModal = () => {
    setIsOpen(false);
    setStepName(undefined);
    formMethods.reset();
    invalidateQueries(queryClient, [
      `/api/user/admin/profile/${masterUserData.id}`,
      `/api/user/admin/staff/review-logs/${masterUserData.id}`,
      `/api/user/admin/staff/user-data-logs/${masterUserData.id}`,
      `/api/user/admin/staff`,
    ]);
  };

  const onEditClick = (stepName: StepNames) => {
    setStepName(stepName);
    setIsOpen(true);
  };
  const reInviteStaff = async () => {
    const data = { email: user?.email, baseURL: window.location.origin };
    const response = (await api.put<GenericResponse>(
      "/api/user/admin/staff/re-invite",
      data
    )) as GenericResponse;
    if (response.statusText && response.status !== 200) {
      toast.error("Error in Re-inviting", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        icon: <Icon name="error" />,
      });
      return;
    }
    toast.success("Re-invite successfully sent", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      icon: <Icon name="check" />,
    });
  };

  const tabs = [
    {
      label: "Profile overview",
      content: useProfileOverviewContent(
        user ? { ...user } : { ...masterUserData },
        onEditClick,
        adminStaffOptions,
        admin,
        isSuperAdmin,
        handleCloseModal,
        reviewData || []
      ),
    },
    {
      label: "Orientation",
      content: orientationData && (
        <OrientationChecklist
          user={user ? { ...user } : { ...masterUserData }}
          admin={admin}
          orientationData={orientationData}
        />
      ),
    },
    {
      label: "Account History",
      content: (
        <div>
          <h3 className="text-xl leading-7 font-semibold text-gray-900 mt-8 mb-10">
            Activity log
          </h3>
          {accountHistoryData && accountHistoryData.length > 0 ? (
            <Table<UserDataChangeLog>
              data={accountHistoryData || []}
              columns={columnsAccountHistory}
              containerClassName="mt-4"
            />
          ) : (
            <>No Data</>
          )}
        </div>
      ),
    },
    {
      label: "Comments",
      content: user && (
        <div className="px-6 py-5  bg-white shadow rounded-md mt-8 mb-11">
          <div className="flex space-x-6 items-center">
            <h3 className="text-lg leading-6 font-semibold text-gray-900">
              Comments
            </h3>
            <div className="rounded-md">
              <div className="flex items-center space-x-2">
                <span className="">
                  <Icon name="info" height={16} width={16} />
                </span>
                <p className="text-sm leading-5 font-medium text-primary-800 inline-flex">
                  You can edit/delete your comment within 5 minutes of creating
                  it. You will not be able to edit/delete after this time
                  period.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full max-h-96 overflow-auto py-2 mt-4">
            <Comments
              admin={admin}
              data={userComments}
              queryInvalidateFn={() => {
                invalidateQueries(queryClient, [
                  `/api/user/admin/staff/comments/${user.id}`,
                ]);
              }}
              addComments={async (newComment: string) =>
                await addComments({ comment: newComment }, user.id)
              }
              updateComments={async (content: string) =>
                await updateComments({ comment: content }, user.id)
              }
              deleteComments={async (id: number) => await deleteComment(id)}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <ApplicationMenu />
      <header className="mx-auto max-w-7xl ">
        <div className="font-bold leading-tight tracking-tight text-gray-900 border-b border-gray-300 py-5 flex justify-between">
          <div className="flex space-x-2 items-center">
            <h1 className="text-3xl flex-1">
              {masterUserData.firstName} {masterUserData.lastName || "User"}'s
              Profile
            </h1>
            {user && <Badge {...getProfileStatusBadgeProps(user.status)} />}
          </div>
          <div className="flex w-2/5 justify-end space-x-3">
            {user?.status === UserStatus.Invited && (
              <Button onClick={() => reInviteStaff()} variant="primary">
                Re-invite
              </Button>
            )}
            <div className="flex flex-col items-start">
              <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                Account created date
              </span>
              <span className="text-sm leading-5 font-medium text-gray-900">
                {getFormattedDate(new Date(masterUserData.createdAt))}
              </span>
            </div>
            <div className="flex flex-col items-start">
              <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                Account created by
              </span>
              <span className="text-sm leading-5 font-medium text-gray-900">
                {capitalizeFirstLetter(masterUserData.createdBy?.username)}
              </span>
            </div>
          </div>
        </div>
      </header>
      <FormProvider {...formMethods1}>
        <div className="mx-auto max-w-7xl  mt-4">
          <Tabs tabs={tabs} />
        </div>
      </FormProvider>
      <FormProvider {...formMethods}>
        <Modal
          isOpen={isOpen}
          onClose={handleCloseModal}
          modalHeader={`Edit ${(stepName || "")?.split("_")?.join(" ").toLowerCase()}`}
          className="large"
          modalBodyClassName="!m-0"
          modalHeaderClassName="!px-6"
        >
          {useEditableForm(
            user || masterUserData,
            stepName,
            formMethods,
            adminStaffOptions,
            handleCloseModal
          )}
          <div className="bg-gray-50 flex justify-end space-x-2 items-center  rounded-b-md px-5 py-3 border-t border-gray-200 w-full mt-3 flex-wrap md:flex-nowrap">
            <Button variant="white" onClick={() => handleCloseModal()}>
              Cancel
            </Button>
            <Button variant="primary" type="submit" form={`edit-${stepName}`}>
              Save
            </Button>
          </div>
        </Modal>
      </FormProvider>
    </div>
  );
}
