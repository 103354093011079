import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { FieldError, useFormContext } from "react-hook-form";
import { getFormattedDate } from "utils/util";
import Button from "./Button";
import { Icon } from "./Icon";
import Input from "./UnControlledInput";
import { FieldType, FormField } from "./types";
import { renderErrorMessage } from "./utility";

export interface SignProps extends FormField {
  name: string;
  children?: React.ReactNode;
  labelClassNames?: string;
  signContaineClassName?: string;
  inputClassName?: string;
  defaultSignature?: string;
  signatureName: string;
  successMessageClassName?: string;
  dataContaineClassName?: string;
  signInputClassName?: string;
}

const Sign: React.FC<SignProps> = ({
  name,
  requiredCondition,
  label,
  className,
  signContaineClassName,
  labelClassNames,
  inputClassName,
  defaultValue,
  defaultSignature,
  isDisabled,
  signatureName,
  isHiglighted,
  successMessageClassName,
  dataContaineClassName,
  signInputClassName,
}) => {
  const {
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useFormContext();

  const [signedTime, setSignedTime] = useState("");

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
      setSignedTime(getFormattedDate(new Date(defaultValue as string)));
    }
  }, [defaultValue, name, setValue]);

  useEffect(() => {
    if (defaultSignature) {
      setValue(signatureName, defaultSignature);
    }
  }, [defaultSignature, name, setValue, signatureName]);

  const setUserSign = () => {
    if (!getValues(signatureName)) {
      setError(signatureName, {
        type: "custom",
        message: "Please fill in your full name.",
      });
      return;
    }
    const signedTime = new Date();
    clearErrors([name, signatureName]);
    setValue(name, signedTime);
    setSignedTime(getFormattedDate(signedTime));
  };
  const clearUserSign = () => {
    setValue(name, "");
    setValue(signatureName, "");
    setSignedTime("");
  };
  return (
    <div className={classNames(className)}>
      {label && (
        <label
          className={classNames(
            "block text-sm font-semibold leading-6 text-gray-700",
            labelClassNames
          )}
        >
          {label}
        </label>
      )}
      <Input
        type={FieldType.Input}
        name={name}
        label=""
        containerClassName="!hidden !m-0"
        defaultValue={defaultValue}
        isDisabled={isDisabled || Boolean(signedTime)}
        requiredCondition={requiredCondition}
      />
      <div className={classNames(signContaineClassName)}>
        <div
          className={classNames(
            "flex items-start justify-start w-full",
            dataContaineClassName
          )}
        >
          <div className={classNames("flex items-start justify-start")}>
            <div className={classNames("flex flex-col items-start")}>
              <Input
                name={signatureName}
                type={FieldType.Input}
                label=""
                containerClassName={classNames("!m-0 w-96", signInputClassName)}
                defaultValue={defaultSignature}
                isDisabled={isDisabled || Boolean(signedTime)}
                parentContainerClassName="relative"
                className={classNames(
                  signedTime &&
                    "!text-green-700 disabled:!text-green-700 font-bold ring-green-600",
                  isHiglighted &&
                    !signedTime &&
                    !errors[name] &&
                    "ring-secondary-500",
                  errors[name] && "!ring-red-600 !focus:ring-red-600",
                  signInputClassName
                )}
              >
                {signedTime && (
                  <span className="absolute inset-y-2 right-0 flex items-center pr-3 pointer-events-none">
                    <Icon name="check" />
                  </span>
                )}
              </Input>
              <p
                className={classNames(
                  "text-sm leading-5 mt-1",
                  signedTime
                    ? "font-bold text-green-700"
                    : "font-normal text-gray-500"
                )}
              >
                {signedTime
                  ? "Signed successfully!"
                  : "Sign by typing your full name and clicking the sign button. "}
              </p>
            </div>

            <Button
              size="sm"
              onClick={() => setUserSign()}
              disabled={isDisabled}
              className={classNames("ml-4", signedTime && "hidden")}
            >
              Sign
              <Icon name="pencil" className="ml-2" />
            </Button>
            <Button
              size="sm"
              onClick={() => clearUserSign()}
              disabled={isDisabled}
              className={classNames("mx-4", !signedTime && "hidden")}
            >
              <span className="mr-2">Re-sign</span>
              <Icon name="recycle" className={classNames("ml-2")} />
            </Button>
          </div>

          {!errors[name] && signedTime && (
            <div
              className={classNames("flex flex-col", successMessageClassName)}
            >
              <p className="text-sm leading-5 font-medium text-gray-500">
                Signed date and time:
              </p>
              <p className="text-sm leading-5 font-bold text-gray-900">
                {signedTime}
              </p>
            </div>
          )}
        </div>

        {renderErrorMessage(errors[name] as FieldError)}
      </div>
    </div>
  );
};

export default Sign;
