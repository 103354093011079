// localStorageUtils.ts
export function setExpiry(key: string, duration: number) {
  const expiryTime = new Date().getTime() + duration;
  localStorage.setItem(key, expiryTime.toString());
}

export function getExpiry(key: string): number | null {
  const expiryTime = localStorage.getItem(key);
  if (!expiryTime) return null;
  return parseInt(expiryTime, 10);
}

export function hasExpired(key: string): boolean {
  const expiryTime = getExpiry(key);
  if (!expiryTime) return true;
  return new Date().getTime() > expiryTime;
}
