// src/components/CommentList.tsx
import { User } from "api/types";
import React from "react";
import Comment from "./Comment";
import { Comment as CommentInterface } from "./Comments";

interface CommentListProps {
  comments: CommentInterface[];
  onDelete: (id: string) => void;
  onEdit: (id: string, content: string) => void;
  admin: User;
}

const CommentList: React.FC<CommentListProps> = ({
  comments,
  onDelete,
  onEdit,
  admin,
}) => {
  return (
    <div>
      {comments.map((comment) => (
        <Comment
          key={comment.id}
          id={comment.id}
          commentedBy={comment.commentedBy}
          createdAt={comment.createdAt}
          comment={comment.comment}
          onDelete={onDelete}
          onEdit={onEdit}
          admin={admin}
        />
      ))}
    </div>
  );
};

export default CommentList;
