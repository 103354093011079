export default function Contractors() {
  return (
    <div className="py-1">
      <header className="mx-auto max-w-7xl">
        <div className="font-bold leading-tight tracking-tight text-gray-900 border-b border-gray-200 pt-10 pb-3 flex justify-between">
          <h1 className="text-3xl">Contractors</h1>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-7xl pt-8">
          Page under construction
        </div>
      </main>
    </div>
  );
}
