import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ADMIN_ROLES } from "api/types";
import { useGetUserDetail } from "api/user";
import Badge from "components/Badge";
import Button from "components/Button";
import { Icon, IconName } from "components/Icon";
import Popover from "components/Popover";
import { getAvatarName } from "components/StaffDetail";
import { Fragment } from "react";
import Avatar from "react-avatar";
import { Link, useLocation, useMatch, useNavigate } from "react-router-dom";
import { user as userAPI } from "../../api";

interface MenuProps {
  name: string;
  href: string;
  current: boolean;
  isOnlyAdmin?: boolean;
}

interface ApplicationMenuProps {
  navigationItems?: MenuProps[];
  showMenu?: boolean;
}

export default function ApplicationMenu({
  navigationItems,
  showMenu = true,
}: ApplicationMenuProps) {
  const match = useMatch("/dashboard/:childPath/*");
  const staffChildPath = useMatch("/staff/*");
  const reportsChildPath = useMatch("/reports/*");
  const childPath = match?.params.childPath ?? "/";
  const navigate = useNavigate();
  const location = useLocation();
  const { data: userDetail } = useGetUserDetail();
  const isAdmin =
    userDetail &&
    userDetail.roles?.filter((role) => ADMIN_ROLES.indexOf(role) > -1).length >
      0;
  const navigation = [
    {
      name: "Dashboard",
      href: "/dashboard",
      current: location.pathname === "/dashboard",
    },
    {
      name: "Clients",
      href: "/dashboard/clients",
      current: location.pathname === "/dashboard/clients",
      isOnlyAdmin: true,
    },
    {
      name: "Allied Health Services",
      href: "/dashboard/allied-health-services",
      current: location.pathname === "/dashboard/allied-health-services",
      isOnlyAdmin: true,
    },
    {
      name: "Staff",
      href: isAdmin ? "/dashboard/staff" : "/dashboard/staff/staff-orientation",
      current:
        location.pathname === "/dashboard/staff" ||
        staffChildPath ||
        location.pathname === "/dashboard/expiringDocuments" ||
        childPath === "staff",
      isOnlyAdmin: true,
    },
    {
      name: "Contractors",
      href: "/dashboard/contractors",
      current: location.pathname === "/dashboard/contractors",
      isOnlyAdmin: true,
    },
    {
      name: "Reports",
      href: "/reports/employment-checks-register",
      current: reportsChildPath,
      isOnlyAdmin: true,
    },
    { name: "Help", href: "/dashboard/help", current: childPath === "help" },
  ];

  const logout = async () => {
    try {
      await userAPI.logout();
      window.localStorage.removeItem("eicare_auth");
      navigate("/login");
    } catch (error) {
      console.error("Logout failed", error);
    }
  };
  const userNavigation = [
    { name: "Your Profile", href: "#", icon: "userGrayAndWhite" },
    {
      name: "Logout",
      href: "#",
      onClick: logout,
      icon: "logout",
      className: "border-t border-gray-100",
    },
  ];

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  if (!showMenu) {
    return <></>;
  }

  const getHelpPopoverData = () => {
    return (
      <div>
        <div className="flex flex-col items-start justify-start p-6 border-b border-gray-300">
          <h3 className="text-lg leading-6 font-semibold text-gray-900">
            Need assistance?
          </h3>
          <p className="text-sm leading-5 font-normal text-gray-500">
            Call us between 9am - 5pm Mon - Fri
          </p>
        </div>
        <div className="px-6 pt-6 flex items-start justify-start">
          <Icon name="email" />
          <p className="flex flex-col text-sm leading-5 font-normal text-gray-500 ml-3">
            <span className="text-gray-600 text-sm leading-5 font-semibold tracking-wide uppercase">
              Email:
            </span>
            <span className="text-base leading-6 font-semibold text-gray-900">
              support@eicare.com.au
            </span>
          </p>
        </div>
        <div className="px-6 pb-4 pt-4 flex items-start justify-start">
          <Icon name="phone" />
          <p className="flex flex-col text-sm leading-5 font-normal text-gray-500 ml-3">
            <span className="text-gray-600 text-sm leading-5 font-semibold tracking-wide uppercase">
              Phone:
            </span>
            <span className="text-base leading-6 font-semibold text-gray-900">
              1300 180 888
            </span>
          </p>
        </div>
      </div>
    );
  };

  return (
    <Disclosure as="nav" className="bg-white shadow-sm">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="flex flex-shrink-0 items-center">
                  <Link key={"logo"} to={"/dashboard"}>
                    <Icon name="logo" height={26} width={120} />
                  </Link>
                </div>
                <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-1">
                  {(navigationItems || navigation).map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        item.current
                          ? "border-primary-500 text-gray-900 border-b-2 hover:bg-primary-50"
                          : "border-transparent border-b-2 text-gray-600 hover:bg-gray-50 focus:bg-gray-50 hover:border-gray-400 hover:text-gray-900",
                        "inline-flex items-center px-4 py-1 text-sm font-semibold"
                        /* item.isOnlyAdmin
                          ? isAdmin
                            ? "block"
                            : "hidden"
                          : "block" */
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                <Popover content={getHelpPopoverData()} className="right-0">
                  <div className="relative group">
                    <Button
                      variant="styledIcon"
                      className="!rounded-full !p-2 hover:bg-gray-200"
                    >
                      <Icon name="assistance" />
                    </Button>
                    <Badge
                      message="Help"
                      className="absolute bg-primary-100 top-11 left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 text-sm leading-5 text-primary-900 font-medium opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                    />
                  </div>
                </Popover>
                <Button
                  variant="styledIcon"
                  className="!rounded-full !p-2 hover:bg-gray-200"
                >
                  <Icon name="bell" />
                </Button>

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span>
                      {userDetail && (
                        <Avatar
                          name={getAvatarName(userDetail)}
                          size="40"
                          round
                          textSizeRatio={2.5}
                          className="!h-10 !w-10 !text-base !leading-6 !font-medium !text-primary-900"
                          color="#D1F5FC"
                          fgColor="#194860"
                          src={userDetail ? userDetail.avatar : ""}
                        />
                      )}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-100 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userDetail && (
                        <Menu.Item key="user-info">
                          {({ active }) => (
                            <div
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "flex flex-col px-4 py-5 text-sm text-gray-700 items-center border-b border-gray-100 space-y-1 bg-transparent"
                              )}
                            >
                              {userDetail && (
                                <Avatar
                                  name={getAvatarName(userDetail)}
                                  size="64"
                                  round
                                  textSizeRatio={2.5}
                                  color="#D1F5FC"
                                  fgColor="#111827"
                                  className="!h-16 !w-16 !text-2xl !leading-8 !font-medium !text-primary-900"
                                  src={userDetail ? userDetail.avatar : ""}
                                />
                              )}
                              <span className="text-base leading-6 font-medium text-gray-900 overflow-hidden text-ellipsis whitespace-nowrap w-56 px-4 text-center">
                                {getAvatarName(userDetail)}
                              </span>
                              <span className="text-xs leading-4 font-medium text-gray-600">
                                {userDetail?.staffProfile?.occupation}
                              </span>
                            </div>
                          )}
                        </Menu.Item>
                      )}
                      {userNavigation.map((item, index) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <div
                              className={classNames(
                                "flex items-center justify-start px-4 py-2",
                                item.className || "",
                                "bg-transparent hover:bg-gray-100 active:bg-gray-100",
                                index === userNavigation.length - 1
                                  ? "hover:rounded-b-md"
                                  : ""
                              )}
                            >
                              <Icon
                                name={item.icon as IconName}
                                size={20}
                                className="text-gray-400"
                              />
                              <a
                                href={item.href}
                                onClick={item.onClick}
                                className={classNames(
                                  active ? "bg-gray-100 text-gray-900" : "",
                                  "text-sm leading-5 px-2 font-semibold  text-gray-600 flex-1 "
                                )}
                              >
                                {item.name}
                              </a>
                            </div>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as={Link}
                  to={item.href}
                  className={classNames(
                    item.current
                      ? "border-primary-500 bg-primary-50 text-primary-700"
                      : "border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800",
                    "block border-l-4 py-2 pl-3 pr-4 text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
            <div className="border-t border-gray-200 pb-3 pt-4">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  {userDetail && (
                    <Avatar
                      name={getAvatarName(userDetail)}
                      size="40"
                      round
                      textSizeRatio={2.5}
                      className="!h-10 !w-10 !text-base !leading-6 !font-medium !text-primary-900"
                      color="#D1F5FC"
                      fgColor="#111827"
                      src={userDetail ? userDetail.avatar : ""}
                    />
                  )}
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">
                    {userDetail?.username}
                  </div>
                  <div className="text-sm font-medium text-gray-500">
                    {userDetail?.email}
                  </div>
                </div>
                <button
                  type="button"
                  className="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                >
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-3 space-y-1">
                {userNavigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    onClick={item.onClick}
                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
