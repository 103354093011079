import classNames from "classnames";
import React from "react";
import { useFormContext } from "react-hook-form";
import { FormField } from "./types";

interface TextareaProps extends FormField {
  name: string;
  className?: string;
}

const Textarea: React.FC<TextareaProps> = ({
  name,
  label,
  className,
  isDisabled,
  defaultValue,
  requiredCondition,
  isHiglighted,
}) => {
  const { register } = useFormContext();

  return (
    <div className={`mb-4 ${className}`}>
      {label && (
        <label htmlFor={name} className="block mb-1">
          {label}
        </label>
      )}
      <textarea
        id={name}
        {...register(name, { required: requiredCondition })}
        className={classNames(
          "p-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-primary-400 focus:ring-primary-400 disabled:text-gray-400 disabled:border-gray-300 disabled:text-gray-400 disabled:bg-gray-100",
          isHiglighted && requiredCondition && "ring-secondary-500"
        )}
        disabled={isDisabled}
        defaultValue={defaultValue as string}
      />
    </div>
  );
};

export default Textarea;
