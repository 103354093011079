import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function ForgotPassword() {
  const [username, setUsername] = useState("");
  const [error, setError] = useState<{
    main: string | null;
    username: string | null;
  }>({
    main: null,
    username: null,
  });

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!username || username.trim() === "") {
      setError({ ...error, username: "Username is required" });
      return;
    }

    try {
      //const response = await user.forgotPassword(username);
      console.log("Forgot password action successful");
      setError({ main: null, username: null });
    } catch (error) {
      console.error("Forgot password action failed", error);
    }
  };
  return (
    <>
      <div className="flex min-h-full w-full ">
        <div className="relative hidden w-2/3 flex-2 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="/images/2c13b5620398dc9b37e5fa8e7e83a65a.jpeg"
            alt=""
          />
        </div>
        <div className="flex flex-1 flex-col justify-center px-4 py-12 w-1/3 lg:w-full bg-primary-50">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="min-h-44 w-full lg:h-60 lg:w-auto"
                src="/images/ei-care-logo.png"
                alt="Your Company"
              />
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Forgot password?
              </h2>
              {error.main && (
                <p className="text-red-600 text-sm">{error.main}</p>
              )}
            </div>

            <div className="mt-10">
              <div>
                <form onSubmit={onSubmit} method="POST" className="">
                  <div className="mt-4">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-700"
                    >
                      Username
                    </label>
                    <div className="mt-1">
                      <input
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="block w-full rounded-md border-0 pl-2 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {error.username && (
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="email-error"
                        >
                          {error.username}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="mt-8">
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md bg-primary-400 px-3 py-3 text-sm font-semibold leading-6 text-black align-middle shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        className="mr-2 mt-0.5 h-full"
                      >
                        <path
                          d="M9.16667 13.3333L5.83333 10M5.83333 10L9.16667 6.66668M5.83333 10L17.5 10M13.3333 13.3333V14.1667C13.3333 15.5474 12.214 16.6667 10.8333 16.6667H5C3.61929 16.6667 2.5 15.5474 2.5 14.1667V5.83334C2.5 4.45263 3.61929 3.33334 5 3.33334H10.8333C12.214 3.33334 13.3333 4.45263 13.3333 5.83334V6.66668"
                          stroke="#111827"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <div className="">Submit</div>
                    </button>
                  </div>
                  <div className="flex items-center mt-2">
                    <div className="text-sm leading-6">
                      <Link
                        to="/login"
                        className="font-semibold text-primary-700 hover:text-primary-600"
                      >
                        Back to Login
                      </Link>
                    </div>
                  </div>
                </form>
                <div className="mt-20 text-gray-700 text-xs">
                  ©2023 EICARE All rights reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
