import classNames from "classnames";
import React from "react";
import Button from "./Button";
import { Icon, IconName } from "./Icon";

export interface BadgeProps {
  message: string;
  className?: string;
  size?: number;
  iconName?: IconName;
  iconClassName?: string;
  isIconOnRight?: boolean;
  statusClassName?: string;
  iconClick?: (value: string) => void;
  buttonClassName?: string;
}

const Badge: React.FC<BadgeProps> = ({
  message,
  className,
  size,
  iconName,
  iconClassName,
  isIconOnRight = false,
  statusClassName,
  iconClick,
  buttonClassName,
}: BadgeProps) => {
  return (
    <span
      className={classNames(
        " text-xs rounded-full p-1 flex items-center",
        iconName ? " justify-between" : " justify-center",
        className
      )}
    >
      {iconName && !isIconOnRight && (
        <Button
          variant="icon"
          size="noSize"
          className={classNames(iconClassName, buttonClassName)}
          onClick={() => iconClick && iconClick(message)}
        >
          <Icon
            name={iconName as IconName}
            size={size}
            className={iconClassName}
          />
        </Button>
      )}
      <span
        className={classNames(
          "text-xs leading-4 font-medium ",
          statusClassName,
          iconName && "ml-1 pr-2"
        )}
      >
        {message}
      </span>
      {iconName && isIconOnRight && (
        <Button
          variant="icon"
          size="noSize"
          className={classNames(iconClassName, buttonClassName)}
          onClick={() => iconClick && iconClick(message)}
        >
          <Icon
            name={iconName as IconName}
            size={size}
            className={iconClassName}
          />
        </Button>
      )}
    </span>
  );
};

export default Badge;
