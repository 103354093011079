// src/components/Comments.tsx
import { StaffProfile, User } from "api/types";
import { CommentResponse } from "api/user";
import React, { useEffect, useState } from "react";
import { setExpiry } from "utils/localStorage";
import Button from "./Button";
import { BUTTON_VISIBLE_DURATION } from "./Comment";
import CommentList from "./CommentList";
import { Icon } from "./Icon";
import Modal from "./Modal";
import { handleGenericResponse } from "./utility";

export interface Comment {
  id: string;
  commentedBy: User;
  createdAt?: string;
  comment: string;
  staffProfileId?: number;
  commentedById?: number;
  staffProfile?: StaffProfile;
  staffInterviewQuestionId?: number;
  refrenceCheckQuestionId?: number;
  referenceNo?: number;
  question?: string;
  candidateQuestionAddedBy?: User;
  staffUser?: User;
  staffUserId?: number;
}

interface CommentsProps {
  admin: User;
  data: Comment[] | undefined;
  queryInvalidateFn: () => void;
  addComments: (comment: string) => Promise<CommentResponse>;
  updateComments: (comment: string, id: number) => Promise<CommentResponse>;
  deleteComments: (id: number) => Promise<CommentResponse>;
}

const Comments: React.FC<CommentsProps> = ({
  admin,
  queryInvalidateFn,
  data,
  addComments,
  updateComments,
  deleteComments,
}) => {
  const [newComments, setNewComments] = useState<Comment[]>([]);
  const [newComment, setNewComment] = useState("");
  const [deleteCommentId, setDeleteCommentId] = useState("");
  const [showAddComments, setShowAddComments] = useState(false);
  const [deleteComment, setDeleteComment] = useState(false);

  useEffect(() => {
    data && setNewComments([...data]);
  }, [data]);

  const addComment = async () => {
    if (newComment.trim()) {
      const staffComment = await addComments(newComment);
      const result = handleGenericResponse(staffComment);
      if (result) {
        const BUTTON_KEY = `${staffComment.id}-button_expiry`;
        setNewComment("");
        setShowAddComments(false);
        setExpiry(BUTTON_KEY, BUTTON_VISIBLE_DURATION);
        setNewComments([
          {
            id: `${staffComment.id}`,
            commentedBy:
              staffComment.commentedBy || staffComment.candidateQuestionAddedBy,
            createdAt: staffComment.createdAt,
            comment: staffComment.comment || newComment,
          },
          ...newComments,
        ]);
        queryInvalidateFn();
      }
    }
  };

  const setUserCommentDeleteId = (commentId: string) => {
    setDeleteComment(true);
    setDeleteCommentId(commentId);
  };

  const deleteUserComment = async (id: string) => {
    setNewComments(newComments.filter((comment) => comment.id !== id));
    const response = await deleteComments(parseInt(id));
    handleGenericResponse(response);
    queryInvalidateFn();
  };

  const editComment = async (id: string, content: string) => {
    setNewComments(
      newComments.map((comment) =>
        comment.id === id ? { ...comment, content } : comment
      )
    );

    const response = await updateComments(content, parseInt(id));
    handleGenericResponse(response);
    queryInvalidateFn();
  };

  return (
    <div className="p-4 mx-auto bg-gray-100 rounded-lg">
      <div className="mb-4">
        <textarea
          className="p-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-primary-400 focus:ring-primary-400 disabled:text-gray-400 disabled:border-gray-300 disabled:text-gray-400 disabled:bg-gray-100"
          placeholder="Add a comment..."
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          onFocus={() => setShowAddComments(true)}
          onBlur={() => !newComment && setShowAddComments(false)}
        />
        {showAddComments && (
          <div className="flex justify-start items-center mt-2">
            <Button onClick={addComment} variant="primary" className="mr-3">
              Add Comment
            </Button>
            <Button
              onClick={() => {
                setNewComment("");
                setShowAddComments(false);
              }}
              variant="white"
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
      <CommentList
        comments={newComments}
        onDelete={setUserCommentDeleteId}
        onEdit={editComment}
        admin={admin}
      />
      <Modal
        isOpen={deleteComment}
        onClose={() => setDeleteComment(false)}
        modalHeader=""
        className="!bg-yellow-50"
        modalBodyClassName="!mt-2"
      >
        <h3 className="text-yellow-900 font-bold flex justify-start items-center px-4">
          <Icon name="warning" />
          <span className="ml-3">
            Are you sure you want to delete this comment?
          </span>
        </h3>
        <div className="flex justify-start items-center  rounded-b-md px-8 py-1  rounded-b-md">
          <Button
            variant="text"
            className="text-yellow-800"
            onClick={() => {
              deleteUserComment(deleteCommentId);
              setDeleteComment(false);
            }}
          >
            Yes
          </Button>
          <Button
            variant="text"
            onClick={() => setDeleteComment(false)}
            className="mr-3 text-yellow-800"
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Comments;
