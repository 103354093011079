import classNames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import { Icon } from "./Icon";

interface FormFooterProps {
  saveforLater: Function;
  step: number;
  formId: string;
  isDisabled: boolean;
  isValid?: boolean;
  isDifferentSubmit?: boolean;
  DifferentSubmitButton?: React.ReactNode;
  isSaveForLaterButtonDisabled?: boolean;
  isContinueButtonDisabled?: boolean;
}

const FormFooter: React.FC<FormFooterProps> = ({
  saveforLater,
  step,
  isValid = true,
  isDisabled,
  formId,
  isDifferentSubmit,
  DifferentSubmitButton,
  isSaveForLaterButtonDisabled,
  isContinueButtonDisabled,
}) => {
  const navigate = useNavigate();
  return (
    <div className="modal-footer bg-gray-50 flex justify-between items-center  rounded-b-md px-6 py-3 border-t border-gray-200 w-full flex-wrap md:flex-nowrap">
      <div className="flex flex-wrap md:flex-nowrap">
        {step > 1 && (
          <Button
            variant="icon"
            onClick={() => navigate(`/onboarding/staff/step/${step - 1}`)}
            className="flex items-center"
            size="noSize"
          >
            <Icon name="back" />
            <span className="ml-2 text-gray-800 text-sm mr-4">Back</span>
          </Button>
        )}
        {isDisabled && (
          <Button
            variant="icon"
            disabled={!isDisabled}
            onClick={() => {
              if (step === 10) {
                navigate(`/onboarding/staff/reviewDetails`);
                return;
              }
              navigate(`/onboarding/staff/step/${step + 1}`);
            }}
            className={classNames("flex items-center ")}
            size="noSize"
          >
            <span
              className={classNames(
                "ml-2 text-gray-800 text-sm mr-4",
                !isDisabled && "!text-gray-300"
              )}
            >
              Forward
            </span>
            <span
              className={classNames(
                "animate rotate-180",
                !isDisabled && "text-gray-300"
              )}
            >
              <Icon
                name="back"
                className={classNames(!isDisabled && "text-gray-300")}
              />
            </span>
          </Button>
        )}
      </div>
      <div className="flex flex-wrap md:flex-nowrap space-x-3">
        <Button
          variant="primary"
          onClick={() => saveforLater()}
          disabled={isDisabled || isSaveForLaterButtonDisabled}
        >
          Save for later
        </Button>
        {isDifferentSubmit && DifferentSubmitButton ? (
          <>{DifferentSubmitButton}</>
        ) : (
          <Button
            variant="white"
            form={formId}
            disabled={!isValid || isDisabled || isContinueButtonDisabled}
            type="submit"
          >
            Continue
          </Button>
        )}
      </div>
    </div>
  );
};

export default FormFooter;
