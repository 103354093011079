import useAuthToken from "components/Auth";
import { Loading } from "components/Loading";
import TokenExpiry from "components/TokenExpiry";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import ApplicationMenu from "routes/dashboard/Menu";

export default function Onboarding(props: any) {
  const { isFetching, isValidToken, isError, invitedByFirstName } =
    useAuthToken();
  const [showMenu, setShowMenu] = useState(false);
  if (isFetching) {
    return <Loading />;
  }
  return (
    <>
      <div className="min-h-full">
        {isValidToken && (
          <>
            <ApplicationMenu navigationItems={[]} showMenu={showMenu} />
            <Outlet
              context={{ setShowMenu, isFetching, isValidToken, isError }}
            />
          </>
        )}
        {!isValidToken && (
          <span>
            <TokenExpiry invitedByFirstName={invitedByFirstName} />
          </span>
        )}
      </div>
    </>
  );
}
