import { onboardStaff } from "api/staff";
import Checkbox from "components/Checkbox";
import FileUpload from "components/FileUpload";
import FormFooter from "components/FormFooter";
import GenericAccordion from "components/GenericAccordion";
import Notification, { NotificationProps } from "components/Notification";
import Sign from "components/Sign";
import { FieldType, FormData, StepProps } from "components/types";
import { handleResponse } from "components/utility";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { formatDateWithTime } from "utils/util";

export const getPositionDescriptionBody = () => {
  return (
    <div className="pb-3">
      <div className="flex flex-col">
        <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
          Position purpose
        </span>
        <span className="text-sm leading-5 font-normal text-gray-900">
          To assist clients in performing daily tasks and improve overall 
          independence and wellbeing
        </span>
      </div>

      <div className="flex flex-col mt-6">
        <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
          Main tasks
        </span>
        <p className="text-sm leading-5 font-normal text-gray-900">
          <span>Core objectives include: </span>
          <ul className="list-disc">
            <li className="ml-6">Assist with the daily care of clients</li>
            <li className="ml-6">
              Perform housekeeping duties including cleaning, food preparation
              and shopping
            </li>
            <li className="ml-6">Monitor or administer some medication</li>
            <li className="ml-6">Perform administrative duties</li>
            <li className="ml-6">
              Participate in case reviews to determine the needs and wellbeing
              of the client
            </li>
            <li className="ml-6">
              Improve wellbeing and independence of clients
            </li>
          </ul>
        </p>
        <p className="text-sm leading-5 font-normal text-gray-900">
          The above list is not exhaustive and the role may change to meet the
          overall objectives of the company.
        </p>
      </div>

      <div className="flex flex-col mt-6">
        <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
          Other duties
        </span>
        <span className="text-sm leading-5 font-normal text-gray-900">
          Fulfil other duties as required by management and other department
          personnel as requested/required
        </span>
      </div>

      <div className="w-full flex mt-6">
        <div className="w-1/2">
          <div className="flex flex-col">
            <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
              Required qualities
            </span>
            <p className="text-sm leading-5 font-normal text-gray-900">
              <ul className="list-disc">
                <li className="ml-6">Professional approach</li>
                <li className="ml-6">Ability to work under pressure</li>
                <li className="ml-6">
                  Organisational and time management skills
                </li>
              </ul>
            </p>
          </div>
        </div>
        <div className="w-1/2">
          <div className="flex flex-col">
            <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
              Desired competencies
            </span>
            <p className="text-sm leading-5 font-normal text-gray-900">
              <ul className="list-disc">
                <li className="ml-6">Initiative</li>
                <li className="ml-6">Tenacity</li>
                <li className="ml-6">Strategic thinking</li>
                <li className="ml-6">Positive approach to change</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const getPersonalSpecificationBody = () => {
  return (
    <div className="pb-3">
      <div className="w-full flex">
        <div className="w-1/2">
          <div className="flex flex-col">
            <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
              Qualifications
            </span>
            <p className="text-sm leading-5 font-normal text-gray-900">
              At least Certificate 3 in Individual Support or Aged Care
            </p>
          </div>
        </div>
        <div className="w-1/2">
          <div className="flex flex-col">
            <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
              Experience
            </span>
            <p className="text-sm leading-5 font-normal text-gray-900">
              Six months of Personal Care experience (desirable)
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col mt-6">
        <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
          Skills & competencies
        </span>
        <p className="text-sm leading-5 font-normal text-gray-900">
          <ul className="list-disc">
            <li className="ml-6">
              Customer service focused: committed to providing exceptional
              customer service across all channels – written, phone and face to
              face
            </li>
            <li className="ml-6">
              Communication: the ability to communicate clearly and concisely,
              varying communication style depending upon the audience
            </li>
            <li className="ml-6">
              Teamwork: willingness to assist and support others as required and
              get on with team members
            </li>
            <li className="ml-6">
              Time management/organisation: accomplish objectives effectively
              within time frame given, and carry out administrative duties
              within portfolio in an efficient and timely manner
            </li>
          </ul>
        </p>
      </div>

      <div className="w-full flex mt-6">
        <div className="w-1/2">
          <div className="flex flex-col">
            <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
              Personal attributes
            </span>
            <p className="text-sm leading-5 font-normal text-gray-900">
              <ul className="list-disc">
                <li className="ml-6">Professional approach (essential)</li>
                <li className="ml-6">Confident manner (essential)</li>
                <li className="ml-6">
                  Positive approach to change (essential)
                </li>
              </ul>
            </p>
          </div>
        </div>
        <div className="w-1/2">
          <div className="flex flex-col">
            <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
              Other
            </span>
            <p className="text-sm leading-5 font-normal text-gray-900">
              <ul className="list-disc">
                <li className="ml-6">Current Police Check (essential)</li>
                <li className="ml-6">
                  Experience working with elderly (desirable)
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      <p className="text-sm leading-5 font-medium text-gray-900 mt-6">
        This job description serves to illustrate the scope and responsibilities
        of the post and is not intended to be an exhaustive list of duties. You
        will be expected to perform other job related tasks requested by
        management and as necessitated by the development of this role and the
        development of the business.
      </p>
    </div>
  );
};

const FIELDS_TO_REMOVE = ["calendarMonth", "calendarYear"];

export default function Step10({ isCompleted, user, isToggled }: StepProps) {
  const { formState, handleSubmit, setError, getValues, ...formMethods } =
    useForm<FormData>();
  const { staffProfile } = user;
  const navigate = useNavigate();
  const [response, setResponse] = useState<NotificationProps | null>(null);
  const [shouldValidate, setShouldValidate] = useState<boolean>(true);
  const methods = {
    ...formMethods,
    formState,
    handleSubmit,
    setError,
    getValues,
  };

  const onSubmit = async (data: FormData) => {
    setResponse(null);
    data.contractRecievedDate = staffProfile.contractReceivedDate || new Date();
    const response = await onboardStaff(10, data, "submit", FIELDS_TO_REMOVE);
    const isSuccess = await handleResponse(response, setError, setResponse);
    isSuccess && navigate("/onboarding/staff/reviewDetails");
  };

  const handleButtonClick = async () => {
    setShouldValidate(false);
    setTimeout(() => handleSubmit(saveForLater)(), 100);
  };

  const saveForLater = async (data: FormData) => {
    setResponse(null);
    data.contractRecievedDate = staffProfile.contractReceivedDate || new Date();
    const response = await onboardStaff(10, data, "save", FIELDS_TO_REMOVE);
    setShouldValidate(true);
    await handleResponse(response, setError, setResponse);
  };

  const getAdditionalFieldsForPersonalCareSupportWorker = () => {
    return (
      <>
        {getPersonalCareDescription()}
        {getDataForStaffToFill()}

        {staffProfile.contractAdminSignature &&
        staffProfile.contractAdminSignedAt ? (
          <div className="flex mt-6 space-x-12">
            <div className="flex space-x-1 items-center">
              <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                Manager signature:
              </span>
              <span className="text-sm leading-5 font-medium text-gray-900">
                {user.staffProfile.contractAdminSignature
                  ? user.staffProfile.contractAdminSignature
                  : "N/A"}
              </span>
            </div>
            <div className="flex space-x-1 items-center">
              <span className="text-sm leading-5 font-bold text-gray-500">
                Signed date and time:
              </span>
              <span className="text-sm leading-5 font-bold text-gray-900">
                {user.staffProfile.contractAdminSignedAt
                  ? formatDateWithTime(
                      new Date(user.staffProfile.contractAdminSignedAt)
                    )
                  : "N/A"}
              </span>
            </div>
          </div>
        ) : (
          <>
            <div className="flex flex-col mt-6">
              <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                Manager signature *
              </span>
              <span className="text-sm leading-5 font-medium text-gray-900">
                Manager has not signed yet!
              </span>
            </div>
            <Notification
              type="warningExclamation"
              message="Note: Once manager signs, you'll be able to click the continue button to finalise profile setup. Please save your progress below."
              className="!m-0 !inline-flex w-max !mt-6"
              iconClassName="text-yellow-500"
            />
          </>
        )}
      </>
    );
  };

  const getPersonalCareDescription = () => {
    return (
      <div className="py-6">
        <h4 className="text-base leading-7 font-semibold text-gray-900">
          Job Description
        </h4>
        <div className="flex space-x-12 pt-3">
          <div className="flex flex-col">
            <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
              Job title
            </span>
            <span className="text-sm leading-5 font-medium text-gray-900">
              Personal Care Worker
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
              Reports to Manager
            </span>
            <span className="text-sm leading-5 font-medium text-gray-900">
              {staffProfile.contractReportsToManager &&
                `${
                  staffProfile.contractReportsToManager.firstName +
                  " " +
                  staffProfile.contractReportsToManager.lastName
                }`}
            </span>
          </div>
        </div>
        <div className="mt-6 px-3 shadow-md">
          <GenericAccordion
            title="Position Description"
            children={getPositionDescriptionBody()}
          />
          <GenericAccordion
            title="Personal Specification"
            children={getPersonalSpecificationBody()}
            isLastRecord
          />
        </div>
      </div>
    );
  };

  const getDataForStaffToFill = () => {
    return (
      <div className="">
        <Notification
          type="warningExclamation"
          message="Note: Click the “Save for later” button below after you acknowledge and sign to notify the Manager to sign."
          className="!m-0 !inline-flex w-max"
          iconClassName="text-yellow-500"
        />

        <div className="mt-6 rounded-md shadow-md">
          <div className="p-6  space-x-6 w-full flex items-start">
            <div className="w-1/2">
              <Checkbox
                name="contractAcknowledged"
                label="Acknowledgement"
                isDisabled={isCompleted}
                defaultValue={staffProfile.contractAcknowledged}
                type={FieldType.Checbox}
                additionalText="I certify that I have read, understood and accept the duties,responsibilities and obligations of my position."
              />
            </div>
            <div className="w-1/2">
              <Sign
                name="contractAcknowledgedAt"
                label="Employee signature *"
                type={FieldType.Sign}
                isDisabled={isCompleted}
                defaultValue={staffProfile.contractAcknowledgedAt}
                defaultSignature={staffProfile.contractAcknowledgedSignature}
                signatureName="contractAcknowledgedSignature"
                dataContaineClassName="flex-col"
                successMessageClassName="!flex-row space-x-1 mt-5"
                signInputClassName="w-105"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="bg-white shadow rounded-md">
      <div className="p-6 text-sm font-medium">
        <div className="flex flex-col ">
          <Notification
            type="warningExclamation"
            message="All mandatory fields must be completed in order to continue to the next step."
            className="!m-0 !inline-flex w-max"
            iconClassName="text-yellow-500"
          />
          {response ? (
            <Notification
              type={response.type}
              message={response.message}
              className="!m-0 !mt-2 !inline-flex w-max"
            />
          ) : (
            <></>
          )}
        </div>
        <FormProvider {...methods}>
          <form id={"save-step10"} onSubmit={handleSubmit(onSubmit)}>
            <div className="py-6">
              <h3 className="text-lg leading-6 font-semibold text-gray-900">
                Please wait to receive your contract from EICARE. Once received,
                sign and upload below.
              </h3>
              <FileUpload
                name="contractSigned"
                label="Contract *"
                type={FieldType.FileUpload}
                requiredCondition={shouldValidate && "This is a required field"}
                isHiglighted={isToggled}
                defaultValue={staffProfile.contractSigned}
                isDisabled={isCompleted}
                isMultipleFiles={false}
                buttonLabel="Upload signed contract"
                fileUploadContainerClassName="pr-1 w-3/4"
              />
            </div>
            {staffProfile.isPersonalCareWorker &&
              getAdditionalFieldsForPersonalCareSupportWorker()}
          </form>
        </FormProvider>
      </div>

      <FormFooter
        saveforLater={handleButtonClick}
        isDisabled={isCompleted}
        isContinueButtonDisabled={
          staffProfile.isPersonalCareWorker &&
          !Boolean(
            staffProfile.contractAdminSignature &&
              staffProfile.contractAdminSignedAt
          )
        }
        formId="save-step10"
        step={10}
      />
    </div>
  );
}
