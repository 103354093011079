// src/components/Confetti.tsx
import React, { useEffect, useState } from "react";

interface ConfettiPiece {
  id: string;
  color: string;
  size: number;
  left: number;
  animationDelay: string;
  animationDuration: string;
}

const svg1Coneftti = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="48.9648"
      y="37.0723"
      width="51.44"
      height="19.87"
      transform="rotate(-160 48.9648 37.0723)"
      fill="#FFB81E"
      fillOpacity="0.85"
    />
  </svg>
);
const svg2Coneftti = (
  <svg
    width="28"
    height="30"
    viewBox="0 0 28 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.78391 29.0612L0.791173 0.745996L27.8093 10.5798L5.78391 29.0612Z"
      fill="#6DDBF3"
      fill-opacity="0.85"
    />
  </svg>
);
const svg3Coneftti = (
  <svg
    width="36"
    height="37"
    viewBox="0 0 36 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3175 36.4913L13.8018 22.6228L0.761713 17.8766L14.1011 14.0516L14.5854 0.183116L22.3453 11.6876L35.6847 7.86263L27.1412 18.7978L34.9011 30.3023L21.861 25.5561L13.3175 36.4913Z"
      fill="#FFE387"
    />
  </svg>
);
const svg4Coneftti = (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="8.94166"
      cy="8.37765"
      r="8.3"
      transform="rotate(-160 8.94166 8.37765)"
      fill="#6DDBF3"
      fill-opacity="0.65"
    />
  </svg>
);
const svg5Coneftti = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="58.1406"
      y="33.4475"
      width="61.44"
      height="12.87"
      fill="#FFE387"
      fill-opacity="0.9"
    />
  </svg>
);
const svg6Coneftti = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="58.1406"
      y="33.4475"
      width="61.44"
      height="12.87"
      fill="#FFB81E"
      fill-opacity="0.9"
    />
  </svg>
);
const svg7Coneftti = (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="8.94166"
      cy="8.37765"
      r="8.3"
      transform="rotate(-160 8.94166 8.37765)"
      fill="#FFB81E"
    />
  </svg>
);

const confettiSvgs = [
  svg1Coneftti,
  svg2Coneftti,
  svg3Coneftti,
  svg4Coneftti,
  svg5Coneftti,
  svg6Coneftti,
  svg7Coneftti,
];

const colors = [
  "#FF6633",
  "#FFB399",
  "#FF33FF",
  "#FFFF99",
  "#00B3E6",
  "#E6B333",
  "#3366E6",
];

const getRandom = (min: number, max: number) =>
  Math.random() * (max - min) + min;

const Confetti: React.FC = () => {
  const [confettiPieces, setConfettiPieces] = useState<ConfettiPiece[]>([]);

  useEffect(() => {
    const pieces = Array.from({ length: 100 }).map((_, index) => ({
      id: "test" + index,
      color: colors[Math.floor(Math.random() * colors.length)],
      size: getRandom(5, 15),
      left: getRandom(0, 100),
      animationDelay: `${getRandom(0, 3)}s`,
      animationDuration: `${getRandom(1, 5)}s`,
    }));

    setConfettiPieces(pieces);
  }, []);

  return (
    <div className="fixed inset-0 overflow-hidden pointer-events-none z-50">
      {confettiPieces.map((piece, index) => (
        <div
          key={piece.id}
          className="absolute opacity-0 animate-confettiFall"
          style={{
            width: `${piece.size}px`,
            height: `${piece.size}px`,
            left: `${piece.left}%`,
            animationDelay: piece.animationDelay,
            "--fall-duration": piece.animationDuration,
            "--shake-duration": "2s",
          }}
        >
          {confettiSvgs[index % 7]}
        </div>
      ))}
    </div>
  );
};

export default Confetti;
