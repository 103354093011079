import Button from "components/Button";
import ReusableForm from "components/Form";
import Modal from "components/Modal";
import Notification, { NotificationProps } from "components/Notification";
import { FieldType, FormData } from "components/types";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { validatePassword } from "utils/util";

interface CreateStaffAccountProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const getFields = (username: string) => [
  {
    name: "firstName",
    label: "First Name",
    type: FieldType.Input,
    placeholder: "John",
    requiredCondition: "This is a required field",
    parentFormContainerClassName:
      "md:w-64 w-full  inline-block align-top md:pr-2",
  },
  {
    name: "lastName",
    label: "Last Name",
    type: FieldType.Input,
    placeholder: "Doe",
    requiredCondition: "This is a required field",
    parentFormContainerClassName: "md:w-64 w-full  inline-block align-top",
  },
  {
    name: "username",
    label: "Username",
    type: FieldType.Input,
    placeholder: "jdoe",
    requiredCondition: "This is a required field",
    className: "w-full",
    parentFormContainerClassName:
      "md:w-64 w-full  inline-block align-top md:pr-2",
    defaultValue: username,
    isDisabled: true,
  },
  {
    name: "password",
    label: "Set Password",
    type: FieldType.Input,
    placeholder:
      "Min. 8 characters, 1 capital letter, 1 number, 1 special character",
    requiredCondition:
      "Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character",
    className: "w-64",
    containerClassName: "w-64",
    isGeneratePassword: true,
    parentContainerClassName: "flex",
    validationFunction: validatePassword,
    validationErrorMessage:
      "Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character",
    additionalValidationProps: {
      minLength: {
        value: 8,
        message:
          "Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character",
      },
    },
    parentFormContainerClassName: "md:w-64 w-full  inline-block align-top",
  },
  {
    name: "email",
    label: "Email",
    type: FieldType.EmailInput,
    placeholder: "Email",
    requiredCondition: "This is a required field",
    className: "w-full",
    parentFormContainerClassName: "mb-6",
    containerClassName: "w-full",
  },
  {
    name: "role",
    label: "User role",
    type: FieldType.Select,
    placeholder: "Select a role",
    options: [
      { label: "Super admin", value: "super_admin" },
      { label: "Administrator", value: "admin" },
      { label: "Normal", value: "normal" },
    ],
    requiredCondition: "This is a required field",
    className: "w-full",
    containerClassName: "w-full",
  },
];

const CreateStaffAccount: React.FC<CreateStaffAccountProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const [response, setResponse] = useState<NotificationProps | null>(null);
  const queryClient = useQueryClient();
  const handleCloseModal = () => {
    setResponse(null);
    setIsOpen(false);
    queryClient.invalidateQueries({ queryKey: [`/api/user/admin/staff`] });
    reset();
  };

  const onSubmit = async (data: FormData) => {
    setResponse(null);
    data = { ...data, baseURL: window.location.origin };
    console.log({ data });
    /* const response = (await api.post<GenericResponse>(
      "/api/user/admin/staff/invite",
      data
    )) as GenericResponse;
    const isSuccess = await handleResponse(response, setError, setResponse);
    isSuccess &&
      setResponse({ type: "success", message: "Invitation sent successfully" }); */
  };

  const { reset, formState, setError, watch, ...formMethods } =
    useForm<FormData>();
  const { isValid } = formState;
  const [username, setUsername] = useState("");
  const watchedUsername = watch(["firstName", "lastName"]);
  useEffect(() => {
    if (
      watchedUsername.length >= 2 &&
      watchedUsername[0] &&
      watchedUsername[1]
    ) {
      setUsername(watchedUsername[0][0] + watchedUsername[1]);
    }
  }, [watchedUsername]);

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={handleCloseModal}
        modalHeader="Create Staff Account"
        className="setWidth"
      >
        {response && (
          <Notification type={response.type} message={response.message} />
        )}
        <ReusableForm
          fields={getFields(username)}
          onSubmit={onSubmit}
          id="hook-form"
          formClassName="m-4 px-3"
          formMethods={{
            ...formMethods,
            reset,
            formState,
            setError,
            watch,
          }}
        />
        <div className="modal-footer bg-gray-50 flex justify-end items-center  rounded-b-md px-6 py-2.5 border-t border-gray-200  rounded-b-md mt-6">
          <Button variant="white" onClick={handleCloseModal} className="mr-3">
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            form="hook-form"
            disabled={!isValid}
          >
            Create account
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default CreateStaffAccount;
