import axios from "axios";

export const baseURL =
  process.env.REACT_APP_BASE_URL?.trim() || "http://localhost:3001";
axios.defaults.baseURL = baseURL;

export const getToken = () => {
  const data = localStorage.getItem("eicare_auth");
  if (data) {
    const authentication = JSON.parse(data);
    return authentication.session ? authentication.session : "";
  }
  return "invalid token ";
};
export const getAuthHeader = (passedToken: string | null = null) => ({
  headers: { Authorization: `Bearer ${passedToken || getToken()}` },
});

export * as user from "./user";
