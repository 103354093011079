import api from "api/api";
import { GenericResponse } from "api/types";
import Button from "components/Button";
import ReusableForm from "components/Form";
import Modal from "components/Modal";
import Notification, { NotificationProps } from "components/Notification";
import { FieldType, FormData } from "components/types";
import { handleResponse } from "components/utility";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

interface InviteStaffProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isSuperAdmin: boolean;
}

export const OccupationOptions = [
  { label: "Nurse", value: "Nurse" },
  { label: "Care Coordinator", value: "Care Coordinator" },
  { label: "Admissions Officer", value: "Admissions Officer" },
  { label: "Client Liaison Officer", value: "Client Liaison Officer" },
  { label: "Quality Officer", value: "Quality Officer" },
  { label: "Payroll", value: "Payroll" },
  { label: "Rostering", value: "Rostering" },
  { label: "Casual Staff", value: "Casual Staff" },
  { label: "Customer Service", value: "Customer Service" },
  { label: "HR", value: "HR" },
  {
    label: "Support Worker (Domestic Assistance)",
    value: "Support Worker (Domestic Assistance)",
  },
  {
    label: "Support Worker (Personal Care)",
    value: "Support Worker (Personal Care)",
  },
];

const SuperAdminRoleInviteOptions = [
  { label: "Super admin", value: "super_admin" },
  { label: "Administrator", value: "admin" },
  { label: "Normal", value: "normal" },
];

const AdminRoleInviteOptions = [
  { label: "Administrator", value: "admin" },
  { label: "Normal", value: "normal" },
];

const InviteStaff: React.FC<InviteStaffProps> = ({
  isOpen,
  setIsOpen,
  isSuperAdmin,
}) => {
  const [response, setResponse] = useState<NotificationProps | null>(null);
  const queryClient = useQueryClient();
  const fields = [
    {
      name: "email",
      label: "Email",
      type: FieldType.EmailInput,
      placeholder: "Email",
      requiredCondition: "This is a required field",
      className: "w-full",
      parentFormContainerClassName: "mb-6",
    },
    {
      name: "occupation",
      label: "Occupation",
      type: FieldType.Select,
      placeholder: "Select an occupation",
      options: OccupationOptions,
      requiredCondition: "This is a required field",
      className: "w-full",
      containerClassName: "w-full",
      parentFormContainerClassName: "mb-6",
    },
    {
      name: "role",
      label: "User role",
      type: FieldType.Select,
      placeholder: "Select a role",
      options: isSuperAdmin
        ? SuperAdminRoleInviteOptions
        : AdminRoleInviteOptions,
      requiredCondition: "This is a required field",
      className: "w-full",
      containerClassName: "w-full",
      hideSearch: true,
    },
  ];
  const handleCloseModal = () => {
    setResponse(null);
    setIsOpen(false);
    reset();
    /* queryClient.invalidateQueries({ queryKey: [`/api/user/admin/staff`] });
     */
  };

  const onSubmit = async (data: FormData) => {
    setResponse(null);
    data = { ...data, baseURL: window.location.origin };
    const response = (await api.post<GenericResponse>(
      "/api/user/admin/staff/invite",
      data
    )) as GenericResponse;
    const isSuccess = await handleResponse(response, setError, setResponse);
    if (isSuccess) {
      setResponse({ type: "success", message: "Invitation sent successfully" });
      queryClient.invalidateQueries({ queryKey: [`/api/user/admin/staff`] });
    }
  };

  const { reset, formState, setError, ...formMethods } = useForm<FormData>();
  const { isValid } = formState;

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={handleCloseModal}
        modalHeader="Invite new staff"
      >
        {response && (
          <Notification type={response.type} message={response.message} />
        )}
        <ReusableForm
          fields={fields}
          onSubmit={onSubmit}
          id="hook-form"
          formClassName="my-4 px-5"
          formMethods={{ ...formMethods, reset, formState, setError }}
        />
        <div className="modal-footer bg-gray-50 flex justify-end items-center  rounded-b-md px-6 py-2.5 border-t border-gray-200  rounded-b-md mt-6">
          <Button variant="white" onClick={handleCloseModal} className="mr-3">
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            form="hook-form"
            disabled={!isValid}
          >
            Send Invitation
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default InviteStaff;
