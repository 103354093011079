import { User } from "api/types";
import {
  addReferenceComments,
  deleteReferenceComment,
  updateReferenceComments,
} from "api/user";
import { default as classNames, default as cn } from "classnames";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import Badge from "./Badge";
import Button from "./Button";
import Comments, { Comment } from "./Comments";
import { Icon } from "./Icon";
import { FormData } from "./types";
import { invalidateQueries } from "./utility";

export interface ReferenceQuestion {
  id: number;
  question: string;
  archivedAt: string;
  createdAt: string;
  updatedAt: string;
  categoryString: string;
  index: number;
}

interface ReferenceQuestionsProps {
  questions: Record<string, ReferenceQuestion[]>;
  className?: string;
  referenceComments: Comment[] | undefined;
  user: User;
  admin?: User;
  referenceNumber: number;
}
interface ReferenceQuestionAccordionProps {
  question: ReferenceQuestion;
  isOpen: boolean;
  onToggle: () => void;
  referenceComments: Comment[] | undefined;
  user: User;
  admin?: User;
  questionNo: number;
  referenceNumber: number;
  totalRecords: number;
}

const ReferenceQuestionAccordion: React.FC<ReferenceQuestionAccordionProps> = ({
  question,
  isOpen,
  onToggle,
  referenceComments,
  user,
  admin,
  referenceNumber,
  questionNo,
  totalRecords,
}) => {
  const { getValues, ...formMethods } = useForm<FormData>();
  const methods = {
    ...formMethods,
    getValues,
  };
  const queryClient = useQueryClient();
  const comments = (referenceComments || []).filter(
    (comments) => comments.refrenceCheckQuestionId === question.id
  );
  return (
    <div
      className={classNames(
        totalRecords !== questionNo && "border-b border-gray-100"
      )}
    >
      <FormProvider {...methods}>
        <div className="w-full text-left py-3 focus:outline-none">
          <div className="flex justify-between items-center">
            <div
              className="flex justify-start items-center space-x-1 cursor-pointer flex-1"
              onClick={onToggle}
            >
              <div className="mr-1">
                <Button
                  variant="text"
                  size="noSize"
                  className="hover:rounded-full focus:rounded-full p-2"
                >
                  {isOpen ? (
                    <Icon
                      name="downChevron"
                      className="rotate-180 text-primary-800"
                    />
                  ) : (
                    <Icon name="downChevron" className="text-primary-800" />
                  )}
                </Button>
              </div>

              <Badge
                message={questionNo.toString()}
                className="bg-primary-50  h-8 w-8 "
                statusClassName="!text-sm text-primary-900 font-semibold"
              />
              <p className="text-base leading-6 font-medium text-gray-900 w-4/5">
                {question.question.replace("<%firstName%>", user.firstName)}
              </p>
            </div>
            <div className="flex space-x-5 items-center">
              <div className="relative">
                <Button
                  className="w-8 h-8 border !rounded-full flex justify-center items-center cursor-pointer border-gray-300 !p-0 focus:!outline-offset-0"
                  onClick={onToggle}
                  variant="white"
                >
                  <Icon name="comments" />
                </Button>
                {comments && comments.length > 0 && (
                  <Badge
                    message={comments.length.toString()}
                    className="bg-primary-100 h-6 w-6 absolute -top-3 -right-2 border-2 border-white"
                    statusClassName="text-primary-900 font-semibold !text-sm leading-5 "
                  />
                )}
              </div>
              <Button
                className="w-22"
                size="xs"
                onClick={onToggle}
                variant="white"
              >
                Comment
              </Button>
            </div>
          </div>
        </div>
        {isOpen && admin && (
          <div className="px-4 py-2 text-gray-700">
            <Comments
              admin={admin}
              data={comments}
              queryInvalidateFn={() => {
                invalidateQueries(queryClient, [
                  `/api/user/admin/staff-reference-check-comments/${user.id}`,
                ]);
              }}
              addComments={async (newComment: string) =>
                await addReferenceComments(
                  {
                    comment: newComment,
                    questionId: question.id,
                    referenceNo: referenceNumber,
                  },
                  user.id
                )
              }
              updateComments={async (content: string, commentId: number) =>
                await updateReferenceComments({ comment: content }, commentId)
              }
              deleteComments={async (id: number) =>
                await deleteReferenceComment(id)
              }
            />
          </div>
        )}
      </FormProvider>
    </div>
  );
};

export const ReferenceQuestions: React.FC<ReferenceQuestionsProps> = ({
  questions,
  className,
  referenceComments,
  user,
  admin,
  referenceNumber,
}) => {
  const [openId, setOpenId] = useState<number[]>([]);

  const handleToggle = (id: number) => {
    const index = openId.indexOf(id);
    if (index > -1) {
      openId.splice(index, 1);
    } else {
      openId.push(id);
    }
    setOpenId([...openId]);
  };
  // Variable to track global index
  let globalIndex = 0;

  Object.values(questions).forEach((array) => {
    array.forEach((item) => {
      item.index = globalIndex++; // Assign and increment the global index
    });
  });
  return (
    <div className={cn("bg-white mx-6 pb-6", className)}>
      <div className={cn("shadow rounded-md", className)}>
        {Object.keys(questions).map((item, index) => (
          <div className="p-3 pb-0">
            <h4 className="text-xs leading-4 font-semibold uppercase text-primary-700">
              {item}
            </h4>
            {questions[item].map((refQuestion) => (
              <ReferenceQuestionAccordion
                key={refQuestion.id}
                question={refQuestion}
                isOpen={openId.indexOf(refQuestion.id) > -1}
                onToggle={() => handleToggle(refQuestion.id)}
                referenceComments={referenceComments}
                user={user}
                admin={admin}
                questionNo={refQuestion.index + 1}
                referenceNumber={referenceNumber}
                totalRecords={Object.values(questions).reduce(
                  (acc, array) => acc + array.length,
                  0
                )}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
