import classNames from "classnames";

const Footer = () => {
  return (
    <div
      className={classNames(
        "bg-transparent py-4 px-8 flex items-center justify-center"
      )}
    >
      <p className="text-xs leading-4 font-medium text-gray-400">
        ©2024 EICARE, All rights reserved | 2 Rose Street Clayton VIC, 3168.
      </p>
    </div>
  );
};

export default Footer;
