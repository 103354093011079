import classNames from "classnames";
import { FC, ReactNode } from "react";

interface ParagraphProps {
  children: ReactNode | string;
  className?: string;
}

const Paragraph: FC<ParagraphProps> = ({ children, className }) => {
  return <div className={classNames(className)}>{children}</div>;
};

export default Paragraph;
