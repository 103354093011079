import { ADMIN_ROLES, SUPER_ADMIN_ROLES, User } from "api/types";
import { Outlet, useLoaderData } from "react-router-dom";
import Menu from "./Menu";

export default function Dashboard(props: any) {
  const { user } = useLoaderData() as { user: User };
  const isAdmin =
    user.roles?.filter((role) => ADMIN_ROLES.indexOf(role) > -1).length > 0;
  const isSuperAdmin =
    user.roles?.filter((role) => SUPER_ADMIN_ROLES.indexOf(role) > -1).length >
    0;
  return (
    <>
      <div className="min-h-full">
        <Menu />
        <Outlet context={{ user, isAdmin, isSuperAdmin }} />
      </div>
    </>
  );
}
