import axios from "axios";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import { get, getFileNameFromS3Url } from "utils/util";
import { downloadFile, uploadFile } from "../utils/file";
import Button from "./Button";
import { Icon } from "./Icon";
import Link from "./Link";
import { FormField } from "./types";
import { renderErrorMessage } from "./utility";

interface FileUploadProps extends FormField {
  name: string;
  label: string;
  buttonLabel?: string;
  fileUploadContainerClassName?: string;
  labelClassName?: string;
  isMultipleFiles?: boolean;
  externalLink?: string;
  uploadContainerClassName?: string;
  uploadedFileContainerClassName?: string;
}

interface UploadedFile {
  key: string;
  fileName: string;
}

const DEFAULT_RECORDS = 5;
const MAX_FILES = 10;

const FileUpload: React.FC<FileUploadProps> = ({
  name,
  label,
  placeholder = "or drag and drop",
  buttonLabel,
  requiredCondition,
  fileUploadContainerClassName,
  labelClassName,
  isMultipleFiles,
  defaultValue,
  isDisabled,
  externalLink,
  isHiglighted,
  className,
  uploadContainerClassName,
  uploadedFileContainerClassName,
}) => {
  const {
    control,
    formState: { errors },
    setValue,
    setError,
    trigger,
    watch,
  } = useFormContext();

  const [uploadedFiles, setUploadedFile] = useState<UploadedFile[]>([]);
  const [showAll, setShowAll] = useState(false);
  const [softError, setSoftError] = useState<string>();
  const [visibleRecords, setVisibleRecords] = useState<UploadedFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const stateValue = watch(name);

  useEffect(() => {
    if (defaultValue) {
      const defaultFiles = (
        defaultValue
          ? isMultipleFiles
            ? [...(defaultValue as string[])]
            : [defaultValue as string]
          : []
      ).map(
        (fileKeys) =>
          ({
            fileName: getFileNameFromS3Url(fileKeys),
            key: fileKeys,
          }) as UploadedFile
      );
      setValue(
        name,
        isMultipleFiles
          ? defaultFiles.map((files) => files.key)
          : defaultFiles[0]?.key
      );
      setUploadedFile(defaultFiles);
    }
  }, [defaultValue, isMultipleFiles, name, setValue]);

  useEffect(() => {
    const visibleRecords = showAll
      ? uploadedFiles
      : uploadedFiles.slice(0, DEFAULT_RECORDS);
    setVisibleRecords(visibleRecords);
  }, [showAll, uploadedFiles]);

  const manageUpload = async (file: File) => {
    setSoftError("");
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
    if (uploadedFiles.length >= 1 && !isMultipleFiles) {
      setSoftError("Only 1 file can be uploaded");
      return;
    }
    if (uploadedFiles.length >= MAX_FILES) {
      setSoftError("Maximum 10 files are allowed");
      return;
    }
    if (!allowedTypes.includes(file.type)) {
      setSoftError("Incorrect file type");
      return;
    }
    if (file.size > maxSizeInBytes) {
      setSoftError("File size exceeds the limit of 10MB.");
      return;
    }
    setIsLoading(true);
    try {
      const data = await uploadFile(file, (event: ProgressEvent) => {
        //console.log("progress", event.loaded, event.total);
      });
      const files = [{ fileName: file.name, key: data.key }, ...uploadedFiles];
      const fileValueForSubmission = isMultipleFiles
        ? files.map((file) => file.key)
        : data.key;
      setValue(name, fileValueForSubmission);
      setUploadedFile(files);
      trigger(name);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // AxiosError is the type of error thrown by Axios
      if (axios.isAxiosError(error)) {
        // Handle Axios errors
        if (error.response) {
          // The request was made and the server responded with a status code
          console.error("Server responded with status:", error.response.status);
          console.error("Response data:", error.response.data);
          setError(name, {
            type: "custom",
            message: `Server responded with status:${error.response.status}`,
          });
        } else if (error.request) {
          // The request was made but no response was received
          console.error("No response received:", error.request);
          setError(name, {
            type: "custom",
            message: `No response received:${error.request}`,
          });
        } else {
          // Something happened in setting up the request that triggered an error
          console.error("Error during request setup:", error.message);
          setError(name, {
            type: "custom",
            message: `Error during request setup:${error.message}`,
          });
        }
      } else {
        // Handle non-Axios errors
        console.error("Internal server error:", error);
        setError(name, {
          type: "custom",
          message: `Internal server error`,
        });
      }
    }
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      manageUpload(file);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleBrowseClick = () => {
    // Trigger file input click
    const fileInput = document.getElementById(`${name}-input`);
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = e.target;
    const file = e.target.files && e.target.files[0];
    fileInput.value = "";
    if (file) {
      manageUpload(file);
    }
  };

  const removeFile = (key: string) => {
    const files = [...uploadedFiles.filter((file) => file.key !== key)];
    const fileValueForSubmission = isMultipleFiles
      ? files.map((file) => file.key)
      : undefined;
    setValue(name, fileValueForSubmission);
    setUploadedFile(files);
    trigger(name);
  };

  return (
    <div className={classNames("mt-5", fileUploadContainerClassName)}>
      <div className={classNames(uploadContainerClassName)}>
        {label && (
          <div
            className={classNames(externalLink && "flex items-center w-max")}
          >
            <label
              className={classNames(
                "block text-sm font-semibold leading-6 text-gray-700",
                labelClassName,
                (isLoading || isDisabled) && "!text-gray-400",
                externalLink && "mr-1"
              )}
            >
              {label}
            </label>
            {externalLink && (
              <Button
                variant="gray"
                className="!rounded-full group"
                size="noSize"
              >
                <Link
                  url={externalLink}
                  className="p-2"
                  isIcon
                  iconClassName="text-gray-400 group-hover:text-gray-600"
                />
              </Button>
            )}
          </div>
        )}
        <div
          className={classNames(
            "relative group border-2 border-dashed border-gray-300 rounded px-2 py-4 flex flex-col items-center justify-center cursor-pointer hover:border-primary-500 group-hover:text-primary-500 text-gray-600",
            isDisabled &&
              "border-gray-200 hover:!border-gray-200 cursor-default",
            isLoading &&
              "border-gray-300 hover:!border-gray-300 cursor-default",
            isHiglighted &&
              requiredCondition &&
              !stateValue &&
              "border-secondary-500",
            {
              "!border-red-600": get(errors, name),
              "focus:!border-red-600": get(errors, name),
            },
            className
          )}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          {isLoading && (
            <>
              <div className="absolute top-0 left-0 right-0 bottom-0 bg-white opacity-60 z-40"></div>
              <Icon name="loader" className="absolute z-50" />
            </>
          )}
          <Icon
            name="upload"
            className={classNames(
              "text-gray-400 group-hover:text-primary-500",
              (isLoading || isDisabled) &&
                "!text-gray-200 !group-hover:text-gray-200"
            )}
          />
          <div
            className={classNames(
              "flex justify-between items-center mt-3 group-hover:text-primary-500 text-gray-600",
              (isLoading || isDisabled) &&
                "!text-gray-300 !group-hover:text-gray-300"
            )}
          >
            <Button
              variant="tonal"
              size="xs"
              onClick={handleBrowseClick}
              type="button"
              disabled={isLoading || isDisabled}
            >
              Upload from file
            </Button>
            <p className={classNames("text-sm ml-3 font-semibold")}>
              or drag and drop
            </p>
          </div>
          <Controller
            name={name}
            control={control}
            rules={{ required: requiredCondition }}
            render={({ field }) => (
              <input
                type="file"
                id={`${name}-input`}
                className="hidden"
                onChange={handleFileChange}
                accept=".png, .jpg, .pdf"
                disabled={isLoading || isDisabled}
              />
            )}
          />
          <p
            className={classNames(
              "text-xs mt-3 group-hover:text-primary-500 text-gray-500",
              (isLoading || isDisabled) &&
                "!text-gray-300 !group-hover:text-gray-300"
            )}
          >
            PNG, JPG, PDF up to 10MB
          </p>
        </div>
      </div>
      {renderErrorMessage(get(errors, name) as FieldError)}
      {softError &&
        renderErrorMessage({ message: softError } as unknown as FieldError)}
      {visibleRecords.length > 0 && (
        <div className={classNames("mt-4", uploadedFileContainerClassName)}>
          <label className="block text-sm font-semibold leading-6 text-gray-500 tracking-wider text-xs uppercase">
            Uploaded Files
          </label>
          <div className="w-full">
            {visibleRecords.map((file, index) => (
              <div key={file.key}>
                <div
                  className={classNames(
                    "flex justify-between items-center shadow-sm border border-gray-300 px-3 py-2 rounded-md",
                    index > 0 ? "mt-3" : " mt-0"
                  )}
                >
                  <div className="flex justify-start items-center">
                    <Icon
                      name="document"
                      className={classNames(
                        (isLoading || isDisabled) && "!text-gray-400"
                      )}
                    />
                    <Button
                      variant="icon"
                      size="noSize"
                      onClick={() => downloadFile(file.key)}
                      className={classNames(
                        "text-gray-800 underline text-sm font-bold pl-2 break-all",
                        (isLoading || isDisabled) && "!text-gray-500"
                      )}
                      type="button"
                    >
                      {file.fileName}
                    </Button>
                  </div>
                  <Button
                    variant="errorTonal"
                    size="noSize"
                    onClick={() => removeFile(file.key)}
                    type="button"
                    disabled={isLoading || isDisabled}
                    className="!rounded-full p-2"
                  >
                    <Icon name="deleteIcon" className="!rounded-full" />
                  </Button>
                </div>
              </div>
            ))}
          </div>
          {!showAll && uploadedFiles.length > DEFAULT_RECORDS && (
            <Button
              variant="icon"
              className="flex text-primary-700 font-bold text-sm items-center"
              onClick={() => setShowAll(true)}
            >
              <span>
                View {uploadedFiles.length - DEFAULT_RECORDS} more file(s)
              </span>
              <span className="ml-3">
                <Icon name="downChevron" size={24} />
              </span>
            </Button>
          )}
          {showAll && uploadedFiles.length > DEFAULT_RECORDS && (
            <Button
              variant="icon"
              className="flex text-primary-700 font-bold text-sm"
              onClick={() => setShowAll(false)}
            >
              <span>View less</span>
              <span className="ml-3">
                <Icon name="downChevron" size={24} className="rotate-180" />
              </span>
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(FileUpload);
