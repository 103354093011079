import classNames from "classnames";
import { useState } from "react";
import Button from "./Button";
import { Icon } from "./Icon";

interface GenericAccordionProps {
  title: string;
  children: React.ReactNode;
  isLastRecord?: boolean;
}

const GenericAccordion: React.FC<GenericAccordionProps> = ({
  title,
  children,
  isLastRecord = false,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className={classNames(!isLastRecord && "border-b border-gray-100")}>
      <div className="w-full text-left py-3 focus:outline-none">
        <div className="flex justify-between items-center">
          <div
            className="flex justify-start items-center space-x-1 cursor-pointer"
            onClick={() => setIsOpen(!isOpen)}
          >
            <div className="mr-1">
              <Button
                variant="text"
                size="noSize"
                className="hover:rounded-full focus:rounded-full p-2"
              >
                {isOpen ? (
                  <Icon
                    name="downChevron"
                    className="rotate-180 text-primary-800"
                  />
                ) : (
                  <Icon name="downChevron" className="text-primary-800" />
                )}
              </Button>
            </div>

            <span className="text-base leading-6 font-medium text-gray-900">
              {title}
            </span>
          </div>
        </div>
      </div>
      {isOpen && children}
    </div>
  );
};

export default GenericAccordion;
