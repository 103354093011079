export const topEmailDomains = [
  "gmail.com",
  "yahoo.com",
  "outlook.com",
  "hotmail.com",
  "aol.com",
  "icloud.com",
  "mail.com",
  "yandex.com",
  "protonmail.com",
  "zoho.com",
  "gmx.com",
  "fastmail.com",
  "hey.com",
  "hushmail.com",
  "mail.ru",
  "yahoo.co.uk",
  "live.com",
  "me.com",
  "mac.com",
  "msn.com",
  "comcast.net",
  "verizon.net",
  "att.net",
  "bellsouth.net",
  "btinternet.com",
  "charter.net",
  "shaw.ca",
  "ntlworld.com",
  "virginmedia.com",
  "sbcglobal.net",
  "sky.com",
  "cox.net",
  "earthlink.net",
  "optonline.net",
  "web.de",
  "freenet.de",
  "gmx.de",
  "t-online.de",
  "orange.fr",
  "wanadoo.fr",
  "laposte.net",
  "neuf.fr",
  "sfr.fr",
  "libero.it",
  "virgilio.it",
  "tin.it",
  "alice.it",
  "tiscali.it",
  "email.it",
  "inwind.it",
  "live.it",
  "hotmail.it",
  "outlook.it",
  "yahoo.it",
  "telefonica.net",
  "terra.es",
  "outlook.es",
  "yahoo.es",
  "live.es",
  "hotmail.es",
  "wanadoo.es",
  "orange.es",
  "yahoo.com.mx",
  "live.com.mx",
  "hotmail.com.mx",
  "outlook.com.mx",
  "prodigy.net.mx",
  "uol.com.br",
  "bol.com.br",
  "terra.com.br",
  "globo.com",
  "globomail.com",
  "ig.com.br",
  "oi.com.br",
  "r7.com",
  "zipmail.com.br",
  "pop.com.br",
  "sapo.pt",
  "clix.pt",
  "netcabo.pt",
  "live.co.uk",
  "hotmail.co.uk",
  "outlook.co.uk",
  "yahoo.co.uk",
  "btinternet.com",
  "talktalk.net",
  "virgin.net",
  "blueyonder.co.uk",
  "plus.com",
  "btopenworld.com",
  "ntlworld.com",
  "virginmedia.com",
  "eircom.net",
  "eir.ie",
  "mail.ru",
  "list.ru",
  "bk.ru",
  "inbox.ru",
  "mail.com",
];
