import Button from "components/Button";
import Notification from "components/Notification";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ApplicationMenu from "routes/dashboard/Menu";

export default function NotAuthorised(props: any) {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => navigate("/dashboard"), 3000);
  }, [navigate]);
  return (
    <>
      <div className="min-h-full">
        <ApplicationMenu navigationItems={[]} />
        <main className="mx-auto max-w-7xl ">
          <div className="py-8 mt-8">
            <Notification
              type="error"
              message="You are not authorised to see this page!"
              bodyText="Please contact the System Administrator for further assistance by calling 1300 180 888 or sending an email to support@eicare.com.au between 9am - 5pm Mon - Fri."
              className="py-4 !m-0 w-3/4"
              messageClassName="!text-red-900 -mt-1"
              textClassName="!text-red-800"
            />
            <Button
              variant="primary"
              className="font-bold mt-8"
              onClick={() => navigate("/dashboard")}
            >
              Dashboard
            </Button>
          </div>
        </main>
      </div>
    </>
  );
}
