import { User } from "api/types";
import MultiStepAccordion from "components/MultiStepAccordion";
import { CircularProgressbar } from "react-circular-progressbar";
import { useLoaderData, useParams } from "react-router-dom";
import ApplicationMenu from "routes/dashboard/Menu";

export default function OnBoardingStaff() {
  const { user } = useLoaderData() as { user: User };
  const { step } = useParams<{ step: string }>();
  const currentStep =
    user.staffProfile.onboardingStatus > parseInt(step || "1")
      ? parseInt(step || "1")
      : user.staffProfile.onboardingStatus;
  const totalSteps = 10;
  const completionPercentage =
    ((user.staffProfile.onboardingStatus > 1
      ? user.staffProfile.onboardingStatus - 1
      : 0) /
      totalSteps) *
    100;
  const onBoardingStep = user.staffProfile.onboardingStatus;
  return (
    <>
      <ApplicationMenu navigationItems={[]} />
      <div className="py-1">
        <header className="mx-auto max-w-7xl ">
          <div className="font-bold leading-tight tracking-tight text-gray-900 border-b-2 border-gray-200 py-5 flex justify-between">
            <h1 className="text-3xl">My Profile</h1>
            <div className="flex justify-center items-center">
              <span className="mr-2">Profile creation: </span>
              <CircularProgressbar
                value={completionPercentage}
                text={`${completionPercentage}%`}
                className="h-10 w-10"
                styles={{
                  path: {
                    stroke: `#0FACD7`,
                  },
                  trail: {
                    stroke: "#E5E7EB",
                  },
                  text: {
                    fill: "#111827",
                    fontSize: "30px",
                  },
                }}
              />
            </div>
          </div>
        </header>
        <main className="mb-11">
          <div className="flex mx-auto max-w-7xl pt-5">
            <MultiStepAccordion
              activeStep={currentStep - 1 >= 1 ? currentStep - 1 : 0}
              stepCompleted={user.staffProfile.onboardingStatus}
              user={user}
              onBoardingStep={onBoardingStep - 1 >= 1 ? onBoardingStep - 1 : 0}
            />
          </div>
        </main>
      </div>
    </>
  );
}
