// src/components/Tabs/Tabs.tsx
import classNames from "classnames";
import React, { useState } from "react";
import { Tab, TabProps } from "./Tab";

interface TabsProps {
  tabs: TabProps[];
  defaultActive?: number;
  tabContentContainerClassName?: string;
}

const Tabs: React.FC<TabsProps> = ({
  tabs,
  defaultActive = 0,
  tabContentContainerClassName,
}) => {
  const [activeTab, setActiveTab] = useState(defaultActive);

  return (
    <div className="w-full">
      <div className="flex">
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            {...tab}
            isActive={index === activeTab}
            onClick={() => setActiveTab(index)}
            tabClassName={classNames(
              index === 0 && "rounded-l-md",
              index === tabs.length - 1 && "rounded-r-md"
            )}
          />
        ))}
      </div>
      <div className={classNames(tabContentContainerClassName)}>
        {tabs[activeTab].content}
      </div>
    </div>
  );
};

export default Tabs;
