import classNames from "classnames";
import React from "react";
import Notification from "./Notification";

interface TokenExpiryProps {
  invitedByFirstName: string | undefined;
}

const TokenExpiry: React.FC<TokenExpiryProps> = ({
  invitedByFirstName,
}: TokenExpiryProps) => {
  return (
    <div className={classNames("mx-auto max-w-7xl  p-3 mt-20")}>
      <Notification
        type="warning"
        message={"Link has already expired!"}
        className="ml-0"
      />
      <div className=" pb-5 mt-8">
        <h1 className="text-gray-900 text-xl font-bold">
          Invitation to join the EICARE team!
        </h1>
        <p className="text-gray-900 mt-4 mb-0">
          You have been redirected to this page because you either clicked on
          the “Accept invitation” or “Decline” option in the EICARE invitation
          email sent to you.
        </p>
        <p className="text-gray-900">However these links have now expired.</p>
        <p className="text-gray-900  mt-4">
          If you are still interested in joining the EICARE team, please contact
          our office and request
          {invitedByFirstName ? ` for ${invitedByFirstName} ` : " them "}
          to resend the invitation.
        </p>
        <p className="text-gray-900  mt-4">
          Email/Call us between 9am - 5pm Mon - Fri
        </p>
        <p className="text-gray-900">Email: support@eicare.com.au</p>
        <p className="text-gray-900">Phone: 1300 180 888</p>
      </div>
    </div>
  );
};

export default TokenExpiry;
