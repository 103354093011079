// src/components/WaveIcon.tsx
import React, { useEffect, useState } from "react";

const Wave: React.FC = () => {
  const [isWaving, setIsWaving] = useState(false);

  useEffect(() => {
    setIsWaving(true);
    const timer = setTimeout(() => setIsWaving(false), 1000); // Reset wave after 1 second
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="flex items-center justify-center">
      <div className={`text-5xl ${isWaving ? "animate-waved" : ""}`}>
        <svg
          width="160"
          height="161"
          viewBox="0 0 160 161"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M80 136.756C110.928 136.756 136 111.684 136 80.7559C136 49.8279 110.928 24.7559 80 24.7559C49.0721 24.7559 24 49.8279 24 80.7559C24 111.684 49.0721 136.756 80 136.756ZM105.95 71.7056C108.683 68.9719 108.683 64.5398 105.95 61.8061C103.216 59.0724 98.7839 59.0724 96.0503 61.8061L73 84.8564L63.9497 75.8061C61.2161 73.0724 56.7839 73.0724 54.0503 75.8061C51.3166 78.5398 51.3166 82.9719 54.0503 85.7056L68.0503 99.7056C70.7839 102.439 75.2161 102.439 77.9497 99.7056L105.95 71.7056Z"
            fill="white"
          />
          <path
            d="M80 148.756C117.555 148.756 148 118.311 148 80.7559C148 43.2005 117.555 12.7559 80 12.7559C42.4446 12.7559 12 43.2005 12 80.7559C12 118.311 42.4446 148.756 80 148.756Z"
            stroke="#EDFCFE"
            stroke-opacity="0.2"
            stroke-width="24"
          />
        </svg>
      </div>
    </div>
  );
};

export default Wave;
