import classNames from "classnames";
import React from "react";

interface ToggleProps {
  isChecked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  className?: string;
}

const Toggle: React.FC<ToggleProps> = ({
  isChecked,
  onChange,
  disabled = false,
  className,
}) => {
  return (
    <div
      className={classNames(
        "relative inline-block w-11 h-6 cursor-pointer rounded-full",
        className,
        "focus:outline-offset-2 focus:outline focus:outline-primary-500",
        "active:outline-offset-2 active:outline active:outline-primary-500"
      )}
      onClick={() => !disabled && onChange(!isChecked)}
    >
      <div
        className={classNames(
          "absolute -inset-0 transition duration-200 ease-in-out rounded-full",
          isChecked ? "bg-primary-500" : "bg-gray-200",
          disabled && "bg-gray-300 cursor-not-allowed"
        )}
      ></div>
      <div
        className={classNames(
          "absolute left-0.5 top-0.5 w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full",
          isChecked ? "translate-x-5" : "translate-x-0",
          disabled && "bg-gray-300"
        )}
      ></div>
    </div>
  );
};

export default Toggle;
