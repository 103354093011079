import { user } from "api";
import Button from "components/Button";
import Confetti from "components/Confetti";
import { Icon } from "components/Icon";
import Wave from "components/Wave";
import { useNavigate } from "react-router-dom";

export default function OnBoardingCompletion(props: any) {
  const navigate = useNavigate();
  const logout = async () => {
    try {
      await user.logout();
      window.localStorage.removeItem("eicare_auth");
      navigate("/login");
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  return (
    <div className="bg-primary-500 h-screen ">
      <div className="animate-slideUpOnboarding">
        <main className=" mx-auto max-w-7xl sm:px-6 lg:px-8 min-h-full">
          <div className="flex justify-center items-center h-screen">
            <div className="flex flex-col items-center">
              <span className="mb-24 text-white ">
                <Icon
                  name="logo"
                  className="text-white"
                  height={50}
                  width={230}
                />
              </span>
              <Wave />
              <div className="mt-8 text-white text-center">
                <h1 className=" text-6xl leading-none font-extrabold">
                  Congratulations!
                </h1>
                <p className="text-3xl leading-9 font-bold  mt-3">
                  Thank you for completing your profile.
                </p>
                <p className="text-base leading-6 font-medium mt-4">
                  Our team will now check your details and finalise setting up
                  your staff account.
                </p>
                <p className="text-base leading-6 font-medium mt-4">
                  You'll receive an email letting you know when your EICARE
                  staff account is ready to use.
                </p>
                <p className="">
                  Welcome to EICARE once again. We are excited to have you and
                  looking forward to working with you.
                </p>
              </div>
              <Button variant="white" className="mt-11" onClick={logout}>
                <span className="mr-3">Logout</span> <Icon name="logout" />
              </Button>
              <Confetti />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
