import api from "api/api";
import { GenericResponse } from "api/types";
import Button from "components/Button";
import { Loading } from "components/Loading";
import Notification, { NotificationProps } from "components/Notification";
import { useState } from "react";
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { StaffOnBoardingOutletContext } from "./staff";

export default function DeclineInvitation(props: any) {
  const [response, setResponse] = useState<NotificationProps | null>(null);
  const { isFetching, isValidToken } =
    useOutletContext<StaffOnBoardingOutletContext>();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const navigate = useNavigate();

  const accpetInvite = async () => {
    navigate(`/onboarding/staff?code=${code}`);
  };

  const declineInvite = async () => {
    setResponse(null);
    const response = (await api.delete<GenericResponse>(
      `/api/user/invite-decline/${code}`
    )) as GenericResponse;
    if (response.status && response.status !== 200) {
      setResponse({
        type: "error",
        message: response.data?.message || response.data?.error,
      });
      return;
    }
    setResponse({
      type: "check",
      message: "Invitation declined successfully",
    });
  };
  if (isFetching) {
    return <Loading />;
  }
  return (
    <main className="mx-auto max-w-7xl sm:px-6 lg:px-8 pt-2">
      <div className="min-h-full">
        {isValidToken && (
          <>
            <div className="mt-2 py-4">
              {response && (
                <Notification
                  type={response.type}
                  message={response.message}
                  bodyText={response.bodyText}
                  className="mx-0 p-5 items-start"
                />
              )}
              {!response && (
                <>
                  <div className=" py-5 ">
                    <h1 className="text-gray-900 text-xl font-bold">
                      Invitation to join the EICARE team!
                    </h1>
                    <p className="text-gray-900  mt-4">
                      You have been redirected to this page because you clicked
                      the “Decline” option in the EICARE invitation email sent
                      to you.
                    </p>
                    <p className="text-gray-900">
                      Please click “Decline” button below to confirm this is
                      really what you want to do.
                    </p>
                    <p className="text-gray-900">
                      If you clicked “Decline” by mistake, please proceed and
                      click on the “Accept invitation” button below.
                    </p>
                  </div>
                  <div className="flex justify-start items-center  rounded-b-md mt-4">
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={accpetInvite}
                    >
                      Accept Invitation
                    </Button>
                    <Button
                      variant="textTonal"
                      type="submit"
                      onClick={declineInvite}
                      className="ml-4"
                    >
                      Decline
                    </Button>
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {!isValidToken && (
          <Notification type="error" message="Invalid or expired token" />
        )}
      </div>
    </main>
  );
}
