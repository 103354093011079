import React, { useEffect, useState } from "react";

interface TimerProps {
  duration: number; // Duration of the countdown in seconds
  onCompletion: () => void;
  helperText: string;
}

const Timer: React.FC<TimerProps> = ({
  duration,
  onCompletion,
  helperText,
}) => {
  const [timeLeft, setTimeLeft] = useState(duration);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        if (prevTimeLeft === 0) {
          clearInterval(timer);
          onCompletion();
          return 0;
        }
        return prevTimeLeft - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [duration, onCompletion]);

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className="text-center">
      <h2 className="text-xl text-primary-900 font-bold mb-2 animate-pulse">
        {formatTime(timeLeft)}
      </h2>
      <p className="text-sm text-gray-500">{helperText}</p>
    </div>
  );
};

export default Timer;
