import { simpleGet } from "api/fetchers";
import {
  FailureReasons,
  GenericResponse,
  OccupationData,
  Staff,
  StaffProfile,
  User,
  UserDataChangeLog,
  UserStatus,
} from "api/types";
import axios from "axios";
import { AuthTokenValidationResponse } from "components/Auth";
import { Comment } from "components/Comments";
import { InterviewAnswers } from "components/InterviewDetail";
import { InterviewQuestion } from "components/InterviewQuestions";
import { ReferenceQuestion } from "components/ReferenceQuestions";
import { VehicleAnswers, VehicleQuestion } from "components/VehicleDetail";
import { useQuery } from "react-query";
import { ExpiringDocumentsFilters } from "routes/dashboard/ExpiringDocuments";
import { StaffFilters } from "routes/dashboard/staff/AllStaff";
import { serializeParams } from "utils/util";
import api from "./api";
import { getAuthHeader } from "./index";

export const login = async (username: string, password: string) => {
  const response = await axios.post("/api/user/login", {
    username,
    password,
  });
  return response.data;
};

export const forgotPassword = async (email: string) => {
  const response = await axios.post("/api/user/forgot-password", {
    email,
  });
  return response.data;
};

export function useValidateResetPasswordToken(code: string | null) {
  return useQuery<AuthTokenValidationResponse, Error>(
    `/api/user/reset-password/${code}`,
    simpleGet,
    {
      staleTime: Infinity,
      retry: 0,
    }
  );
}

export const getUser = async () => {
  return (await api.get<User>("/api/user/me")) as User;
};

export const logout = async () => {
  const response = await axios.post("/api/user/logout", {}, getAuthHeader());
  return response.data;
};

export const addStaff = async (data: any) => {
  data = { ...data, baseURL: window.location.origin };
  const authHeader = getAuthHeader();
  console.log("authHeader", authHeader);
  const response = await axios.post("/api/user/staff", data, authHeader);
  return response.data;
};

export function useValidateToken(code: string | null) {
  return useQuery<AuthTokenValidationResponse, Error>(
    `/api/user/invite-code/${code}`,
    simpleGet,
    {
      staleTime: Infinity,
      retry: 0,
    }
  );
}

interface StaffResponse {
  staffs: Staff[];
  count: number;
}
export interface ExpiringDocument {
  user: User;
  createdAt: string;
  expiringAt: string;
  type: string;
  document: string;
  id: number;
  userId: number;
}
interface ExpiringDocumentResponse {
  documents: ExpiringDocument[];
  count: number;
}

const fetchStaff = async (queryParams: string) => {
  return (await api.get<StaffResponse>(
    `/api/user/admin/staff?${queryParams}`
  )) as StaffResponse;
};

export function useGetStaff(
  isRoleAdmin: boolean,
  filters: Partial<StaffFilters>
) {
  if (filters.filter_status) {
    filters["filter_failure_reason"] = filters.filter_status
      .map((status) => {
        return (Object.values(FailureReasons) as string[]).includes(status)
          ? (status as FailureReasons)
          : undefined;
      })
      .filter(Boolean) as string[];
    filters.filter_status = filters.filter_status
      .map((status) => {
        return (Object.values(UserStatus) as string[]).includes(status)
          ? (status as UserStatus)
          : undefined;
      })
      .filter(Boolean) as string[];
    if (!filters.filter_status.length) {
      delete filters.filter_status;
    }
    if (!filters.filter_failure_reason.length) {
      delete filters.filter_failure_reason;
    }
  }
  const queryParams = serializeParams(filters);
  return useQuery<StaffResponse, Error>(
    [`/api/user/admin/staff`, queryParams],
    () => fetchStaff(queryParams),
    {
      staleTime: Infinity,
      enabled: isRoleAdmin,
      retry: 1,
    }
  );
}

export const fetchStaffExpiringDocuments = async (queryParams: string) => {
  return (await api.get<ExpiringDocumentResponse>(
    `/api/user/admin/expiring-documents?${queryParams}`
  )) as ExpiringDocumentResponse;
};

export function useGetStaffExpiringDocuments(
  isRoleAdmin: boolean,
  filters: Partial<ExpiringDocumentsFilters>
) {
  const queryParams = serializeParams(filters);
  return useQuery<ExpiringDocumentResponse, Error>(
    [`/api/user/admin/expiring-documents`, queryParams],
    () => fetchStaffExpiringDocuments(queryParams),
    {
      staleTime: Infinity,
      enabled: isRoleAdmin,
      retry: 1,
    }
  );
}

export function useGetUserDetail() {
  return useQuery<User, Error>(`/api/user/me`, simpleGet, {
    staleTime: Infinity,
    retry: 1,
  });
}

export const getStaffDetails = async () => {
  return (await api.get<StaffProfile>(
    "/api/user/staff-profile"
  )) as StaffProfile;
};

export const getUserDetail = async (userId: string) => {
  return (await api.get<User>(`/api/user/admin/profile/${userId}`)) as User;
};

export function useGetStaffDetail(userId: number) {
  return useQuery<User, Error>(`/api/user/admin/profile/${userId}`, simpleGet, {
    staleTime: Infinity,
    retry: 1,
  });
}

export function useStaffReviewLogs(userId: number) {
  return useQuery<UserDataChangeLog[], Error>(
    `/api/user/admin/staff/review-logs/${userId}`,
    simpleGet,
    {
      staleTime: Infinity,
      retry: 1,
    }
  );
}
export function useStaffDataLogs(userId: number) {
  return useQuery<UserDataChangeLog[], Error>(
    `/api/user/admin/staff/user-data-logs/${userId}`,
    simpleGet,
    {
      staleTime: Infinity,
      retry: 1,
    }
  );
}
export function useStaffComments(userId: number) {
  return useQuery<Comment[], Error>(
    `/api/user/admin/staff/comments/${userId}`,
    simpleGet,
    {
      staleTime: Infinity,
      retry: 1,
    }
  );
}

export function useGetAdminStaffs() {
  return useQuery<Staff[], Error>(`/api/user/admin/all-admins`, simpleGet, {
    staleTime: Infinity,
  });
}

export interface CommentResponse
  extends Omit<Comment, "status">,
    Omit<GenericResponse, "status"> {
  status: number | string;
  staffComment: Comment;
}

export const addComments = async (data: any, staffId: number) => {
  return (await api.post<CommentResponse>(
    `/api/user/admin/staff/section/comment/${staffId}`,
    data
  )) as CommentResponse;
};
export const updateComments = async (data: any, commentId: number) => {
  return (await api.put<CommentResponse>(
    `/api/user/admin/staff/comments/${commentId}`,
    data
  )) as CommentResponse;
};
export const deleteComment = async (commentId: number) => {
  return (await api.delete<CommentResponse>(
    `/api/user/admin/staff/comments/${commentId}`
  )) as CommentResponse;
};

export function useGetInterviewQuestions() {
  return useQuery<InterviewQuestion[], Error>(
    `/api/user/admin/staff-interview-questions?enabled=true`,
    simpleGet,
    {
      staleTime: Infinity,
      retry: 1,
    }
  );
}
export function useGetInterviewComments(staffId: number) {
  return useQuery<Comment[], Error>(
    `/api/user/admin/interview-comments/${staffId}`,
    simpleGet,
    {
      staleTime: Infinity,
      retry: 1,
    }
  );
}
export function useGetInterviewAnswers(staffId: number) {
  return useQuery<InterviewAnswers[], Error>(
    `/api/user/admin/interview-answers/${staffId}`,
    simpleGet,
    {
      staleTime: Infinity,
      retry: 1,
    }
  );
}

export const addInterviewComments = async (data: any, staffId: number) => {
  return (await api.post<CommentResponse>(
    `/api/user/admin/interview-comment/${staffId}`,
    data
  )) as CommentResponse;
};

export const addInterviewAnswers = async (
  data: any,
  staffId: number,
  verb: "post" | "put"
) => {
  return (await api[verb]<CommentResponse>(
    `/api/user/admin/interview-answer/${staffId}`,
    data
  )) as CommentResponse;
};
export const updateInterviewComments = async (data: any, commentId: number) => {
  return (await api.put<CommentResponse>(
    `/api/user/admin/interview-comment/${commentId}`,
    data
  )) as CommentResponse;
};
export const deleteInterviewComment = async (commentId: number) => {
  return (await api.delete<CommentResponse>(
    `/api/user/admin/interview-comment/${commentId}`
  )) as CommentResponse;
};

export const addInterviwers = async (data: any, staffId: number) => {
  return (await api.post<StaffProfile>(
    `/api/user/admin/staff/interviewers/${staffId}`,
    data
  )) as StaffProfile;
};

export const addCandidateQuestions = async (data: any, staffId: number) => {
  return (await api.post<CommentResponse>(
    `/api/user/admin/staff/candidate-question/${staffId}`,
    data
  )) as CommentResponse;
};

export const updateCandidateQuestions = async (
  data: any,
  commentId: number
) => {
  return (await api.put<CommentResponse>(
    `/api/user/admin/staff/candidate-questions/${commentId}`,
    data
  )) as CommentResponse;
};

export const deleteCandidateQuestions = async (commentId: number) => {
  return (await api.delete<CommentResponse>(
    `/api/user/admin/staff/candidate-questions/${commentId}`
  )) as CommentResponse;
};

export function useGetCandidateQuestions(staffId: number) {
  return useQuery<Comment[], Error>(
    `/api/user/admin/staff/candidate-questions/${staffId}`,
    simpleGet,
    {
      staleTime: Infinity,
      retry: 1,
    }
  );
}

export const addCommencementDate = async (data: any, staffId: number) => {
  return (await api.post<StaffProfile>(
    `/api/user/admin/staff/commencement-date/${staffId}`,
    data
  )) as StaffProfile;
};

export function useGetReferenceDetailQuestions() {
  return useQuery<ReferenceQuestion[], Error>(
    `/api/user/admin/staff-reference-check-questions?enabled=true`,
    simpleGet,
    {
      staleTime: Infinity,
      retry: 1,
    }
  );
}

export function useGetReferenceCheckComments(staffId: number) {
  return useQuery<Comment[], Error>(
    `/api/user/admin/staff-reference-check-comments/${staffId}`,
    simpleGet,
    {
      staleTime: Infinity,
      retry: 1,
    }
  );
}

export const addReferenceAnswers = async (
  data: any,
  staffId: number,
  verb: "post" | "put"
) => {
  return (await api[verb]<CommentResponse>(
    `/api/user/admin/interview-answer/${staffId}`,
    data
  )) as CommentResponse;
};
export const addReferenceComments = async (data: any, staffId: number) => {
  return (await api.post<CommentResponse>(
    `/api/user/admin/reference-check-question-comment/${staffId}`,
    data
  )) as CommentResponse;
};
export const updateReferenceComments = async (data: any, commentId: number) => {
  return (await api.put<CommentResponse>(
    `/api/user/admin/reference-check-question-comment/${commentId}`,
    data
  )) as CommentResponse;
};
export const deleteReferenceComment = async (commentId: number) => {
  return (await api.delete<CommentResponse>(
    `/api/user/admin/reference-check-question-comment/${commentId}`
  )) as CommentResponse;
};

export const addReferencesCheckedBy = async (
  data: any,
  staffId: number,
  referenceNo: number
) => {
  return (await api.post<StaffProfile>(
    `/api/user/admin/staff/reference/${referenceNo}/staff/${staffId}/reference-check-admins`,
    data
  )) as StaffProfile;
};

export const addReferencesIntroDetails = async (
  data: any,
  staffId: number,
  referenceNo: number
) => {
  return (await api.put<StaffProfile>(
    `/api/user/admin/staff/reference/${referenceNo}/staff/${staffId}/intro`,
    data
  )) as StaffProfile;
};

export const updateVehicleInspectionDetails = async (
  data: any,
  staffId: number
) => {
  return (await api.put<StaffProfile>(
    `/api/user/admin/staff/vehicle-review/${staffId}`,
    data
  )) as StaffProfile;
};

export function useGetVehicleQuestions() {
  return useQuery<VehicleQuestion[], Error>(
    `/api/user/admin/vehicle-review-question?enabled=true`,
    simpleGet,
    {
      staleTime: Infinity,
      retry: 1,
    }
  );
}

export function useGetVehicleAnswers(staffId: number) {
  return useQuery<VehicleAnswers[], Error>(
    `/api/user/admin/vehicle-review-answer/${staffId}`,
    simpleGet,
    {
      staleTime: Infinity,
      retry: 1,
    }
  );
}

export const addVehicleAnswers = async (
  data: any,
  staffId: number,
  verb: "post" | "put"
) => {
  return (await api[verb]<CommentResponse>(
    `/api/user/admin/vehicle-review-answer/${staffId}`,
    data
  )) as CommentResponse;
};

export const addVehicleReviewers = async (data: any, staffId: number) => {
  return (await api.post<StaffProfile>(
    `/api/user/admin/staff/vehicle-reviewers/${staffId}`,
    data
  )) as StaffProfile;
};

export function useOccupationToOrientationDataMapping() {
  return useQuery<OccupationData, Error>(
    `/api/orientation/admin/orientation-data`,
    simpleGet,
    {
      staleTime: Infinity,
    }
  );
}

export interface StaffProfileGenericResponse
  extends Omit<StaffProfile, "status">,
    Omit<GenericResponse, "status"> {
  status: number | string;
}

export const updateOrientationList = async (data: any, staffId: number) => {
  return (await api.put<StaffProfile>(
    `/api/orientation/admin/orientation-item/${staffId}`,
    data
  )) as StaffProfileGenericResponse;
};
