import { onboardStaff, proceedToContinueApplication } from "api/staff";
import { UserStatus } from "api/types";
import Button from "components/Button";
import Calendar from "components/Calendar";
import FormFooter from "components/FormFooter";
import Notification, { NotificationProps } from "components/Notification";
import { FieldType, FormData, StepProps } from "components/types";
import { handleResponse } from "components/utility";
import { format, toZonedTime } from "date-fns-tz";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { formatTime, getTomorrowDate } from "utils/util";

const FIELDS_TO_REMOVE = ["calendarMonth", "calendarYear"];

export default function Step6({ user, isCompleted, isToggled }: StepProps) {
  const { handleSubmit, formState, setError, ...formMethods } =
    useForm<FormData>();
  const navigate = useNavigate();
  const [response, setResponse] = useState<NotificationProps | null>(null);
  const [interviewDateTime, setInterviewDateTime] = useState<Date>();

  const onSubmit = async (data: FormData) => {
    setResponse(null);
    const interviewDate = new Date(data.interviewDate);
    interviewDate.setHours(data.interviewDateTime.getHours());
    interviewDate.setMinutes(data.interviewDateTime.getMinutes());
    interviewDate.setSeconds(data.interviewDateTime.getSeconds());

    const response = await onboardStaff(
      6,
      { interviewDate: format(interviewDate, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX", { timeZone: 'Australia/Sydney' }) },
      "submit",
      FIELDS_TO_REMOVE
    );
    const isSuccess = await handleResponse(response, setError, setResponse);
    isSuccess && setInterviewDateTime(interviewDate);
  };

  const { staffProfile } = user;

  const hasUserPassed =
    user.status === UserStatus.In_Progress &&
    user.staffProfile?.interviewOutcome &&
    user.staffProfile.onboardingStatus === 6;

  const hasUserPassedAndProgressed =
    user.staffProfile?.interviewOutcome &&
    user.staffProfile.onboardingStatus > 6;

  useEffect(() => {
    if (staffProfile.interviewDate) {
      setInterviewDateTime(toZonedTime(new Date(staffProfile.interviewDate), 'Australia/Sydney'));
    }
  }, [staffProfile.interviewDate]);

  return (
    <div className="bg-white shadow rounded-md pt-6">
      {hasUserPassed || hasUserPassedAndProgressed ? (
        <div className="">
          <Notification
            type="success"
            message="Congratulations! Your interview was successful. Click “Continue” button to go to next step. "
            className="mb-6"
          />
        </div>
      ) : (
        <>
          {response && response.type !== "success" ? (
            <Notification
              type={response.type}
              message={response.message}
              className="mb-6"
            />
          ) : (
            <></>
          )}
          <div className="px-6 pb-6 ">
            <div className="border-b border-gray-300 pb-5">
              <h3 className="text-lg leading-6 font-semibold">
                Select preferred interview Date and Time:
              </h3>
              <p className="text-sm leading-5 font-normal">
                Please select a suitable time between 10am to 4pm on Monday, Thursday or Friday. All times are in {`Melbourne VIC, Australia (${new Date().toLocaleString("en-AU", {
                  timeZone: 'Australia/Victoria',
                  timeZoneName: 'shortOffset'
                })?.split(" ")?.pop()})`}
              </p>
            </div>
            <FormProvider
              {...{ ...formMethods, handleSubmit, formState, setError }}
            >
              <form id={"step-6"} onSubmit={handleSubmit(onSubmit)}>
                <div className="my-6 flex items-center flex-wrap md:flex-nowrap">
                  <h5 className="text-base leading-6 font-medium mr-3">
                    I want to have my interview on
                  </h5>
                  <Calendar
                    name="interviewDate"
                    label=""
                    type={FieldType.Calendar}
                    containerClassName="!my-0 "
                    className="w-64"
                    requiredCondition="This is field is required"
                    isHiglighted={isToggled}
                    minDate={getTomorrowDate()}
                    defaultValue={
                      staffProfile.interviewDate
                        ? interviewDateTime
                        : undefined
                    }
                    isDisabled={Boolean(interviewDateTime) || isCompleted}
                    customDateFormat="EEEE, do MMMM yyyy"
                    showOnlyWeekday={true}
                  />
                  <h5 className="text-base leading-6 font-medium mx-3">at</h5>
                  <Calendar
                    name="interviewDateTime"
                    label=""
                    type={FieldType.Calendar}
                    containerClassName="!my-0 timeOnlyPicker onboardingTimePicker"
                    className="w-48"
                    requiredCondition="This is field is required"
                    isHiglighted={isToggled}
                    placeholder="Select time"
                    showTimeInput={true}
                    minDate={getTomorrowDate()}
                    defaultValue={
                      staffProfile.interviewDate
                        ? interviewDateTime
                        : undefined
                    }
                    isDisabled={Boolean(interviewDateTime) || isCompleted}
                    showWorkingTime={true}
                  />
                  <Button
                    variant="primary"
                    onClick={() => handleSubmit(onSubmit)()}
                    className="ml-2 md:mx-4"
                    disabled={Boolean(interviewDateTime) || isCompleted}
                  >
                    Send request
                  </Button>
                </div>
              </form>
            </FormProvider>
            {interviewDateTime && (
              <div className="mt-3">
                <p className="text-gray-900">
                  <span className="text-sm leading-5 font-medium">
                    Your request has been received. You have requested your
                    interview for
                  </span>
                  <span className="text-sm leading-5 text-primary-600 font-semibold ml-1">
                    {interviewDateTime.toLocaleString("en-AU", {
                      weekday: "long",
                      month: "long",
                      day: "numeric",
                    })}
                  </span>
                  <span className="text-sm leading-5 font-medium mx-1">at</span>
                  <span className="text-sm leading-5 font-semibold text-primary-600 ">
                    {` ${formatTime(new Date(format(interviewDateTime, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX", { timeZone: 'Australia/Sydney' })))} Melbourne VIC, Australia ${new Date(interviewDateTime).toLocaleString("en-AU", {
                      timeZone: 'Australia/Victoria',
                      timeZoneName: 'shortOffset'
                    })?.split(" ")?.pop()}`}
                  </span>
                  <span className="text-sm leading-5 font-medium ml-1">
                    Our staff will contact you for next steps.
                  </span>
                </p>
                <Notification
                  type={"warning"}
                  message="Please note that your preferred day and time for the interview cannot be guaranteed. Depending on the circumstances, we may provide you a different date and time to have your interview."
                  className="my-3 !mx-0 !items-start"
                />
              </div>
            )}
          </div>
        </>
      )}

      <FormFooter
        saveforLater={() => { }}
        isDisabled={isCompleted}
        isSaveForLaterButtonDisabled={true}
        formId="step-6"
        isValid={false}
        step={6}
        isDifferentSubmit={hasUserPassed}
        DifferentSubmitButton={
          <Button
            variant="white"
            size="sm"
            onClick={async () => {
              const result = await proceedToContinueApplication();
              if (result.onboardingStatus === 7) {
                navigate("/onboarding/staff/step/7");
              }
            }}
          >
            Continue
          </Button>
        }
      />
    </div>
  );
}
