import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";
import Button from "./Button";
import { Icon, IconName } from "./Icon";

interface DialogueProps {
  title: string;
  iconName?: string;
  isTitleIcon?: boolean;
  children: React.ReactNode;
  cancelButtonLabel?: string;
  confirmButtonLabel: string;
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  iconElement: React.ReactNode;
  panelClassName?: string;
  confirmIconName?: string;
  cancelIconName?: string;
  containerClassName?: string;
}

const Dialogue: React.FC<DialogueProps> = ({
  title,
  children,
  cancelButtonLabel,
  confirmButtonLabel,
  open,
  handleClose,
  handleConfirm,
  iconElement,
  panelClassName,
  confirmIconName,
  cancelIconName,
  containerClassName,
}) => {
  return (
    <Transition show={open}>
      <Dialog className="relative z-100" onClose={handleClose}>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-100 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  "relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl",
                  panelClassName
                )}
              >
                <div
                  className={classNames(
                    "bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4",
                    containerClassName
                  )}
                >
                  <div className="">
                    {iconElement}
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        {title}
                      </Dialog.Title>
                      <div className="mt-2">{children}</div>
                    </div>
                  </div>
                </div>
                <div className="bg-white px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <Button
                    variant="success"
                    className="w-full sm:w-1/2 my-2 sm:ml-3"
                    onClick={handleConfirm}
                    data-autofocus
                  >
                    {confirmIconName && (
                      <Icon name={confirmIconName as IconName} />
                    )}
                    <span className="ml-3"> {confirmButtonLabel} </span>
                  </Button>
                  <Button
                    variant="white"
                    onClick={handleClose}
                    className="w-full sm:w-1/2 my-2 "
                  >
                    {cancelIconName && (
                      <Icon name={cancelIconName as IconName} />
                    )}
                    <span className="ml-3"> {cancelButtonLabel} </span>
                  </Button>
                  <Button
                    className="absolute right-2 top-2 !rounded-full p-2"
                    size="noSize"
                    aria-label="Close"
                    onClick={handleClose}
                    variant="gray"
                  >
                    <Icon name="close" />
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Dialogue;
