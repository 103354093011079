import {
  personalCareWorkerAcknowledgement,
  personalCareWorkerAssignManager,
} from "api/staff";
import { User } from "api/types";
import { useGetAdminStaffs } from "api/user";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import GenericAccordion from "components/GenericAccordion";
import { Icon } from "components/Icon";
import Modal from "components/Modal";
import Notification, { NotificationProps } from "components/Notification";
import Select, { Option } from "components/Select";
import Sign from "components/Sign";
import { FieldType, FormData } from "components/types";
import { handleResponse } from "components/utility";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLoaderData, useNavigate } from "react-router-dom";
import ApplicationMenu from "routes/dashboard/Menu";
import { formatDateWithTime } from "utils/util";
import {
  getPersonalSpecificationBody,
  getPositionDescriptionBody,
} from "./steps/step10";

export default function PersonalCareWorkerManagerForm() {
  const { user, admin } = useLoaderData() as { user: User; admin: User };
  const { formState, handleSubmit, setError, getValues, ...formMethods } =
    useForm<FormData>();
  const { staffProfile } = user;
  const navigate = useNavigate();
  const [response, setResponse] = useState<NotificationProps | null>(null);
  const methods = {
    ...formMethods,
    formState,
    handleSubmit,
    setError,
    getValues,
  };
  const [adminStaffOptions, setAdminStaffOptions] = useState<Option[]>([]);
  const { data, isFetching } = useGetAdminStaffs();
  const [showConfirmationChangeManager, setShowConfirmationChangeManager] =
    useState(false);
  const [showUpdateButtom, setShowUpdateButtom] = useState(false);

  useEffect(() => {
    if (data) {
      const adminStaffOptions = data.map((admin) => ({
        label: admin.username,
        value: admin.id,
      }));
      setAdminStaffOptions(adminStaffOptions);
    }
  }, [data, isFetching]);

  const getEmployeeData = () => {
    return (
      <div className="space-y-6">
        <div className="flex mt-6 space-x-12">
          <div className="flex space-x-1 items-center">
            <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
              Employee signature:
            </span>
            <span className="text-sm leading-5 font-medium text-gray-900">
              {user.staffProfile.contractAcknowledgedSignature
                ? user.staffProfile.contractAcknowledgedSignature
                : "N/A"}
            </span>
          </div>
          <div className="flex space-x-1 items-center">
            <span className="text-sm leading-5 font-bold text-gray-500">
              Signed date and time:
            </span>
            <span className="text-sm leading-5 font-bold text-gray-900">
              {user.staffProfile.contractAcknowledgedAt
                ? formatDateWithTime(
                    new Date(user.staffProfile.contractAcknowledgedAt)
                  )
                : "N/A"}
            </span>
          </div>
        </div>
        <div className="flex flex-col space-y-3 justify-center">
          <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
            ACKNOWLEDGEMENT
          </span>
          <Checkbox
            name="contractAcknowledged"
            label="I certify that I have read, understood and accept the duties,responsibilities and obligations of my position."
            defaultValue={user.staffProfile.contractAcknowledged}
            type={FieldType.Checbox}
            isDisabled
          />
        </div>
      </div>
    );
  };

  const getAdditionalFieldsForPersonalCareSupportWorker = () => {
    return (
      <div className="">
        {getPersonalCareDescription()}
        {admin.id === staffProfile.contractReportsToManagerId &&
        !showUpdateButtom ? (
          <div className="space-y-2">
            <div className="p-6 space-y-6">
              <div className="px-3 shadow-md">
                <GenericAccordion
                  title="Position Description"
                  children={getPositionDescriptionBody()}
                />
                <GenericAccordion
                  title="Personal Specification"
                  children={getPersonalSpecificationBody()}
                  isLastRecord
                />
              </div>
              {getEmployeeData()}
              {getDataForManagerToFill()}
              <Notification
                type="warningExclamation"
                message="Note: Please ensure to save below."
                className="!m-0 !mt-6"
                iconClassName="text-yellow-500"
              />
            </div>
          </div>
        ) : (
          <div className="p-6">
            <Notification
              type="warningExclamation"
              message="Note: Please ensure to click “Update” button"
              className="!m-0 !mt-6"
              iconClassName="text-yellow-500"
            />
          </div>
        )}
      </div>
    );
  };

  const getPersonalCareDescription = () => {
    return (
      <div className="p-6">
        <h4 className="text-base leading-7 font-semibold text-gray-900">
          Job Description
        </h4>
        <div className="flex space-x-12 pt-3">
          <div className="flex flex-col">
            <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
              Job title
            </span>
            <span className="text-sm leading-5 font-medium text-gray-900">
              Personal Care Worker
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
              Reports to Manager
            </span>
            <div className="flex space-x-2 items-start">
              {adminStaffOptions.length > 0 && (
                <Select
                  name="reportingManager"
                  label=""
                  type={FieldType.Select}
                  defaultValue={staffProfile.contractReportsToManagerId}
                  options={adminStaffOptions}
                  containerClassName="!mt-0 w-80"
                  onChange={() => setShowConfirmationChangeManager(true)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const submitUserDetails = async () => {
    const data = {
      contractAdminSignedAt: getValues("contractAdminSignedAt"),
      contractAdminSignature: getValues("contractAdminSignature"),
    };
    const response = await personalCareWorkerAcknowledgement(data, user.id);
    await handleResponse(response, setError, setResponse);

    setTimeout(() => navigate("/dashboard"), 3000);
  };

  const getDataForManagerToFill = () => {
    return (
      <div className="mt-6 rounded-md shadow-md">
        <div className="p-6  space-x-6 w-full flex items-start">
          <Sign
            name="contractAdminSignedAt"
            label="Manager signature *"
            type={FieldType.Sign}
            requiredCondition={"This is a required field"}
            defaultValue={staffProfile.contractAdminSignedAt}
            defaultSignature={staffProfile.contractAdminSignature}
            signatureName="contractAdminSignature"
            dataContaineClassName="flex-col"
            successMessageClassName="!flex-row space-x-1 mt-5"
            signInputClassName="w-105"
            isDisabled={Boolean(staffProfile.contractAdminSignature)}
          />
        </div>
      </div>
    );
  };
  return (
    <>
      <ApplicationMenu navigationItems={[]} />
      <main className="mx-auto max-w-7xl ">
        <h3 className="text-3xl leading-9 font-bold text-gray-900 pb-3 border-b border-gray-300  mt-10">
          Personal Care Worker Job Description
        </h3>
        <div className="bg-white shadow rounded-md mt-8 ">
          <div className="">
            <FormProvider {...methods}>
              <form id={"save-PersonalCareWorkerManagerForm"}>
                {response ? (
                  <Notification
                    type={response.type}
                    message={response.message}
                    className="!m-6 !mb-0 !inline-flex w-max"
                  />
                ) : (
                  <></>
                )}
                {getAdditionalFieldsForPersonalCareSupportWorker()}
                <div className="flex justify-end items-center bg-gray-50 border-t border-gray-200 px-6  py-3  space-x-3 ">
                  {showUpdateButtom ? (
                    <Button
                      onClick={async () => {
                        const data = {
                          adminId: getValues("reportingManager"),
                        };
                        const response = await personalCareWorkerAssignManager(
                          data,
                          user.id
                        );
                        await handleResponse(response, setError, setResponse);
                        setTimeout(() => navigate("/dashboard"), 3000);
                      }}
                      variant="primary"
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      onClick={() => submitUserDetails()}
                      disabled={Boolean(
                        user.staffProfile?.contractAdminSignature
                      )}
                    >
                      Save
                    </Button>
                  )}
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </main>
      <Modal
        isOpen={showConfirmationChangeManager}
        onClose={() => setShowConfirmationChangeManager(false)}
        modalHeader=""
        className="!bg-yellow-50"
        modalBodyClassName="!mt-0 pb-2 px-4"
      >
        <h3 className="text-yellow-900 font-bold flex justify-start items-center">
          <Icon name="warning" />
          <span className="ml-3">
            Please confirm you want to change “Reports to Manager”
          </span>
        </h3>
        <div className="flex justify-start items-center  rounded-b-md  rounded-b-md px-4 pt-2">
          <Button
            variant="yellowTonal"
            onClick={() => setShowConfirmationChangeManager(false)}
            className="mr-3"
          >
            Cancel
          </Button>
          <Button
            variant="yellowTonal"
            onClick={() => {
              setShowConfirmationChangeManager(false);
              setShowUpdateButtom(true);
            }}
          >
            Confirm
          </Button>
        </div>
      </Modal>
    </>
  );
}
