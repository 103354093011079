import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Icon } from "./Icon";
import Toggle from "./Toggle";

export interface AccordionProps {
  title: string;
  content: React.ReactNode;
  isActive?: boolean;
  displayStep?: string;
  isCompleted?: boolean;
  isOnBoardingStep?: boolean;
  stepNumber: number;
  setIsToggled: (isToggled: boolean) => void;
  isToggled: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  content,
  isActive = false,
  isCompleted = false,
  displayStep,
  isOnBoardingStep,
  stepNumber,
  setIsToggled,
  isToggled,
}) => {
  const [isOpen, setIsOpen] = useState(isActive);
  const accordionRef = useRef<HTMLDivElement>(null);

  const handleToggleChange = (checked: boolean) => {
    setIsToggled(checked);
  };

  const { step } = useParams<{ step: string }>();
  const navigate = useNavigate();

  const toggleAccordion = () => {
    if (!(isActive || isCompleted || isOnBoardingStep)) {
      setIsOpen(false);
      return;
    }
    if (parseInt(step || "") === stepNumber) {
      setIsOpen(!isOpen);
      return;
    }
    navigate(`/onboarding/staff/step/${stepNumber}`);
    setIsOpen(false);
    setIsToggled(false);
  };

  useEffect(() => {
    setIsOpen(isActive);
    setIsToggled(false);
    if (accordionRef.current && isActive) {
      setTimeout(
        () => accordionRef?.current?.scrollIntoView({ behavior: "smooth" }),
        100
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  return (
    <div className="rounded-md mb-4 w-full" key={title}>
      <div
        className={`flex justify-between items-center cursor-pointer ${isOpen ? "" : ""
          }`}
        onClick={toggleAccordion}
        ref={accordionRef}
      >
        <h3
          className={classNames("font-bold", isCompleted && "text-primary-600")}
        >
          {title}
        </h3>
        <div className="flex items-center">
          {displayStep && (
            <div className="flex justify-between items-center">
              {isActive && !isCompleted && isOpen && (
                <div className="mr-4 flex items-center">
                  <div onClick={(e) => e.stopPropagation()}>
                    <Toggle
                      isChecked={isToggled}
                      onChange={handleToggleChange}
                    />
                  </div>
                  <span className="ml-2 text-xs leading-4 font-semibold text-gray-900">
                    Show required fields
                  </span>
                </div>
              )}
              <span className={classNames("mr-3 text-xs rounded-full py-0.5 px-2", isCompleted ? "bg-primary-100 text-primary-900" : "bg-gray-100 text-gray-800")}>
                {displayStep}
              </span>
              {isCompleted && (
                <span className="mr-3 text-xs rounded-full bg-green-100  text-green-800 py-0.5 px-2 flex justify-between items-center">
                  <Icon name="check" />
                  <span className="ml-1">Completed</span>
                </span>
              )}
            </div>
          )}
          <div className={`text-2xl ml-4 ${isOpen ? "animate" : ""}`}>
            {isOpen ? (
              <p className="!rounded-full p-2 !shadow bg-white"><Icon name="accordionOpen" /></p>
            ) : (
              <p className="!rounded-full p-2 bg-gray-200 text-gray-900"><Icon name="accordionClose" /></p>
            )}
          </div>
        </div>
      </div>
      {<div className={classNames("py-4", isOpen ? 'block' : 'hidden')}>{content}</div>}
    </div>
  );
};

export default Accordion;
