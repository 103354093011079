import { onboardStaff } from "api/staff";
import Calendar from "components/Calendar";
import FileUpload from "components/FileUpload";
import FormFooter from "components/FormFooter";
import Notification, { NotificationProps } from "components/Notification";
import { FieldType, FormData, StepProps } from "components/types";
import { handleResponse } from "components/utility";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getTodayDate } from "utils/util";

const uploadComponents = [
  {
    name: "policeCheck",
    label: "Police check *",
    buttonLabel: "Upload police check",
    type: FieldType.FileUpload,
    fileUploadContainerClassName: "w-full md:w-3/4 mb-5 pr-6",
    key: "policeCheck",
    externalLink: "https://www.police.vic.gov.au/apply-national-police-check",
  },
];

const FIELDS_TO_REMOVE = ["calendarMonth", "calendarYear"];

export default function Step5({ user, isCompleted, isToggled }: StepProps) {
  const [shouldValidate, setShouldValidate] = useState<boolean>(true);
  const { handleSubmit, formState, setError, ...formMethods } =
    useForm<FormData>();
  const navigate = useNavigate();
  const [response, setResponse] = useState<NotificationProps | null>(null);

  const onSubmit = async (data: FormData) => {
    setResponse(null);
    delete data.sameAsResidentialAddress;
    const response = await onboardStaff(5, data, "submit", FIELDS_TO_REMOVE);
    const isSuccess = await handleResponse(response, setError, setResponse);
    isSuccess && navigate("/onboarding/staff/step/6");
  };

  const saveForLater = async (data: FormData) => {
    setResponse(null);
    const response = await onboardStaff(5, data, "save", FIELDS_TO_REMOVE);
    setShouldValidate(true);
    handleResponse(response, setError, setResponse);
  };

  const handleButtonClick = async () => {
    setShouldValidate(false);
    setTimeout(() => handleSubmit(saveForLater)(), 100);
  };

  const { staffProfile } = user;

  return (
    <div className="bg-white shadow rounded-md pt-6">
      <div className="flex flex-col ">
        <Notification
          type="warningExclamation"
          message="All mandatory fields must be completed in order to continue to the next step."
          className="my-0 !inline-flex w-max"
          iconClassName="text-yellow-500"
        />
        {response ? (
          <Notification
            type={response.type}
            message={response.message}
            className="my-2 !inline-flex w-max"
          />
        ) : (
          <></>
        )}
      </div>
      <div className="px-6">
        <FormProvider
          {...{ ...formMethods, handleSubmit, formState, setError }}
        >
          <form id={"step-5"} onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-wrap md:flex-nowrap">
              {uploadComponents.map((uploadComp) => (
                <FileUpload
                  {...uploadComp}
                  requiredCondition={
                    shouldValidate && "This is a required field"
                  }
                  isHiglighted={isToggled}
                  defaultValue={staffProfile.policeCheck}
                  isDisabled={isCompleted}
                />
              ))}
              <Calendar
                name="policeCheckValidUntil"
                label="Police check expiry date *"
                type={FieldType.Calendar}
                parentFormContainerClassName="!my-0"
                requiredCondition={shouldValidate && "This is a required field"}
                isHiglighted={isToggled}
                defaultValue={staffProfile.policeCheckValidUntil}
                isDisabled={isCompleted}
                minDate={getTodayDate()}
                className="w-full md:w-96"
              />
            </div>
          </form>
        </FormProvider>
      </div>

      <FormFooter
        saveforLater={handleButtonClick}
        isDisabled={isCompleted}
        formId="step-5"
        step={5}
      />
    </div>
  );
}
