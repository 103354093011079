/* eslint-disable import/no-anonymous-default-export */
import { getToken } from "api";
import axios, { AxiosError, AxiosInstance } from "axios";
import { redirect } from "react-router-dom";
import { cleanObject } from "utils/util";

class Api {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL:
        process.env.REACT_APP_BASE_URL?.trim() || "http://localhost:3001", // Set your base URL
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Add interceptors for handling requests and responses
    this.axiosInstance.interceptors.request.use(
      (config) => {
        // Add logic for modifying request config if needed
        const token = getToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        // Add logic for handling request errors if needed
        return Promise.reject(error);
      }
    );

    this.axiosInstance.interceptors.response.use(
      (response) => {
        // Add logic for handling successful responses if needed
        return response;
      },
      (error) => {
        if (error?.response?.status === 401) {
          redirect("/login");
        }
        // Add logic for handling response errors if needed
        return Promise.reject(error);
      }
    );
  }

  // GET request
  async get<T>(url: string, params?: any) {
    try {
      const response = await this.axiosInstance.get<T>(url, { params });
      return response.data;
    } catch (error) {
      const errorCaught = error as AxiosError;
      return {
        status: errorCaught?.response?.status,
        data: errorCaught?.response?.data,
        statusText: errorCaught?.response?.statusText,
      };
    }
  }

  // POST request
  async post<T>(url: string, data: any) {
    try {
      data = cleanObject(data);
      const response = await this.axiosInstance.post<T>(url, data);
      return response.data;
    } catch (error) {
      const errorCaught = error as AxiosError;
      return {
        status: errorCaught?.response?.status,
        data: errorCaught?.response?.data,
        statusText: errorCaught?.response?.statusText,
      };
    }
  }

  // PUT request
  async put<T>(url: string, data: any) {
    try {
      data = cleanObject(data);
      const response = await this.axiosInstance.put<T>(url, data);
      return response.data;
    } catch (error) {
      const errorCaught = error as AxiosError;
      return {
        status: errorCaught?.response?.status,
        data: errorCaught?.response?.data,
        statusText: errorCaught?.response?.statusText,
      };
    }
  }

  //Delete
  async delete<T>(url: string) {
    try {
      const response = await this.axiosInstance.delete<T>(url);
      return response.data;
    } catch (error) {
      const errorCaught = error as AxiosError;
      return {
        status: errorCaught?.response?.status,
        data: errorCaught?.response?.data,
        statusText: errorCaught?.response?.statusText,
      };
    }
  }

  // Add other request methods as needed (e.g., delete, patch)
}

export default new Api();
