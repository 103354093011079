import { onboardStaff } from "api/staff";
import classNames from "classnames";
import EmailInput from "components/EmailInput";
import FileUpload from "components/FileUpload";
import FormFooter from "components/FormFooter";
import Notification, { NotificationProps } from "components/Notification";
import Select from "components/Select";
import Input from "components/UnControlledInput";
import { FieldType, FormData, StepProps } from "components/types";
import { handleResponse } from "components/utility";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { validateAustralianPhoneNumber } from "utils/util";

const uploadComponents = [
  {
    name: "workCertificates",
    label: "Work-related certificates (Upload up to a maximum of 10 files) *",
    buttonLabel: "Upload work-related certificates",
    type: FieldType.FileUpload,
    fileUploadContainerClassName: "w-full mb-4 md:pr-6",
    key: "workCertificates",
    labelClassName: "text-gray-900 font-bold !text-base",
    isMultipleFiles: true,
  },
  {
    name: "resume",
    label: "Resume *",
    buttonLabel: "Upload resume",
    type: FieldType.FileUpload,
    fileUploadContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6 ",
    key: "resume",
    labelClassName: "text-gray-900 font-bold !text-sm mb-3",
  },
  {
    name: "foodHandlingCertificate",
    label: "Food handling certificate *",
    buttonLabel: "Upload food handling certificate ",
    type: FieldType.FileUpload,
    fileUploadContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    key: "foodHandlingCertificate",
    labelClassName: "text-gray-900 font-bold !text-sm mb-1",
    isMultipleFiles: true,
    externalLink: "https://dofoodsafely.health.vic.gov.au/index.php/en/",
  },
  {
    name: "infectionControlCertificate",
    label: "Infection control training",
    buttonLabel: "Upload infection control training",
    type: FieldType.FileUpload,
    fileUploadContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    key: "infectionControlCertificate",
    labelClassName: "text-gray-900 font-bold !text-sm mb-3",
    isMultipleFiles: true,
  },
];

export const qualificationOptions = [
  {
    label: "High school certificate",
    value: "High school certificate",
  },
  {
    label: "Diploma",
    value: "Diploma",
  },
  {
    label: "Undergraduate degree",
    value: "Undergraduate degree",
  },
  {
    label: "Post-graduate degree",
    value: "Post-graduate degree",
  },
  {
    label: "Masters degree",
    value: "Masters degree",
  },
  {
    label: "PhD",
    value: "PhD",
  },
  {
    label: "Certificate III",
    value: "Certificate III",
  },
];

const FIELDS_TO_REMOVE = ["calendarMonth", "calendarYear"];

const getLabel = (name: string, occupation: string, defaultLabel: string) => {
  if (
    name === "workCertificates" &&
    occupation === "Support Worker (Domestic Assistance)"
  ) {
    return "Work-related certificates (Upload up to a maximum of 10 files)";
  }
  if (name === "foodHandlingCertificate" && occupation === "Nurse") {
    return "Food handling certificate";
  }
  return defaultLabel;
};

export default function Step4({ user, isCompleted, isToggled }: StepProps) {
  const [shouldValidate, setShouldValidate] = useState<boolean>(true);
  const { handleSubmit, formState, setError, ...formMethods } =
    useForm<FormData>();
  const navigate = useNavigate();
  const [response, setResponse] = useState<NotificationProps | null>(null);
  const { staffProfile } = user;

  const onSubmit = async (data: FormData) => {
    setResponse(null);
    const response = await onboardStaff(4, data, "submit", FIELDS_TO_REMOVE);
    const isSuccess = await handleResponse(
      response,
      setError,
      setResponse,
      "Submitted successfully"
    );
    isSuccess && navigate("/onboarding/staff/step/5");
  };

  const saveForLater = async (data: FormData) => {
    setResponse(null);
    const response = await onboardStaff(4, data, "save", FIELDS_TO_REMOVE);
    setShouldValidate(true);
    handleResponse(response, setError, setResponse);
  };

  const handleButtonClick = async () => {
    setShouldValidate(false);
    setTimeout(() => handleSubmit(saveForLater)(), 100);
  };

  return (
    <div className="bg-white shadow rounded-md pt-6">
      <div className="flex flex-col ">
        <Notification
          type="warningExclamation"
          message="All mandatory fields must be completed in order to continue to the next step."
          className="!my-0 !inline-flex w-max"
          iconClassName="text-yellow-500"
        />
        {response ? (
          <Notification
            type={response.type}
            message={response.message}
            className="my-3 !inline-flex w-max"
          />
        ) : (
          <></>
        )}
      </div>
      <div className="pl-6 pt-1 pr-0 pb-0">
        <FormProvider
          {...{ ...formMethods, handleSubmit, formState, setError }}
        >
          <form id={"step-4"} onSubmit={handleSubmit(onSubmit)}>
            <div className="flex justify-start items-start flex-wrap md:flex-nowrap">
              <Select
                name="highestQualification"
                label="Highest qualification in your country *"
                type={FieldType.Select}
                options={qualificationOptions}
                requiredCondition={shouldValidate && "This is a required field"}
                isHiglighted={isToggled}
                defaultValue={staffProfile.highestQualification}
                isDisabled={isCompleted}
                containerClassName="md:pr-6"
                className="w-full md:!w-96"
              />
              <Input
                name="highestQualificationSchool"
                label="School where highest qualification was attained *"
                type={FieldType.Input}
                requiredCondition={shouldValidate && "This is a required field"}
                isHiglighted={isToggled}
                containerClassName="w-full md:!w-96 md:pr-6"
                defaultValue={staffProfile.highestQualificationSchool}
                isDisabled={isCompleted}
              />
            </div>
            <div className="">
              {uploadComponents.map((uploadComp) => (
                <FileUpload
                  {...uploadComp}
                  label={getLabel(
                    uploadComp.name,
                    staffProfile.occupation,
                    uploadComp.label
                  )}
                  requiredCondition={
                    uploadComp.name === "infectionControlCertificate" ||
                    (uploadComp.name === "workCertificates" &&
                      staffProfile.occupation ===
                        "Support Worker (Domestic Assistance)") ||
                    (uploadComp.name === "foodHandlingCertificate" &&
                      staffProfile.occupation === "Nurse")
                      ? false
                      : shouldValidate && "This is a required field"
                  }
                  isHiglighted={isToggled}
                  defaultValue={
                    staffProfile[
                      uploadComp.name as
                        | "workCertificates"
                        | "foodHandlingCertificate"
                        | "infectionControlCertificate"
                        | "ndisWorkerOrientationCertificate"
                    ]
                  }
                  isDisabled={isCompleted}
                  key={uploadComp.name}
                />
              ))}
            </div>
            <div className="mt-5">
              <h4 className="text-gray-900 text-lg font-bold pt-5">
                Job Reference Contact Details
              </h4>
              <div className="flex py-6 flex-wrap md:flex-nowrap">
                {["reference1", "reference2"].map((ref, index) => (
                  <div
                    className={classNames(
                      "md:w-1/2 w-full",
                      index === 0 ? "md:border-r border-gray-300" : "pl-6"
                    )}
                  >
                    <h5 className="text-gray-900 text-base font-bold">
                      Reference {index + 1}
                    </h5>
                    <Select
                      name={`${ref}.title`}
                      key={`${ref}title`}
                      label="Title *"
                      type={FieldType.Select}
                      placeholder="Select"
                      requiredCondition={
                        shouldValidate && "This is a required field"
                      }
                      isHiglighted={isToggled}
                      options={[
                        { label: "Mr", value: "Mr" },
                        {
                          label: "Mrs",
                          value: "Mrs",
                        },
                        { label: "Miss", value: "Miss" },
                      ]}
                      defaultValue={
                        staffProfile[ref as "reference1" | "reference2"]?.title
                      }
                      isDisabled={isCompleted}
                      inputClassName="w-3/4"
                      hideSearch
                      containerClassName={classNames(
                        "md:w-1/5 w-full  inline-block align-top",
                        "md:pr-3"
                      )}
                      className="w-full"
                    />
                    <Input
                      name={`${ref}.firstName`}
                      label="First Name *"
                      type={FieldType.Input}
                      containerClassName={classNames(
                        "md:w-2/5 w-full  inline-block align-top",
                        "md:pr-3"
                      )}
                      className="w-full"
                      placeholder="John"
                      requiredCondition={
                        shouldValidate && "This is a required field"
                      }
                      isHiglighted={isToggled}
                      defaultValue={
                        staffProfile[ref as "reference1" | "reference2"]
                          ?.firstName
                      }
                      isDisabled={isCompleted}
                    />
                    <Input
                      name={`${ref}.lastName`}
                      label="Last Name *"
                      type={FieldType.Input}
                      placeholder="John"
                      containerClassName={classNames(
                        "md:w-2/5 w-full  inline-block align-top",
                        "md:pr-6"
                      )}
                      className="w-full"
                      requiredCondition={
                        shouldValidate && "This is a required field"
                      }
                      isHiglighted={isToggled}
                      defaultValue={
                        staffProfile[ref as "reference1" | "reference2"]
                          ?.lastName
                      }
                      isDisabled={isCompleted}
                    />
                    <EmailInput
                      name={`${ref}.email`}
                      label="Email *"
                      type={FieldType.Input}
                      className="w-full"
                      placeholder="jamesbond@actionstuff.com"
                      containerClassName={classNames(
                        "w-1/2  inline-block align-top",
                        "md:pr-3"
                      )}
                      requiredCondition={
                        shouldValidate && "This is a required field"
                      }
                      isHiglighted={isToggled}
                      defaultValue={
                        staffProfile[ref as "reference1" | "reference2"]?.email
                      }
                      isDisabled={isCompleted}
                    />
                    <Input
                      name={`${ref}.mobile`}
                      label="Mobile *"
                      type={FieldType.Input}
                      className="w-full"
                      placeholder="+614XXX XXX XXX"
                      containerClassName={classNames(
                        "w-1/2  inline-block align-top",
                        "md:pr-6"
                      )}
                      requiredCondition={
                        shouldValidate && "This is a required field"
                      }
                      isHiglighted={isToggled}
                      defaultValue={
                        staffProfile[ref as "reference1" | "reference2"]?.mobile
                      }
                      validationFunction={validateAustralianPhoneNumber}
                      validationErrorMessage="Invalid Australian phone number"
                      isDisabled={isCompleted}
                    />
                    <Input
                      name={`${ref}.relationShip`}
                      label="Relationship *"
                      type={FieldType.Input}
                      containerClassName={"md:pr-6"}
                      placeholder="Manager"
                      requiredCondition={
                        shouldValidate && "This is a required field"
                      }
                      isHiglighted={isToggled}
                      defaultValue={
                        staffProfile[ref as "reference1" | "reference2"]
                          ?.relationShip
                      }
                      isDisabled={isCompleted}
                    />
                  </div>
                ))}
              </div>
            </div>
          </form>
        </FormProvider>
      </div>

      <FormFooter
        saveforLater={handleButtonClick}
        isDisabled={isCompleted}
        formId="step-4"
        step={4}
      />
    </div>
  );
}
