import classNames from "classnames";
import Button from "components/Button";
import Calendar from "components/Calendar";
import FileUpload from "components/FileUpload";
import RadioBox from "components/RadioBox";
import Select from "components/Select";
import Input from "components/UnControlledInput";
import {
  FieldType,
  FormData,
  FormProps,
  TotalFormFields,
} from "components/types";
import React from "react";
import { FormProvider } from "react-hook-form";
import { stringToEnum } from "utils/util";
import Checkbox from "./Checkbox";
import EmailInput from "./EmailInput";
import MultiSelectDropdown from "./MultiSelect";
import Paragraph from "./Paragraph";
import Sign from "./Sign";

const getFields = (fieldType: FieldType, props: TotalFormFields) => {
  switch (fieldType) {
    case FieldType.Input:
      return <Input {...props} />;
    case FieldType.Button:
      return <Button variant="primary" label={props.label} />;
    case FieldType.Select:
      return <Select {...props} />;
    case FieldType.Radio:
      return <RadioBox {...props} />;
    case FieldType.Calendar:
      return <Calendar {...props} />;
    case FieldType.FileUpload:
      return <FileUpload {...props} />;
    case FieldType.Checbox:
      return <Checkbox {...props} />;
    case FieldType.MultiSelect:
      return <MultiSelectDropdown {...props} />;
    case FieldType.EmailInput:
      return <EmailInput {...props} />;
    case FieldType.Sign:
      return <Sign {...props} signatureName={props.signatureName || ""} />;
    case FieldType.Paragraph:
      return (
        <Paragraph children={props.children} className={props.className} />
      );
    default:
      return;
  }
};

const ReusableForm: React.FC<FormProps<FormData>> = ({
  fields,
  onSubmit,
  id,
  formClassName,
  formMethods,
}) => {
  const { handleSubmit } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <form
        id={id}
        onSubmit={handleSubmit(onSubmit)}
        className={classNames(formClassName)}
        key={id}
      >
        {fields.map((field) => {
          const { parentFormContainerClassName } = field;
          return (
            <div
              key={field.name}
              className={classNames(
                field.type === FieldType.Button ? "text-center mt-5" : "",
                parentFormContainerClassName
              )}
            >
              {getFields(
                stringToEnum(field.type, FieldType) || FieldType.Input,
                {
                  ...field,
                }
              )}
            </div>
          );
        })}
      </form>
    </FormProvider>
  );
};

export default ReusableForm;
