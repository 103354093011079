import classNames from "classnames";
import Button from "components/Button";
import React, { useEffect, useRef, useState } from "react";
import { Icon } from "./Icon";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  modalHeader: string;
  children: React.ReactNode;
  modalBodyClassName?: string;
  modalHeaderClassName?: string;
  customModalStyles?: object;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  className = "",
  children,
  modalHeader,
  modalBodyClassName,
  modalHeaderClassName,
  customModalStyles,
}) => {
  const [animation, setAnimation] = useState("");
  const [modalHeight, setModalHeight] = useState(0);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) {
      setAnimation("animate-slideUp");
      if (modalRef.current) {
        setModalHeight(modalRef.current.clientHeight);
      }
    } else {
      setAnimation("animate-slideDown");
    }
  }, [isOpen]);

  if (!isOpen && animation === "animate-slideDown") return null;

  return (
    <div
      className={`fixed inset-0 z-50 flex ${modalHeight > 500 ? "items-start" : "items-center"} justify-center overflow-auto outline-none focus:outline-none bg-black bg-opacity-40 shadow animate-opacityAnimation`}
    >
      <div
        className={`relative z-60 w-auto mx-2 md:mx-auto my-6  ${animation}`}
      >
        <div
          className={`modal ${className} bg-white rounded-md`}
          ref={modalRef}
          style={{ ...customModalStyles }}
        >
          <div className="modal-content pt-4">
            {modalHeader && (
              <div
                className={classNames(
                  "modal-header flex justify-between items-center px-5",
                  modalHeaderClassName
                )}
              >
                <h3 className="text-gray-900 text-lg font-semibold mt-4">
                  {modalHeader}
                </h3>
                <Button
                  className="absolute right-1 top-1 hover:rounded-full focus:rounded-full !p-2"
                  aria-label="Close"
                  onClick={onClose}
                  variant="gray"
                >
                  <Icon name="close" />
                </Button>
              </div>
            )}
            <div
              className={classNames("modal-body mt-5", modalBodyClassName)}
              style={{ ...customModalStyles }}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
