import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import { get, valueExists } from "../utils/util";
import { Icon } from "./Icon";
import { FormField } from "./types";
import { renderErrorMessage } from "./utility";

export interface Option {
  label: string;
  value: string | number;
}

interface Props extends FormField {
  name: string;
  inputClassName?: string;
  containerClassName?: string;
  error?: FieldError | undefined;
  onChange?: Function;
  isClearable?: boolean;
  value?: string | number;
  hideSearch?: boolean;
  isDropdownFixed?: boolean;
  dropDownClassName?: string;
}

const Select: React.FC<Props> = ({
  name,
  options,
  placeholder = "Select",
  error,
  inputClassName,
  containerClassName,
  label,
  defaultValue,
  isDisabled,
  className,
  onChange,
  requiredCondition,
  isClearable = false,
  hideSearch = false,
  value,
  isDropdownFixed = false,
  dropDownClassName,
  isHiglighted,
}) => {
  const [selectedOption, setSelectedOption] = useState<Option>();
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [openUpwards, setOpenUpwards] = useState(false);
  const {
    control,
    setValue,
    trigger,
    formState: { errors },
    watch
  } = useFormContext();
  const stateValue = watch(name)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    const handleScroll = (event: Event) => {
      if (
        dropdownRef.current &&
        dropdownRef.current.contains(event.target as Node)
      ) {
        return; // Ignore scroll events inside the dropdown
      }
      setIsOpen(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("scroll", handleScroll, true); // Use `true` to capture the event during the capture phase

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  useEffect(() => {
    if (valueExists(defaultValue) && options) {
      setValue(name, defaultValue);
      const selectedOption = options.filter(
        (lang) => lang.value === defaultValue
      )[0];
      setSelectedOption(selectedOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, name, setValue]);

  useEffect(() => {
    if (!value && isClearable) {
      setValue(name, "");
      setSelectedOption(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (isOpen && dropdownRef.current && selectRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const selectRect = selectRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      const spaceBelow = viewportHeight - selectRect.bottom;
      const spaceAbove = selectRect.top;

      if (dropdownRect.height > spaceBelow - 100 && spaceAbove > spaceBelow) {
        setOpenUpwards(true);
      } else {
        setOpenUpwards(false);
      }
    }
  }, [isOpen]);

  if (!options) {
    return <></>;
  }

  const handleToggle = () => {
    !isDisabled && setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: Option) => {
    setSelectedOption(option);
    setValue(name, option.value);
    setIsOpen(false);
    trigger(name);
    setSearchTerm("");
    onChange && onChange(option);
  };

  const filteredOptions = options.filter((option) =>
    option.label?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const isSelected = (option: Option) => {
    return options.some(
      () => selectedOption && selectedOption.value === option.value
    );
  };

  return (
    <div
      className={classNames("mt-5 min-w-20", containerClassName || "w-96")}
      id={name}
      key={name}
    >
      {label && (
        <label
          htmlFor={name}
          className={classNames(
            "block text-sm font-semibold text-gray-700",
            isDisabled && "!text-gray-400"
          )}
        >
          {label}
        </label>
      )}
      <div className="relative mt-1" ref={selectRef}>
        <div
          className={classNames(
            "flex items-center cursor-pointer justify-between",
            "h-10 text-gray-900 rounded-md border-0 px-3 py-2 mt-1 shadow-sm ring-1 ring-inset placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-400 focus:shadow sm:text-sm sm:leading-6",
            isDisabled &&
            "!text-gray-400 !border-gray-300 focus:!ring-gray-300 focus:!ring-1 !cursor-default active:!ring-1 active:!ring-gray-300",
            "active:ring-2 active:ring-inset active:ring-primary-400 active:shadow",
            isOpen ? "ring-2 !ring-primary-400 shadow" : "ring-gray-300",
            {
              "!ring-red-600": get(errors, name),
              "!focus:ring-red-600": get(errors, name),
            },
            className,
            "bg-white",
            isHiglighted && requiredCondition && !stateValue && "ring-secondary-500"
          )}
          onClick={handleToggle}
        >
          <span className="mr-2">
            {selectedOption ? selectedOption.label : placeholder}
          </span>
          {isOpen ? (
            <Icon
              name="downChevron"
              className="animate rotate-180 text-gray-500"
              size={20}
            />
          ) : (
            <Icon
              name="downChevron"
              className={classNames(
                "text-gray-500",
                isDisabled && "!text-gray-400 !border-gray-300"
              )}
              size={20}
            />
          )}
        </div>
        {isOpen && (
          <div
            ref={dropdownRef}
            style={{
              left: "auto",
              top: !isDropdownFixed && openUpwards ? "" : "auto",
              bottom: !isDropdownFixed && openUpwards ? "100%" : "",
              width: selectRef.current?.getBoundingClientRect().width,
            }}
            className={classNames(
              "z-100 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg min-w-80 max-h-60 overflow-y-auto",
              className,
              isDropdownFixed ? "fixed" : "absolute",
              dropDownClassName
            )}
          >
            {!hideSearch && (
              <div className="relative flex items-center p-2">
                <input
                  type="text"
                  className={classNames(
                    "flex-1 h-10 text-gray-900 rounded-md border-0 pr-3 pl-9 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-primary-400 focus:shadow sm:text-sm sm:leading-6 disabled:text-gray-400 disabled:border-gray-300",
                    inputClassName,
                    "py-2"
                  )}
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className="absolute z-100 inset-y-0 left-3 pl-3 flex items-center pointer-events-none">
                  <Icon name="search" />
                </div>
              </div>
            )}
            <div className=" ">
              {filteredOptions.map((option) => (
                <div
                  key={option.value}
                  className={classNames(
                    `group flex justify-between items-center px-3 py-2 cursor-pointer  hover:bg-primary-200 focus:bg-primary-400 text-gray-900 text-sm leading-5 disabled:bg-gray-50 disabled:text-gray-400  ${isSelected(option) ? "font-semibold" : "font-normal"
                    }`
                  )}
                  onClick={() => handleOptionClick(option)}
                >
                  <span>{option.label}</span>
                  {isSelected(option) && (
                    <Icon
                      name="solidCheck"
                      className="text-primary-500 group-hover:text-gray-900 hover:text-gray-900"
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        <Controller
          name={name}
          control={control}
          rules={{ required: requiredCondition }}
          render={({ field }) => (
            <input
              type="hidden"
              {...field}
              value={selectedOption?.value || ""}
            />
          )}
        />
      </div>
      {renderErrorMessage(get(errors, name) as FieldError)}
    </div>
  );
};

export default Select;
