// src/components/Popover.tsx
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";

interface PopoverProps {
  content: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}

const Popover: React.FC<PopoverProps> = ({ content, children, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);

  const togglePopover = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block" ref={popoverRef}>
      <div className="cursor-pointer" onClick={togglePopover}>
        {children}
      </div>
      {isOpen && (
        <div
          className={classNames(
            "absolute z-10 mt-2 min-w-80 bg-white border border-gray-200 rounded-md shadow-lg",
            className
          )}
        >
          {content}
        </div>
      )}
    </div>
  );
};

export default Popover;
