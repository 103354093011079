/* eslint-disable no-useless-escape */

export type UserAgentInfo = {
  browser: string;
  browserVersion: string;
  operatingSystem: string;
  osVersion: string;
  device: string;
  platform: string;
};

function parseUserAgent(ua: string): UserAgentInfo {
  let browser = "Unknown";
  let browserVersion = "Unknown";
  let operatingSystem = "Unknown";
  let osVersion = "Unknown";
  let device = "Unknown";
  let platform = "Unknown";

  // Detect Browser and Version
  if (/firefox|fxios/i.test(ua)) {
    browser = "Firefox";
    const version = ua.match(/firefox\/(\d+(\.\d+)?)/i);
    if (version) browserVersion = version[1];
  } else if (/chrome|crios|crmo/i.test(ua)) {
    browser = "Chrome";
    const version = ua.match(/(?:chrome|crios|crmo)\/(\d+(\.\d+)?)/i);
    if (version) browserVersion = version[1];
  } else if (/safari/i.test(ua)) {
    browser = "Safari";
    const version = ua.match(/version\/(\d+(\.\d+)?)/i);
    if (version) browserVersion = version[1];
  } else if (/msie|trident/i.test(ua)) {
    browser = "Internet Explorer";
    const version = ua.match(/(?:msie |rv:)(\d+(\.\d+)?)/i);
    if (version) browserVersion = version[1];
  } else if (/edg/i.test(ua)) {
    browser = "Edge";
    const version = ua.match(/edg\/(\d+(\.\d+)?)/i);
    if (version) browserVersion = version[1];
  }

  // Detect Operating System and Version
  if (/windows nt/i.test(ua)) {
    operatingSystem = "Windows";
    const version = ua.match(/windows nt (\d+(\.\d+)?)/i);
    if (version) {
      const versionMap: { [key: string]: string } = {
        "10.0": "10",
        "6.3": "8.1",
        "6.2": "8",
        "6.1": "7",
        "6.0": "Vista",
        "5.1": "XP",
        "5.0": "2000",
      };
      osVersion = versionMap[version[1]] || version[1];
    }
  } else if (/mac os x/i.test(ua)) {
    operatingSystem = "Mac OS";
    const version = ua.match(/mac os x (\d+[\._]\d+(\.\d+)?)/i);
    if (version) osVersion = version[1].replace(/_/g, ".");
  } else if (/android/i.test(ua)) {
    operatingSystem = "Android";
    const version = ua.match(/android (\d+(\.\d+)?)/i);
    if (version) osVersion = version[1];
  } else if (/linux/i.test(ua)) {
    operatingSystem = "Linux";
  } else if (/iphone|ipad|ipod/i.test(ua)) {
    operatingSystem = "iOS";
    const version = ua.match(/os (\d+[\._]\d+(\.\d+)?)/i);
    if (version) osVersion = version[1].replace(/_/g, ".");
  }

  // Detect Device
  if (/mobile/i.test(ua)) {
    device = "Mobile";
  } else if (/tablet/i.test(ua)) {
    device = "Tablet";
  } else {
    device = "Desktop";
  }

  // Detect Platform
  if (/android/i.test(ua)) {
    platform = "Android";
  } else if (/iphone|ipad|ipod/i.test(ua)) {
    platform = "iOS";
  } else if (/win/i.test(ua)) {
    platform = "Windows";
  } else if (/mac/i.test(ua)) {
    platform = "Mac";
  } else if (/linux/i.test(ua)) {
    platform = "Linux";
  }

  return {
    browser,
    browserVersion,
    operatingSystem,
    osVersion,
    device,
    platform,
  };
}

export { parseUserAgent };
