import classNames from "classnames";
import { FormField } from "components/types";
import React, { useEffect } from "react";
import { FieldError, useFormContext } from "react-hook-form";
import { renderErrorMessage } from "./utility";

interface SelectProps extends FormField {
  label: string;
  error?: FieldError | undefined;
  placeholder?: string;
  containerClassName?: string;
}

const Select: React.FC<SelectProps> = ({
  label,
  name,
  options,
  requiredCondition,
  className,
  placeholder,
  containerClassName,
  isDisabled,
  defaultValue,
}) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();
  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
    }
  }, [defaultValue, name, setValue]);

  if (!options) {
    return <></>;
  }
  return (
    <div className={classNames("my-3", containerClassName)}>
      <label
        htmlFor={name}
        className="block text-sm font-semibold text-gray-700"
      >
        {label}
      </label>
      <select
        id={name}
        className={classNames(
          "block h-10 text-gray-900 rounded-md border-0 p-2 mt-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-400 focus:shadow sm:text-sm sm:leading-6 disabled:text-gray-400 disabled:border-gray-300",
          className
        )}
        {...register(name, { required: requiredCondition })}
        value={defaultValue as string}
        disabled={isDisabled}
      >
        <option disabled selected hidden value="">
          {placeholder}
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {renderErrorMessage(errors[name] as FieldError)}
    </div>
  );
};

export default Select;
