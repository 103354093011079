import classNames from "classnames";
import { Icon } from "./Icon";

export interface Column<T> {
  header: string;
  accessor: keyof T;
  Cell?: (value: T[keyof T], row: T) => JSX.Element;
  columnWidth?: string;
  sortable?: boolean;
}

export interface SortOptions<T> {
  sortField: keyof T;
  sortOrder: string;
}

export interface TableProps<T> {
  data: T[];
  columns: Column<T>[];
  containerClassName?: string;
  setSortConfig: (sort: SortOptions<T>) => void;
  sortConfig: SortOptions<T> | null;
}

const SortableTable = <T extends Record<string, any>>({
  data,
  columns,
  containerClassName,
  setSortConfig,
  sortConfig,
}: TableProps<T>) => {
  const requestSort = (sortField: keyof T) => {
    let sortOrder: "asc" | "desc" = "desc";
    if (
      sortConfig &&
      sortConfig.sortField === sortField &&
      sortConfig.sortOrder === "desc"
    ) {
      sortOrder = "asc";
    }
    setSortConfig({ sortField, sortOrder });
  };

  const getClassNamesFor = (key: keyof T) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.sortField === key ? sortConfig.sortOrder : undefined;
  };

  return (
    <div
      className={classNames(
        "overflow-auto border border-gray-200 shadow rounded-md",
        containerClassName
      )}
    >
      <div className="min-w-full bg-white">
        {/* Header Row */}
        <div
          className="flex border-b border-gray-300 bg-gray-50 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider px-6"
          style={{ height: "3.25rem" }}
        >
          {columns.map((column, index) => (
            <div
              key={String(column.accessor) + index}
              onClick={() => column.sortable && requestSort(column.accessor)}
              className={classNames(
                "flex items-center cursor-pointer",
                column.columnWidth,
                "whitespace-nowrap overflow-hidden text-ellipsis flex-shrink-0" // Prevent column from shrinking
              )}
              style={{ width: column.columnWidth }}
            >
              {column.header}
              {column.sortable && (
                <div className="hover:bg-gray-200 hover:rounded-full focus:rounded-full justify-center ml-1 p-1">
                  <Icon
                    name="downChevron"
                    size={24}
                    className={classNames(
                      "transition-transform",
                      getClassNamesFor(column.accessor) === "desc"
                        ? "rotate-180"
                        : ""
                    )}
                  />
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Data Rows */}
        <div>
          {data.map((row, rowIndex) => (
            <div
              key={rowIndex}
              className="flex border-b last:border-0 px-6"
              style={{ height: "3.25rem" }} // Adjust height as needed
            >
              {columns.map((column, index) => (
                <div
                  key={String(column.accessor) + index}
                  className={classNames(
                    "text-sm font-medium text-gray-900 flex items-center",
                    column.columnWidth,
                    "whitespace-nowrap overflow-hidden text-ellipsis flex-shrink-0" // Prevent column from shrinking,
                  )}
                  style={{ width: column.columnWidth }}
                >
                  {column.Cell
                    ? column.Cell(row[column.accessor], row)
                    : row[column.accessor]}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SortableTable;
