import { removeObjectFields } from "utils/util";
import api from "./api";
import { GenericResponse, StaffProfile } from "./types";

interface StaffProfileResponse
  extends Omit<StaffProfile, "status">,
    Omit<GenericResponse, "status"> {
  status: number | string;
}

export const onboardStaff = async (
  step: number,
  data: any,
  action: "save" | "submit",
  fieldsToRemove: string[]
) => {
  data = removeObjectFields(data, fieldsToRemove);
  return (await api.put<StaffProfileResponse>(
    `/api/user/onboarding/${step}/${action}`,
    data
  )) as StaffProfileResponse;
};

export const proceedToContinueApplication = async () => {
  return (await api.put<StaffProfileResponse>(
    `/api/user/onboarding/6/proceed`,
    {}
  )) as StaffProfileResponse;
};

export const updateStaffCheckStatus = async (
  staffId: number,
  fieldName: string,
  outcome: string
) => {
  return (await api.put<StaffProfileResponse>(
    `/api/user/admin/staff/${fieldName}/${staffId}`,
    {
      outcome: outcome,
    }
  )) as StaffProfileResponse;
};
export const finishOnboarding = async () => {
  return (await api.put<StaffProfileResponse>(
    `/api/user/onboarding/finish`,
    {}
  )) as StaffProfileResponse;
};

export const confirmInterviewTime = async (staffId: number) => {
  return (await api.put<StaffProfileResponse>(
    `/api/user/admin/staff/interview/confirm-time/${staffId}`,
    {}
  )) as StaffProfileResponse;
};

export const editStaff = async (
  endpoint: string,
  data: any,
  fieldsToRemove: string[]
) => {
  data = removeObjectFields(data, fieldsToRemove);
  return (await api.put<StaffProfileResponse>(
    `${endpoint}`,
    data
  )) as StaffProfileResponse;
};

export const updateChecklistItems = async (data: any, staffId: number) => {
  return (await api.put<StaffProfileResponse>(
    `/api/user/admin/staff/section/profile-review-checklist/${staffId}`,
    data
  )) as StaffProfileResponse;
};

export const completeStaffProfileOnboarding = async (staffId: number) => {
  return (await api.put<StaffProfileResponse>(
    `/api/user/admin/staff/completed-profile/${staffId}`,
    {}
  )) as StaffProfileResponse;
};

export const personalCareWorkerAcknowledgement = async (
  data: any,
  staffId: number
) => {
  return (await api.put<StaffProfileResponse>(
    `/api/user/admin/staff/section/contract/personal-care-worker-acknowledgement/${staffId}`,
    data
  )) as StaffProfileResponse;
};

export const personalCareWorkerAssignManager = async (
  data: any,
  staffId: number
) => {
  return (await api.put<StaffProfileResponse>(
    `/api/user/admin/staff/section/contract/personal-care-worker/assign-admin/${staffId}`,
    data
  )) as StaffProfileResponse;
};
