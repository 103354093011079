import classNames from "classnames";

export interface Column<T> {
  header: string;
  accessor: keyof T;
  Cell?: (value: T[keyof T], row: T) => JSX.Element;
  columnWidth?: string;
}

export interface TableProps<T> {
  data: T[];
  columns: Column<T>[];
  containerClassName?: string;
}

const Table = <T extends Record<string, any>>({
  data,
  columns,
  containerClassName,
}: TableProps<T>) => {
  return (
    <div
      className={classNames(
        "overflow-auto  border border-gray-200 shadow rounded-md",
        containerClassName
      )}
    >
      <table className="min-w-full bg-white ">
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th
                key={String(column.accessor) + index}
                className={classNames(
                  "px-6 py-3 border-b border-gray-300 bg-gray-50 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider whitespace-nowrap",
                  column.columnWidth
                )}
              >
                {column.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex} className="bg-white border-b last:border-0">
              {columns.map((column, index) => (
                <td
                  key={String(column.accessor) + index}
                  className={classNames(
                    "px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900",
                    column.columnWidth
                  )}
                >
                  {column.Cell
                    ? column.Cell(row[column.accessor], row)
                    : row[column.accessor]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
